
export default{
    props:{
        isInViewPort: Boolean,
        topPosition: Number
    },
    computed: {
        scrollY (){
            return this.$store.getters.portfolioScrollOffset
        }
    },
    watch: {
        scrollY(){
            
        },
        topPosition(){
            
        }
    }
}