<template>
    <div class="portfolio-item portfolio-end-border bm-container-wrapper">

        <!-- SPACE FOR CARUSEL -->
        <div class="portfolio-space-for-carusel">
            <div class="scroll-to-read"> Scroll to read case study </div>
        </div>

        <div class="bm-row bm-container bm-sm bm-md bm-xs">
            <div class="bm-col-md-2 bm-col-xs-1"></div>
            <div class="bm-col-md-14 bm-col-xs-16 portfolio-item-content no-gutters">

                <ItemDescription
                    lead="The Warsaw Rising was a major WWII operation during which the city once know as Paris of the North ceased to exist, only to rise like phoenix from the ashes. The project was developed in cooperation between the Topography of Terror Foundation in Berlin and the Warsaw Rising Museum for the 70th anniversary of the Warsaw Uprising."
                    role="UI UX, Responsive design, Motion design, Art direction" client="Warsaw Rising Museum" year="2015"
                    :recognition="[
                        'The Webby Awards x2',
                        'The European Design Award',
                        'Awwwards SOTD',
                        'The FWA SOTD',
                    ]" link="https://warsawrising.eu" />

                <div class="bm-col-14 portfolio-row-margin">
                    <VideoPlayer :topPosition="topPosition" 
                        :hasSound="true"
                        :hasInfo="true"
                        path="/assets/portfolio/warsaw/video/warsaw.mp4"  
                        poster="/assets/portfolio/posters/warsaw.webp"></VideoPlayer>
                </div>

                <!-- <div class="bm-row portfolio-images bm-col-14"> -->
                    <div class="bm-col-14">
                        <div class="portfolio-img">
                            <div class="img-with-ratio-hd">
                                <DeferredImage placeholderSrc="/assets/portfolio/warsaw/desktop/001m@2x.webp" src="/assets/portfolio/warsaw/desktop/001@2x.webp" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="portfolio-item--images-mobile-row odd">
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile2">
                                    <DeferredImage placeholderSrc="/assets/portfolio/warsaw/mobile/001m@2x.webp" src="/assets/portfolio/warsaw/mobile/001@2x.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-col-md-5 bm-col-xs-2"></div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile2">
                                    <DeferredImage placeholderSrc="/assets/portfolio/warsaw/mobile/003m@2x.webp" src="/assets/portfolio/warsaw/mobile/003@2x.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile2">
                                    <DeferredImage placeholderSrc="/assets/portfolio/warsaw/mobile/002m@2x.webp" src="/assets/portfolio/warsaw/mobile/002@2x.webp" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                <div class="bm-col-14 portfolio-row-margin">
                    <VideoPlayer :topPosition="topPosition" path="/assets/portfolio/warsaw/video/004.mp4"
                        pathMobile="/assets/portfolio/warsaw/video/004.mp4"
                        poster="/assets/portfolio/posters/Warsaw-004.webp"></VideoPlayer>
                </div>

                <div class="bm-col-14 portfolio-row-margin">
                    <div class="portfolio-img">
                        <div class="img-with-ratio-hd">
                            <DeferredImage placeholderSrc="/assets/portfolio/warsaw/desktop/007m@2x.webp" src="/assets/portfolio/warsaw/desktop/007@2x.webp" alt="" />
                        </div>
                    </div>
                </div>

                <div class="bm-col-14 portfolio-row-margin">
                    <VideoPlayer :topPosition="topPosition" path="/assets/portfolio/warsaw/video/003.mp4"
                        pathMobile="/assets/portfolio/warsaw/video/003.mp4"
                        poster="/assets/portfolio/posters/Warsaw-003.webp"></VideoPlayer>
                </div>

                <div class="bm-col-14 portfolio-row-margin">
                    <VideoPlayer :topPosition="topPosition" path="/assets/portfolio/warsaw/video/001.mp4"
                    pathMobile="/assets/portfolio/warsaw/video/001.mp4"
                        poster="/assets/portfolio/posters/Warsaw-001.webp"></VideoPlayer>
                </div>
            </div>
            <div class="bm-col-md-2 bm-col-xs-1"></div>
        </div>
    </div>
</template>
<script>
import VideoPlayer from '@/components/portfolio/VideoPlayer'
import ItemDescription from '@/components/portfolio/ItemDescription'
import portfolioItemMixin from '@/mixins/portfolio-item-mixin';
import DeferredImage from '@/components/portfolio/DeferredImage'


export default {
    mixins: [portfolioItemMixin],
    components: {
        VideoPlayer,
        ItemDescription,
        DeferredImage,

    }
}
</script>