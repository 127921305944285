/* eslint-disable */
import axios from 'axios';
import DataConstants from '../constants/DataConstants';
import ProjectsStore from '../ProjectsStore';
import StageStore from '../StageStore';
import isMobile from 'ismobilejs';
import "url-search-params-polyfill";

require('es6-promise').polyfill();

let urls = {
  getprojects: '/assets/data.json',
  getmobile: './assets/mobiledetect.json',
  getclothpoints: '/assets/clothNew.json',
};

if (process.env.NODE_ENV === 'production') {
  // urls = {
  //   getprojects: 'data.json',
  //   getmobile: 'mobiledetect.php',
  // };
}


/** Get projects
*
* @returns Projects DATA
*/
export function getProjects() {
  axios.get(urls.getprojects)
    .then((response) => {
      if (response.data) {
        ProjectsStore.setProjects(response.data.projects);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

/** Get mobileinfo
*
* @returns mobile=true/false
*/
export function getMobile() {

  // axios.get(urls.getmobile)
  //   .then((response) => {
  //     if (response.data) {        
      StageStore.setMobile(isMobile(window.navigator).any || window.innerWidth <= 1024 ? 1 : 0);
  //   }
  // })
  // .catch((error) => {
  //   console.log(error);
  // });
}


/** Get getclothpoints
*
* @returns JSON points
*/
export function getCloth() {
  
  axios.get(urls.getclothpoints)
    .then((response) => {
      if (response.data) {
        ProjectsStore.setClothPoints(response.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
