
<template>
  <!-- <div class="form-bg"></div> -->
  <div v-if="submitted" class="contact-form submitted">
    <section ref="submittedSection">
      <div class="container-fluid" ref="formHeader" :style="{'padding': `0 ${leftPadding}px`}">
        <div class="row">
            <h1 class="form-title fade-in">
              SENT
            </h1>
        </div>
        <div class="row">
          <div class="body-text input-wrapper lead" style="margin-bottom: 0;">
            <p class="input-wrapper bright-body-size fade-in">
              Thank you for your message!<br>
              We tend to respond within two business days.
            </p>
            <a class="input-wrapper bright-body-size fade-in" @click="onCloseClick" @mouseover="redrawBorder">
              Close
              <div class="border-redraw-transition left"></div>
              <div class="border-redraw-transition right"></div>
            </a>
          </div>
        </div>
      </div>
    </section>

  </div>
  <div v-else class="contact-form">
    
    <section>
      <div class="container-fluid" ref="formHeader" :style="{'padding': `0 ${leftPadding}px`}">
        <div class="row">
          <!-- <div class="col-12"> -->
            <!-- whitespace: 'condense' -->
            <h1 class="form-title">
              Let’s talk
            </h1>
          <!-- </div> -->
        </div>
        <div class="row">
          <div class="body-text input-wrapper lead" style="margin-bottom: 0;">
            <p class="input-wrapper bright-body-size">Ready for a bright new project? Because we are! We just need to know a thing or two before we meet. Please fill out this form or you may reach us directly by phone or email if you prefer.</p>
            <p class="input-wrapper required">* required fields</p>
          </div>
          <!-- <div class="col-12 d-none"></div>
          <div class="col-12 col-sm-10 col-md-6 col-lg-4 body-text input-wrapper">
            <p class="input-wrapper">We really need you to fill all the fields marked with asterisk. Thank you!</p>
          </div> -->
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid" :style="{'padding': `0 ${leftPadding}px`}">
        <!-- <div class="row">
          <div class="col-12 col-lg-4">
            
          </div>
        </div> -->
        <div class="row">
          <div class="col-12 col-sm-12 col-lg-4">
            <h2 class="section-title">
              CONTACT INFO
            </h2>
          </div>
          <div class="col-12 col-sm-12 col-lg-8">
            <div class="row">
              <div class="col-12 col-md-6 input-wrapper">
                <div ref="firstNameWrapper" class="input-wrapper rounded-border" :class="{'input-filled': formData.firstName != ''}">
                    <input class="bright-body-size-form" type="text" v-model="formData.firstName" aria-label="First name" tabindex="0"/>
                    <span ref="firstNameError" class="form-error"></span>
                    <span ref="firstNamePlaceholder" class="form-placeholder">First name* </span>
                </div>
              </div>
              <div class="col-12 col-md-6 input-wrapper">
                <div ref="lastNameWrapper" class="input-wrapper rounded-border" :class="{'input-filled': formData.lastName != ''}">
                  <input class="bright-body-size-form" type="text" v-model="formData.lastName" aria-label="Last name" tabindex="0"/>
                  <span ref="lastNameError" class="form-error"></span>
                  <span ref="firstNamePlaceholder" class="form-placeholder">Last name* </span>
                </div>
              </div>
              <div class="col-12 col-md-6 input-wrapper">
                <div ref="emailWrapper" class="input-wrapper rounded-border" :class="{'input-filled': formData.email != ''}">
                  <input class="bright-body-size-form" type="email" v-model="formData.email" aria-label="Email" tabindex="0"/>
                  <span ref="emailError" class="form-error"></span>
                  <span ref="firstNamePlaceholder" class="form-placeholder">Email*</span>
                </div>
              </div>
              <div class="col-12 col-md-6 input-wrapper">
                <div ref="phoneWrapper" class="input-wrapper rounded-border" :class="{'input-filled': formData.phone != ''}">
                  <input class="bright-body-size-form" type="phone" v-model="formData.phone" aria-label="Phone" tabindex="0"/>
                  <span ref="phoneError" class="form-error"></span>
                  <span ref="firstNamePlaceholder" class="form-placeholder">Phone</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid" :style="{'padding': `0 ${leftPadding}px`}">
        <div class="row">
          <div class="col-12 col-sm-12 col-lg-4">
            <h2 class="section-title">
              PROJECT INFO
            </h2> 
          </div>
          <div class="col-12 col-sm-12 col-lg-8">
            <div class="row">
              <div class="col-12 col-md-6 input-wrapper">
                <div ref="typeOfProjectWrapper" class="input-wrapper rounded-border" :class="{'input-filled': formData.typeOfProject.length }">
                  <SelectMultiBox v-model="formData.typeOfProject" :options="['application', 'extenderd reality', 'website', 'artifitial', 'branding']" tabindex="0"/>
                  <span ref="typeOfProjectError" class="form-error"></span>
                  <span ref="firstNamePlaceholder" class="form-placeholder">Type of project*</span>
                </div>
              </div>
              <div class="col-12 col-md-6 input-wrapper">
                <div ref="deadlineWrapper" class="input-wrapper rounded-border" :class="{'input-filled': formData.deadline != ''}">
                  <input class="bright-body-size-form" type="text" v-model="formData.deadline" aria-label="Project deadline" tabindex="0"/>
                  <span ref="deadlineError" class="form-error"></span>
                  <span ref="firstNamePlaceholder" class="form-placeholder">Project deadline</span>
                </div>
              </div>              
              <div class="col-12 input-wrapper">
                <div ref="descriptionWrapper" class="input-wrapper rounded-border" :class="{'input-filled': formData.description != ''}">
                  <span ref="descriptionInput" class="textarea bright-body-size-form" role="textbox" @input="onDescriptionChange" contenteditable aria-label="Project description" tabindex="0"></span>
                  <span ref="descriptionError" class="form-error"></span>
                  <span ref="firstNamePlaceholder" class="form-placeholder">Project description*</span>
                </div>
              </div>
              <div class="col-12 input-wrapper button-wrapper">
                <button class="submit-button" @click="submitForm" 
                  @mouseover="onSubmitMouseOver"
                  @mouseleave="onSubmitMouseLeave" tabindex="0">
                  Send
                  <div class="submit-border"></div>
                  <div class="submit-overlay" :style="{transform: 'scale(0.98, 1.1)'}"></div>
                </button> 
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-4 d-none d-lg-block"></div>
          <div class="col-8">
                     
          </div>
        </div> -->
      </div>
      
    </section>
    
  </div>
</template>

<script>
var validator = require("email-validator");
// import parsePhoneNumber from 'libphonenumber-js'
// import SelectBox from '@/components/contactform/SelectBox'
import SelectMultiBox from '@/components/contactform/SelectMultiBox'
import emitter from  '@/js/emitter'
import gsap from 'gsap';
import axios from 'axios';
import redrawBorder from '../../mixins/redraw-border';
export default {
  mixins: [redrawBorder],
  props: {
    onCloseClick: Function
  },
  data(){
    return{
      formData:{
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',

        budget: '',
        typeOfProject: [],
        deadline: '',
        description: ''
      },
      formWidth: 0,
      submitted: false
    }
  },
  mounted() {
    
    this.onResize()

  },
  methods: {
    onResize(){
      
      this.$nextTick(() => this.meassureForm())
      
    },
    meassureForm(){

      this.formWidth    = Math.round(this.$refs.formHeader.getBoundingClientRect().width);

    },
    validateForm(){
      var isFormValid = true;

      this.$refs.firstNameWrapper.classList.remove('error');
      this.$refs.lastNameWrapper.classList.remove('error');
      this.$refs.emailWrapper.classList.remove('error');
      this.$refs.phoneWrapper.classList.remove('error');
      // this.$refs.companyWrapper.classList.remove('error');
      // this.$refs.budgetWrapper.classList.remove('error');
      this.$refs.typeOfProjectWrapper.classList.remove('error');
      this.$refs.deadlineWrapper.classList.remove('error');
      this.$refs.descriptionWrapper.classList.remove('error');

      this.$refs.firstNameError.innerHTML = ""
      this.$refs.lastNameError.innerHTML = ""
      this.$refs.emailError.innerHTML = ""
      this.$refs.phoneError.innerHTML = ""
      // this.$refs.companyError.innerHTML = ""
      // this.$refs.budgetError.innerHTML = ""
      this.$refs.typeOfProjectError.innerHTML = ""
      this.$refs.deadlineError.innerHTML = ""
      this.$refs.descriptionError.innerHTML = ""

      if(!this.formData.firstName){
        this.$refs.firstNameWrapper.classList.add('error');
        this.$refs.firstNameError.innerHTML = "This field is required."
        isFormValid = false;
      }

      if(!this.formData.lastName){
        this.$refs.lastNameWrapper.classList.add('error');
        this.$refs.lastNameError.innerHTML = "This field is required."
        isFormValid = false;
      }
        
      if(!this.formData.email){
        this.$refs.emailWrapper.classList.add('error');
        this.$refs.emailError.innerHTML = "This field is required."
        isFormValid = false;
      } else if(this.formData.email && !validator.validate(this.formData.email)){
        this.$refs.emailWrapper.classList.add('error');
        this.$refs.emailError.innerHTML = "Email is incorrect"
        isFormValid = false;
      }
        
      // const phoneNumber = parsePhoneNumber(this.formData.phone, '');
                    
      // if(!this.formData.phone){
      //   this.$refs.phoneWrapper.classList.add('error');
      //   this.$refs.phoneError.innerHTML = "This field is required."
      //   isFormValid = false;
      // }
      // else if (!phoneNumber.isValid()) {
      //   this.$refs.phoneError.innerHTML = "Phone is incorrect"
      // }
      
      // if(!this.formData.company){
      //   this.$refs.companyError.innerHTML = "This field is required."
      // }
      
      // if(!this.formData.budget){
      //   this.$refs.budgetWrapper.classList.add('error');
      //   this.$refs.budgetError.innerHTML = "This field is required."
      //   isFormValid = false;
      // }
        
      if(!this.formData.typeOfProject.length){
        this.$refs.typeOfProjectWrapper.classList.add('error');
        this.$refs.typeOfProjectError.innerHTML = "This field is required."
        isFormValid = false;
      }

      // if(!this.formData.deadline){
      //   this.$refs.deadlineWrapper.classList.add('error');
      //   this.$refs.deadlineError.innerHTML = "This field is required."
      //   isFormValid = false;
      // }
    
      
      if(!this.formData.description){
        this.$refs.descriptionWrapper.classList.add('error');
        this.$refs.descriptionError.innerHTML = "This field is required."
        isFormValid = false;
      }
      console.log("%c 👨‍💼: validateForm", "font-size:16px;background-color:#aa6a1d;color:white;", isFormValid)
      return isFormValid
    },
    submitForm(){
      if(this.validateForm()){
        this.submitted = true;
        setTimeout(() => {
          const submittedSectionElements = this.$refs.submittedSection.querySelectorAll('.fade-in');
          gsap.to(submittedSectionElements, { opacity: 1, duration: 0.4, ease: 'power1.inOut', stagger: 0.08 });
        }, 50);

        console.log("%c 👨‍💼: submitForm", "font-size:16px;background-color:#aa6a1d;color:white;", this.formData)
        axios.post('https://live.soir.events/api/bm/contact', this.formData)
        .then(response => {
          console.log("%c 👨‍💼: submitForm -> response ", "font-size:16px;background-color:#aa6a1d;color:white;", response)
        })
        .catch(error => {
          console.log("%c 👨‍💼: submitForm -> error ", "font-size:16px;background-color:#aa6a1d;color:white;", error)
        });
      }
    },
    onDescriptionChange(){
      this.formData.description = this.$refs.descriptionInput.innerHTML;
    },
    onClose(){
      emitter.emit("onCloseContactForm")
    },
    onSubmitMouseOver(e){
      gsap.set(e.target.querySelector('.submit-overlay'), {scaleX: '98%'})
      gsap.to(e.target.querySelector('.submit-overlay'), {scaleX: '0%', duration: 0.3, ease: 'power1.inOut'})
    },
    onSubmitMouseLeave(e){
      gsap.to(e.target.querySelector('.submit-overlay'), {scaleX: '98%', duration: 0.3, ease: 'power1.inOut'})
    },
    onSocialMouseOut(e) {
      gsap.to(e.target.querySelector('.border-in-transition'), { width: '0%', left: '100%', duration: 0.3, ease: 'power.inOut' })
    },
    onSocialMouseOver(e) {
      gsap.set(e.target.querySelector('.border-in-transition'), { width: '0%', left: '0%', duration: 0.3, ease: 'power1.inOut' })
      gsap.to(e.target.querySelector('.border-in-transition'), { width: '100%', left: '0%', duration: 0.3, ease: 'power1.inOut' })
    },
  },
  computed:{
    windowWidth(){
      return this.$store.getters.windowWidth
    },
    leftPadding(){
      return this.$store.getters.homeColumnWidth
    }
    
  },
  watch:{
    windowWidth(){
      this.onResize()
    }
  },
  components: {
    // SelectBox,
    SelectMultiBox
  }
}
</script>