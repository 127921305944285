<template>
    <div class="portfolio-item-description bm-col-14">
        <div class="portfolio-item-description--lead">
            {{ lead }}
        </div>
        <div class="portfolio-item-description--table">
            <div class="portfolio-item-description--table--section">
                <div>role</div>
                <div>client</div>
                <div>year</div>
                <div>{{ recognition ? 'recognition' : '' }}</div>
            </div>
            <div class="portfolio-item-description--table--section">
                <div>{{ role }}</div>
                <div>{{ client }}</div>
                <div>{{ year }}</div>
                <div v-if="recognition">
                    <div v-for="item in recognition" v-bind:key="item">
                        {{ item }}
                    </div>
                </div>
                <div v-else></div>
                <a v-if="link" target="_blank" :href="link" >see live
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path
                            d="M0 7.99898L1.28389 9.28389L6.0523 4.51716C6.53874 4.03033 6.97734 3.59138 7.59935 2.8731C7.50366 3.84677 7.47973 4.77255 7.47973 5.73025L7.47176 7.38228H9.28998V2C9.28998 0.895432 8.39455 0 7.28998 0H1.90555V1.81165L3.55629 1.80367C4.51323 1.79569 5.43827 1.77973 6.40318 1.68396C5.69345 2.30646 5.25485 2.73743 4.76043 3.24022L0 7.99898Z"
                            fill="white" />
                    </svg></a>
            </div>
            <!-- mobile layout -->
            <div class="portfolio-item-description--table--section mobile">
                <div>role</div>
                <div>{{ role }}</div>
            </div>
            <div class="portfolio-item-description--table--section mobile">
                <div>client</div>
                <div>{{ client }}</div>
            </div>
            <div class="portfolio-item-description--table--section mobile">
                <div>year</div>
                <div>{{ year }}</div>
            </div>
            <div class="portfolio-item-description--table--section mobile">
                <div>honor</div>
                <div v-if="recognition">
                    <div v-for="item in recognition" v-bind:key="item">
                        {{ item }}
                    </div>
                </div>
                <div v-else>
                    —
                </div>
            </div>
            <a v-if="link" target="_blank" :href="link" class="mobile" >see live
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path
                            d="M0 7.99898L1.28389 9.28389L6.0523 4.51716C6.53874 4.03033 6.97734 3.59138 7.59935 2.8731C7.50366 3.84677 7.47973 4.77255 7.47973 5.73025L7.47176 7.38228H9.28998V2C9.28998 0.895432 8.39455 0 7.28998 0H1.90555V1.81165L3.55629 1.80367C4.51323 1.79569 5.43827 1.77973 6.40318 1.68396C5.69345 2.30646 5.25485 2.73743 4.76043 3.24022L0 7.99898Z"
                            fill="white" />
                    </svg></a>
        </div>
    </div>
</template>
    
<script>

export default {
    props: {
        lead: String,
        role: String,
        client: String,
        year: String,
        recognition: Array,
        link: String
    },
    data() {
        return { }
    },
}
</script>