import Utils from './../utils/Utils';

const obj = Utils.keyMirror({
  STAGE_RESIZE: 'STAGE_RESIZE',
  NAVI_OPENED: 'NAVI_OPENED',
  NAVI_CLOSED: 'NAVI_CLOSED',
});

export default obj;

