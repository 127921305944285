const DistortShader = {
    uniforms: {
        "tDiffuse": { type: "t", value: null },
        "mousePosition": { type: "vec2", value: { x: 0, y: 0 } },
        "resolution": { type: "vec2", value: { x: 0, y: 0 } },
    }, 
    

    vertexShader: [
        "varying vec2 vUv;",
        "void main() {",
        "vUv = uv;",
        "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
        "}"

    ].join("\n"),

    fragmentShader: `
        precision mediump float;
        
        uniform sampler2D tDiffuse;
        uniform vec2 resolution;
        uniform vec2 mousePosition;

        varying vec2 vUv;
        
        void main() {
            vec2 aspect = vec2(resolution.x / resolution.y, 1.0);
            vec2 mouse = mousePosition / resolution;
            vec2 uv = gl_FragCoord.xy / resolution;
            vec2 diff = uv * aspect - mouse * aspect;

            float distance = 1. / (1. + length(diff));
            float distortion = pow(distance, 10.0) * 1.5;
            
            vec2 offset = vec2(distortion * cos(distortion), distortion * sin(distortion));
            vec2 crumpledTexCoord = uv + offset * diff / aspect;

            gl_FragColor = texture2D(tDiffuse, crumpledTexCoord);
        }
    `

};

export { DistortShader }