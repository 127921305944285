<template>
    <div ref="imageContainer" class="deferred-image">
        <img :src="placeholderSrc" ref="placeholderBackground" class="deferred-image--placeholder-bg" />
        <img :src="placeholderSrc" ref="placeholderImage" class="deferred-image--placeholder" />
    </div>
</template>
    
<script>
import { PORTFOLIO_STATE } from "@/js/constants/PortfolioState.js"
import gsap from 'gsap'

const LOAD_STATE = {
    WAITING: 'WAITING',
    LOADING: 'LOADING',
    LOADED: 'LOADED'
}

export default {
    props: {
        src: String,
        placeholderSrc: String,
        alt: String
    },
    data() {
        return {
            isInViewport: false,
            loadState: LOAD_STATE.WAITING,
        }
    },
    methods: {
        onViewportMove() {
            if (this.loadState !== LOAD_STATE.WAITING) {
                return;
            }

            const offset = 0;
            const rect = this.$refs.imageContainer.getBoundingClientRect();
            if (
                rect.top + offset > window.innerHeight ||
                rect.bottom + offset < 0
            ) {
                this.isInViewport = false;
            } else {
                // this.zoom = gsap.utils.clamp(-2, 2, (rect.top + rect.bottom / 2) / window.innerHeight) * 200;
                // gsap.to(this.$refs.imageContainer, { y: this.zoom });

                if(this.loadState === LOAD_STATE.WAITING) {
                    gsap.to(this.$refs.imageContainer, {
                        opacity: 1,
                        duration: 0.4
                    })
                }

                this.isInViewport = true;
            }
        },
        load() {
            this.loadState = LOAD_STATE.LOADING;
            const newImage = document.createElement("img");
            newImage.style = 'z-index: 3';
            newImage.alt = this.$props.alt || '';

            newImage.onload = () => {
                gsap.set(newImage, { opacity: 0, scale: 1.05 })
                
                this.$refs.imageContainer.appendChild(newImage);
            
                gsap.to([newImage, this.$refs.placeholderBackground, this.$refs.placeholderImage], {
                    opacity: 1, scale: 1, duration: 0.5, ease: 'power1.out', onComplete: () => {
                        this.loadState = LOAD_STATE.LOADED;
                        this.$refs.imageContainer.removeChild(this.$refs.placeholderImage);
                        this.$refs.imageContainer.removeChild(this.$refs.placeholderBackground);
                    }
                });
            }

            newImage.src = this.$props.src;
        }
    },
    mounted() {
        gsap.set(this.$refs.imageContainer, {
            opacity: 0,
        })
    }, 
    computed: {
        scrollY() {
            return this.$store.getters.portfolioScrollOffset;
        },
        isPortfolioExpanded() {
            return this.$store.getters.projectViewState === PORTFOLIO_STATE.EXPANDED ? true : false;
        },
    },
    watch: {
        isPortfolioExpanded() {
            this.onViewportMove();
        },
        scrollY() {
            this.onViewportMove();
        },
        isInViewport() {
            if (this.loadState === LOAD_STATE.WAITING) {
                this.load();
            }
        }
    }
}
</script>