<template>
    <div class="portfolio-item portfolio-end-border bm-container-wrapper">

        <!-- SPACE FOR CARUSEL -->
        <div class="portfolio-space-for-carusel">
            <div class="scroll-to-read"> Scroll to read case study </div>
        </div>

        <div class="bm-row bm-container bm-sm bm-md bm-xs">
            <div class="bm-col-md-2 bm-col-xs-1"></div>
            <div class="bm-col-md-14 bm-col-xs-16 portfolio-item-content no-gutters">

                <ItemDescription
                    lead="Despite the many turmoils of the 20th century, Polish designers produced work that inspired many generations to come. We created an interactive guide to tell the story of 100 years of Polish design. A story of creativity blossoming despite changing political landscapes, tastes, habits, styles and needs."
                    role="UI/UX, Motion design, Art direction, Development" client="Adam Mickiewicz Insitute" year="2019" :recognition="[
                        'Awwwards SOTD',
                    ]" link="https://designguide.pl" />

                <div class="bm-col-14 portfolio-row-margin">
                    <VideoPlayer :topPosition="topPosition" :hasSound="true" :hasInfo="true"
                        path="/assets/portfolio/design/video/design.mp4" poster="/assets/portfolio/posters/design.webp">
                    </VideoPlayer>
                </div>

                <!-- <div class="bm-row portfolio-images bm-col-14"> -->
                    <div class="portfolio-item--images-mobile-row odd">
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/design/mobile/01m.webp"
                                        src="/assets/portfolio/design/mobile/01.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-col-md-5 bm-col-xs-2"></div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/design/mobile/02m.webp"
                                        src="/assets/portfolio/design/mobile/02.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img ">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/design/mobile/03m.webp"
                                        src="/assets/portfolio/design/mobile/03.webp" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- todo - wrong picture -->
                    <div class="bm-col-14 portfolio-row-margin">
                        <VideoPlayer :topPosition="topPosition" path="/assets/portfolio/design/video/001.mp4"
                            poster="/assets/portfolio/posters/Design-001.webp"></VideoPlayer>
                    </div>
                    <div class="portfolio-item--images-mobile-row even">
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/design/mobile/04m.webp"
                                        src="/assets/portfolio/design/mobile/04.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-d-none bm-d-xs-block bm-col-xs-2"></div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/design/mobile/05m.webp"
                                    src="/assets/portfolio/design/mobile/05.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-col-md-5 bm-col-xs-9"></div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img ">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/design/mobile/06m.webp"
                                        src="/assets/portfolio/design/mobile/06.webp" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->

                <div class="bm-col-14 portfolio-row-margin">
                    <VideoPlayer :topPosition="topPosition" path="/assets/portfolio/design/video/002.mp4"
                        poster="/assets/portfolio/posters/Design-002.webp"></VideoPlayer>
                </div>
            </div>
            <div class="bm-col-md-2 bm-col-xs-1"></div>
        </div>
    </div>
</template>
<script>
import VideoPlayer from '@/components/portfolio/VideoPlayer'
import ItemDescription from '@/components/portfolio/ItemDescription'
import portfolioItemMixin from '@/mixins/portfolio-item-mixin';
import DeferredImage from '@/components/portfolio/DeferredImage'


export default {
    mixins: [portfolioItemMixin],
    components: {
        VideoPlayer,
        ItemDescription,
        DeferredImage,

    }
}
</script>