<template>
    <div class="portfolio-item portfolio-end-border bm-container-wrapper">

        <!-- SPACE FOR CARUSEL -->
        <div class="portfolio-space-for-carusel">
            <div class="scroll-to-read"> Scroll to read case study </div>
        </div>

        <div class="bm-row bm-container bm-sm bm-md bm-xs">
            <div class="bm-col-md-2 bm-col-xs-1"></div>
            <div class="bm-col-md-14 bm-col-xs-16 portfolio-item-content no-gutters">

                <ItemDescription
                    lead="Experience the future of event planning with SOIR - the Event Management Platform that delivers unforgettable experiences for enterprise clients and individual users alike."
                    role="UI/UX, Motion Design, Art direction, Development" client="SOIR" year="2022" :recognition="[]" link="https://soir.events" />

                <div class="bm-col-14 portfolio-row-margin">
                    <div class="portfolio-img">
                        <div class="img-with-ratio-hd">
                            <DeferredImage placeholderSrc="/assets/portfolio/soir/desktop/01m.webp"
                                src="/assets/portfolio/soir/desktop/01.webp" alt="" />
                        </div>
                    </div>
                </div>

                <!-- <div class="bm-row portfolio-images bm-col-14"> -->
                    <div class="portfolio-item--images-mobile-row odd">
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/soir/mobile/01m.webp"
                                        src="/assets/portfolio/soir/mobile/01.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-col-md-5 bm-col-xs-2"></div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/soir/mobile/02m.webp"
                                        src="/assets/portfolio/soir/mobile/02.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img ">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/soir/mobile/03m.webp"
                                        src="/assets/portfolio/soir/mobile/03.webp" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="bm-row portfolio-images bm-col-14"> -->
                        <div class="portfolio-item--images-mobile-row even">
                            <div class="bm-col-md-3 bm-col-xs-7">
                                <div class="portfolio-img">
                                    <div class="img-with-ratio-mobile">
                                        <DeferredImage placeholderSrc="/assets/portfolio/soir/mobile/04m.webp"
                                            src="/assets/portfolio/soir/mobile/04.webp" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="bm-d-none bm-d-xs-block bm-col-xs-2"></div>
                            <div class="bm-col-md-3 bm-col-xs-7">
                                <div class="portfolio-img">
                                    <div class="img-with-ratio-mobile">
                                        <DeferredImage placeholderSrc="/assets/portfolio/soir/mobile/05m.webp"
                                            src="/assets/portfolio/soir/mobile/05.webp" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- </div> -->
                <!-- </div> -->
            </div>
            <div class="bm-col-md-2 bm-col-xs-1"></div>
        </div>
    </div>
</template>
<script>
// import VideoPlayer from '@/components/portfolio/VideoPlayer'
import ItemDescription from '@/components/portfolio/ItemDescription'
import DeferredImage from '@/components/portfolio/DeferredImage'
import portfolioItemMixin from '@/mixins/portfolio-item-mixin';

export default {
    mixins: [portfolioItemMixin],
    components: {
        // VideoPlayer,
        ItemDescription,
        DeferredImage,
    }
}
</script>