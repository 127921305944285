/* eslint-disable */
import Scroller from './Scroller';
import StageStore from './../StageStore';
import PageConstants from './../constants/PageConstants';
import * as dat from 'dat.gui';

let instance = null;

class ProjectsNavigation {
  constructor() {
    if (!instance) {
      this.naviActive = true;
      this.naviDOM = document.getElementById('projectsNavi');
      this.scroller = new Scroller();

      this.updateStageF = this.updateStage.bind(this);
      StageStore.on(PageConstants.STAGE_RESIZE, this.updateStageF);

      instance = this;

      // var gui = new dat.GUI();
      // const scrollerFolder = gui.addFolder('Scroller');
      // // scrollerFolder.add(this.scroller, 'speedDamping').min(0.01).max(1).step(0.05)
      // // scrollerFolder.add(this.scroller, 'speedMultiplier').name('Speed multiplier').min(0).max(5).step(0.1);      
      // scrollerFolder.add(this.scroller, 'didDrag').listen();
      // scrollerFolder.add(this.scroller, 'lastSpeed', -200, 200, 1).listen();

    }

    return instance;
  }

  updateStage(event) {
    this.scroller.resize();
  }

  initNavi() {
    
    if (StageStore.mobile === 0) {
      this.scroller.init(this.naviDOM, false);
    } else {
      this.scroller.init(this.naviDOM, true);
    }

    this.scroller.openNavi(0);
    // this.scroller.setSelectedProject(0)
  }
}

export default ProjectsNavigation;
