/* eslint-disable */
import * as THREE from 'three';
import Particle from './Particle';
import Cloth from './Cloth';

class ClothPhysics {
  constructor() {

    this.structuralSprings = true;
    this.hearSprings = true;
    this.bendingSprings = true;

    this.DAMPING = 0.9;
    this.DRAG = 1 - this.DAMPING;
    this.MASS = 0.0;

    this.restDistanceB = 2;
    this.restDistanceS = Math.sqrt(2);

    this.friction = 0.1;

    this.fabricLength = 400;// * 2 / 1.6;

    this.proportionX = 1.6;
    this.proportionY = 0.9;

    this.xSegs = Math.round(this.fabricLength * this.proportionX / 40);
    this.ySegs = Math.round(this.fabricLength * this.proportionY / 40);

    this.restDistance = 0;//this.fabricLength / this.xSegs;
    this.restDistanceY = 0;//this.fabricLength / this.ySegs;

    // var newCollisionDetection = true;

    this.wind = false;
    this.windStrength;
    this.windForce = new THREE.Vector3(0, 0, 0);

    this.rotate = false;
    this.pinned = 'Corners';
    this.thing = 'Ball';

    this.cornersPinned;
    this.oneEdgePinne;
    this.twoEdgesPinned;
    this.fourEdgesPinned;
    this.randomEdgesPinned;

    this.avoidClothSelfIntersection = false;

    this.clothInitialPosition = this.plane(this.fabricLength * this.proportionX, this.fabricLength * this.proportionY);

    this.cloth = new Cloth(this.xSegs, this.ySegs, this.fabricLength * this.proportionX, this.fabricLength * this.proportionY, this.clothInitialPosition);

    console.log("this.cloth: ", this.cloth);

    this.GRAVITY = 0;// 9.81 * 100; //
    this.gravity = new THREE.Vector3(0, -this.GRAVITY, 0).multiplyScalar(this.MASS);


    this.TIMESTEP = 180 / 1000;
    this.TIMESTEP_SQ = this.TIMESTEP * this.TIMESTEP;

    // var pins = [];


    this.ballSize = 400 / 4; // 40
    this.ballPosition = new THREE.Vector3(0, 0, -440 + this.ballSize);
    this.prevBallPosition = new THREE.Vector3(0, 0, -440 + this.ballSize);

    this.tmpForce = new THREE.Vector3();

    this.lastTime;

    this.pos;

    this.whereAmI;
    this.whereWasI;

    this.posFriction = new THREE.Vector3(0, 0, 0);
    this.posNoFriction = new THREE.Vector3(0, 0, 0);

    this.diff = new THREE.Vector3();
    this.objectCenter = new THREE.Vector3();

    this.a;
    this.b;
    this.c;
    this.d;
    this.e;
    this.f;

    this.nearestX;
    this.nearestY;
    this.nearestZ;
    this.currentX;
    this.currentY;
    this.currentZ;

    this.xDist;
    this.yDist;
    this.zDist;

    this.randomPoints = [];
    this.rand;
    this.randX;
    this.randY;

    this.scene;
  }

  pinCloth(choice) {
    if (choice == 'Corners') {
      this.cornersPinned = true;
      this.oneEdgePinned = false;
      this.twoEdgesPinned = false;
      this.fourEdgesPinned = false;
      this.randomEdgesPinned = false;
    } else if (choice == 'OneEdge') {
      this.cornersPinned = false;
      this.oneEdgePinned = true;
      this.twoEdgesPinned = false;
      this.fourEdgesPinned = false;
      this.randomEdgesPinned = false;
    } else if (choice == 'TwoEdges') {
      this.cornersPinned = false;
      this.oneEdgePinned = false;
      this.twoEdgesPinned = true;
      this.fourEdgesPinned = false;
      this.randomEdgesPinned = false;
    } else if (choice == 'FourEdges') {
      this.cornersPinned = false;
      this.oneEdgePinned = false;
      this.twoEdgesPinned = false;
      this.fourEdgesPinned = true;
      this.randomEdgesPinned = false;
    } else if (choice == 'Random') {
      this.cornersPinned = false;
      this.oneEdgePinned = false;
      this.twoEdgesPinned = false;
      this.fourEdgesPinned = false;
      this.randomEdgesPinned = true;

      this.rand = Math.round(Math.random() * 10) + 1;
      this.randomPoints = [];
      for (u = 0; u < rand; u++) {
        this.randX = Math.round(Math.random() * this.xSegs);
        this.randY = Math.round(Math.random() * this.ySegs);
        this.randomPoints.push([this.randX, this.randY]);
      }
    } else if (choice == 'None') {
      this.cornersPinned = false;
      this.oneEdgePinned = false;
      this.twoEdgesPinned = false;
      this.fourEdgesPinned = false;
      this.randomEdgesPinned = false;
    }
  }

  // DO WYWALENIA //
  /*wireFrame(){

    poleMat.wireframe = !poleMat.wireframe;
    clothMaterial.wireframe = !clothMaterial.wireframe;
    ballMaterial.wireframe = !ballMaterial.wireframe;

  } */

  // TWORZENIE PLANE //
  plane(width, height) {
    return function (u, v) {
      let x = u * width - width / 2;
      let y = v * height - height / 2; // height/2;
      let z = 500;// v * height - height/2;

      return new THREE.Vector3(x, y, z);
    };
  }

  /* Particle( x, y, z, mass ) {

    this.position = clothInitialPosition( x, y ); // position
    this.previous = clothInitialPosition( x, y ); // previous
    this.original = clothInitialPosition( x, y ); // original
    this.a = new THREE.Vector3( 0, 0, 0 ); // acceleration
    this.mass = mass;
    this.invMass = 1 / mass;
    this.tmp = new THREE.Vector3();
    this.tmp2 = new THREE.Vector3();

  }

  Particle.prototype.lockToOriginal = function() {

      this.position.copy( this.original );
      this.previous.copy( this.original );
  }

  Particle.prototype.lock = function() {

      this.position.copy( this.previous );
      this.previous.copy( this.previous );

  }

  Particle.prototype.addForce = function( force ) {

    this.a.add(
      this.tmp2.copy( force ).multiplyScalar( this.invMass )
    );

  };

  Particle.prototype.integrate = function( timesq ) {

    var newPos = this.tmp.subVectors( this.position, this.previous );
    newPos.multiplyScalar( DRAG ).add( this.position );
    newPos.add( this.a.multiplyScalar( timesq ) );

    this.tmp = this.previous;
    this.previous = this.position;
    this.position = newPos;

    this.a.set( 0, 0, 0 );

  };*/

  satisifyConstrains(p1, p2, distance) {
    this.diff.subVectors(p2.position, p1.position);
    let currentDist = this.diff.length();
    if (currentDist == 0) return; // prevents division by 0
    let correction = this.diff.multiplyScalar((currentDist - distance) / currentDist);
    let correctionHalf = correction.multiplyScalar(0.5);
    p1.position.add(correctionHalf);
    p2.position.sub(correctionHalf);
  }

  repelParticles(p1, p2, distance) {
    diff.subVectors(p2.position, p1.position);
    let currentDist = diff.length();
    if (currentDist == 0) return; // prevents division by 0
    if (currentDist < distance) {
      let correction = diff.multiplyScalar((currentDist - distance) / currentDist);
      let correctionHalf = correction.multiplyScalar(0.5);
      p1.position.add(correctionHalf);
      p2.position.sub(correctionHalf);
    }
  }


  /*Cloth( w, h, fabricWidth, fabricHeight ) {

    //w = w || 10;
    //h = h || 10;
    this.w = w;
    this.h = h;
    restDistance = fabricWidth/w; // assuming square cloth for now
    restDistanceY = fabricHeight/h;

    console.log("w: ", w);
    console.log("h: ", h);

    //console.log("l: ", l);

    console.log("restDistance: ", restDistance);
    console.log("restDistanceY: ", restDistanceY);

    var particles = [];
    var constrains = [];

    var u, v;

    // Create particles
    for (v=0; v<=h; v++) {
      for (u=0; u<=w; u++) {
        particles.push(
          new Particle(u/w, v/h, 0, MASS)
        );
      }
    }

      for (v=0; v<=h; v++) {
        for (u=0; u<=w; u++) {

          if(v<h && (u == 0 || u == w)){
            constrains.push( [
              particles[ index( u, v ) ],
              particles[ index( u, v + 1 ) ],
              restDistanceY
            ] );
          }

          if(u<w && (v == 0 || v == h)){
            constrains.push( [
              particles[ index( u, v ) ],
              particles[ index( u + 1, v ) ],
              restDistance
            ] );
          }
        }
      }


    // Structural

    if(structuralSprings){

      for (v=0; v<h; v++) {
        for (u=0; u<w; u++) {

          if(u!=0){
            constrains.push( [
              particles[ index( u, v ) ],
              particles[ index( u, v+1 ) ],
              restDistanceY
            ] );
          }

          if(v!=0){
            constrains.push( [
              particles[ index( u, v ) ],
              particles[ index( u+1, v ) ],
              restDistance
            ] );
          }

        }
      }
    }

    // Shear

    if(shearSprings){

    for (v=0;v<=h;v++)
    {
      for (u=0;u<=w;u++)
      {

        if(v<h && u<w){
          constrains.push([
            particles[index(u, v)],
            particles[index(u+1, v+1)],
            restDistanceS*restDistanceY
          ]);

          constrains.push([
            particles[index(u+1, v)],
            particles[index(u, v+1)],
            restDistanceS*restDistance
          ]);
        }

      }
    }
    }


  // Bending springs

    if(bendingSprings){

      for (v=0; v<h; v++)
      {

        for (u=0; u<w; u++)
        {

          if(v<h-1){
            constrains.push( [
              particles[ index( u, v ) ],
              particles[ index( u, v+2 ) ],
              restDistanceB*restDistanceY
            ] );
          }

          if(u<w-1){
            constrains.push( [
              particles[ index( u, v ) ],
              particles[ index( u+2, v ) ],
              restDistanceB*restDistance
            ] );
          }


        }
      }
    }


    this.particles = particles;
    this.constrains = constrains;

    function index( u, v ) {

      return u + v * ( w + 1 );

    }

    this.index = index;

  } */

  map(n, start1, stop1, start2, stop2) {
    return ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
  }

  simulate(time, sphere) {
    if (!this.lastTime) {
      this.lastTime = time;
      return;
    }

    let i,
    u, 
il, 
particles, 
particle, 
pt, 
constrains, 
constrain;

    // Aerodynamics forces
    if (this.wind) {
      this.windStrength = Math.cos(time / 7000) * 20 + 40;
      this.windForce.set(
        Math.sin(time / 2000),
        Math.cos(time / 3000),
        Math.sin(time / 1000),
      ).normalize().multiplyScalar(this.windStrength);

      // apply the wind force to the cloth particles
      let face, 
faces = clothGeometry.faces, 
normal;
      particles = this.cloth.particles;
      for (i = 0, il = faces.length; i < il; i++) {
        face = faces[i];
        normal = face.normal;
        tmpForce.copy(normal).normalize().multiplyScalar(normal.dot(windForce));
        particles[face.a].addForce(tmpForce);
        particles[face.b].addForce(tmpForce);
        particles[face.c].addForce(tmpForce);
      }
    }

    for (particles = this.cloth.particles, i = 0, il = particles.length; i < il; i++) {
      particle = particles[i];
      particle.addForce(this.gravity);
      //particle.lock();
      //particle.integrate( this.TIMESTEP_SQ ); // performs verlet integration
    }

    // Start Constrains

    constrains = this.cloth.constrains,
    il = constrains.length;
    for (i = 0; i < il; i++) {
      constrain = constrains[i];
      this.satisifyConstrains(constrain[0], constrain[1], constrain[2], constrain[3]);
    }


    this.prevBallPosition.copy(this.ballPosition);
    /* this.ballPosition.y = 50*Math.sin(Date.now()/600);
    this.ballPosition.x = 50*Math.sin(Date.now()/600);
    this.ballPosition.z = 50*Math.cos(Date.now()/600);
    sphere.position.copy( this.ballPosition ); */// maybe remove this since it's also in render()

    if (this.avoidClothSelfIntersection) {
      for (i = 0; i < particles.length; i++) {
        p_i = particles[i];
        for (j = 0; j < particles.length; j++) {
          p_j = particles[j];
          this.repelParticles(p_i, p_j, restDistance);
        }
      }
    }

    for (particles = this.cloth.particles, i = 0, il = particles.length; i < il; i++) {
      particle = particles[i];
      this.whereAmI = particle.position;
      this.whereWasI = particle.previous;

      // check to see if point is inside sphere
      if (sphere.visible) {
        this.diff.subVectors(this.whereAmI, this.ballPosition);
        if (this.diff.length() < this.ballSize) {
          // if yes, we've collided, so take correcting action

          // no friction behavior:
          // project point out to nearest point on sphere surface
          this.diff.normalize().multiplyScalar(this.ballSize);
          this.posNoFriction.copy(this.ballPosition).add(this.diff);

          this.diff.subVectors(this.whereWasI, this.ballPosition);

          if (this.diff.length() > this.ballSize) {
            // with friction behavior:
            // add the distance that the sphere moved in the last frame
            // to the previous position of the particle
            this.diff.subVectors(this.ballPosition, this.prevBallPosition);
            this.posFriction.copy(this.whereWasI).add(this.diff);

            this.posNoFriction.multiplyScalar(1 - this.friction);
            this.posFriction.multiplyScalar(this.friction);
            this.whereAmI.copy(this.posFriction.add(this.posNoFriction));
          } else {
            this.whereAmI.copy(this.posNoFriction);
          }
        }
      }
    }

    // Floor Constains
    for (particles = this.cloth.particles, i = 0, il = particles.length;
i < il; i++) {
      particle = particles[i];
      this.pos = particle.position;
      particle.previous.copy(particle.position);

      if (this.pos.y < -249) { this.pos.y = -249; }
    }

    // Pin Constrains
    if (this.cornersPinned) {
      // could also do particles[blah].lock() which will lock particles to wherever they are, not to their original position
      particles[this.cloth.index(0, 0)].lockToOriginal();
      particles[this.cloth.index(xSegs, 0)].lockToOriginal();
      particles[this.cloth.index(0, ySegs)].lockToOriginal();
      particles[this.cloth.index(xSegs, ySegs)].lockToOriginal();
    } else if (this.oneEdgePinned) {
      for (u = 0; u <= xSegs; u++) {
        particles[this.cloth.index(u, 0)].lockToOriginal();
      }
    } else if (this.twoEdgesPinned) {
      for (u = 0; u <= xSegs; u++) {
        particles[this.cloth.index(0, u)].lockToOriginal();
        particles[this.cloth.index(xSegs, u)].lockToOriginal();
      }
    } else if (this.fourEdgesPinned) {
      for (u = 0; u <= xSegs; u++) {
        particles[this.cloth.index(0, u)].lockToOriginal();
        particles[this.cloth.index(xSegs, u)].lockToOriginal();
        particles[this.cloth.index(u, 0)].lockToOriginal();
        particles[this.cloth.index(u, xSegs)].lockToOriginal();
      }
    } else if (this.randomEdgesPinned) {
      for (u = 0; u < randomPoints.length; u++) {
        rand = randomPoints[u];
        randX = rand[0];
        randY = rand[1];
        particles[this.cloth.index(randX, randY)].lockToOriginal();
      }
    }/*else{
      for (u = 0; u <= this.xSegs; u++) {
        particles[this.cloth.index(0, u)].lock();
        particles[this.cloth.index(this.xSegs, u)].lock();
        particles[this.cloth.index(u, 0)].lock();
        particles[this.cloth.index(u, this.ySegs)].lock();
      }
    }*/
  }
}

export default ClothPhysics;
