
<template>
    <!-- <div class="form-bg careers-bg"></div> -->

    <div class="contact-form">

        <section>
            <div class="container-fluid" ref="formHeader" :style="{ 'padding': `0 ${leftPadding}px` }">
                <div class="row">
                    <h1 class="form-title">
                        CAREERS
                    </h1>
                </div>
                <div class="row">
                    <div class="body-text input-wrapper lead" style="margin-bottom: 0;">
                        <p class="input-wrapper bright-body-size">We are always looking for unique and passionate talents who are willing to
                            share their skills and work with us. Please fill out this form or you may reach us directly by
                            phone or email if you prefer.</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container-fluid" :style="{ 'padding': `0 ${leftPadding}px` }">
                <div class="row">
                    <div class="col-12 col-sm-12 col-lg-5">
                        <h2 class="section-title">
                            CURRENT OPENINGS
                        </h2>
                    </div>
                    <div class="col-12 col-sm-12 col-lg-7">
                        <div class="row">
                            <a :href="'mailto:jobs@wearebright.studio?subject=' + opening.name" class="col-12 opening" v-for="opening in openings" v-bind:key="opening.name"
                                @mouseenter="redrawBorder">
                                <div class="opening--requirements">
                                    <div class="opening--requirements--item">
                                        {{ opening.experience }} years of experience
                                    </div>
                                    <div class="opening--requirements--item">
                                        english {{ opening.english }}
                                    </div>
                                    <div class="opening--requirements--item">
                                        {{ opening.remote ? 'onsite / remote' : 'onsite' }}
                                    </div>
                                </div>
                                <div class="opening--row">
                                    <div class="opening--row--name bright-body-size">
                                        {{ opening.name }}
                                    </div>
                                    <div class="opening--row--apply bright-body-size">
                                        Apply
                                    </div>
                                    <div class="border-redraw-transition left"></div>
                                    <div class="border-redraw-transition right"></div>
                                </div>

                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import emitter from '@/js/emitter'
import redrawBorder from '@/mixins/redraw-border';

export default {
    mixins: [redrawBorder],
    data() {
        return {
            openings: [
                // {
                //     name: 'Graphic Designer',
                //     experience: '2+',
                //     english: 'b2+',
                //     remote: true
                // },
                {
                    name: 'Art Director',
                    experience: '4+',
                    english: 'b2+',
                    remote: true
                },
                {
                    name: 'Backend Developer (PHP, Node.js, Python)',
                    experience: '4+',
                    english: 'b2+',
                    remote: true
                },
                {
                    name: 'Project Manager / Product Owner',
                    experience: '3+',
                    english: 'b2+',
                    remote: true
                }
            ],
            formWidth: 0
        }
    },
    mounted() {
        this.onResize()
    },
    methods: {
        onResize() {
            this.$nextTick(() => this.meassureForm())
        },
        meassureForm() {
            this.formWidth = Math.round(this.$refs.formHeader.getBoundingClientRect().width);
        },
        onClose() {
            emitter.emit("onCloseContactForm")
        },
    },
    computed: {
        windowWidth() {
            return this.$store.getters.windowWidth
        },
        leftPadding() {
            return this.$store.getters.homeColumnWidth
        }

    },
    watch: {
        windowWidth() {
            this.onResize()
        }
    },
}
</script>