/* eslint-disable */
import Particle from './Particle';

class Cloth {
  constructor(w, h, fabricWidth, fabricHeight, func) {
    // w = w || 10;
    // h = h || 10;

    this.clothInitialPosition = func;

    this.w = w;
    this.h = h;
    this.restDistance = fabricWidth / w;
    this.restDistanceY = fabricHeight / h;

    this.particles = [];
    this.constrains = [];

    this.u = 0;
    this.v = 0;

    // Create particles
    for (this.v = 0; this.v <= this.h; this.v++) {
      for (this.u = 0; this.u <= this.w; this.u++) {
        this.particles.push(new Particle(this.u / this.w, this.v / this.h, 0, 0, this.clothInitialPosition));
      }
    }

    for (this.v = 0; this.v <= this.h; this.v++) {
      for (this.u = 0; this.u <= this.w; this.u++) {
        if (this.v < this.h && (this.u === 0 || this.u === this.w)) {
          this.constrains.push([
            this.particles[this.index(this.u, this.v)],
            this.particles[this.index(this.u, this.v + 1)],
            this.restDistanceY,
          ]);
        }

        if (this.u < this.w && (this.v == 0 || this.v == this.h)) {
          this.constrains.push([
            this.particles[this.index(this.u, this.v)],
            this.particles[this.index(this.u + 1, this.v)],
            this.restDistance,
          ]);
        }
      }
    }

    // Structural

    if (this.structuralSprings) {
      for (this.v = 0; this.v < this.h; this.v++) {
        for (this.u = 0; this.u < this.w; this.u++) {
          if (this.u != 0) {
            this.constrains.push([
              this.particles[this.index(this.u, this.v)],
              this.particles[this.index(this.u, this.v + 1)],
              this.restDistanceY,
            ]);
          }

          if (this.v != 0) {
            this.constrains.push([
              this.particles[this.index(this.u, this.v)],
              this.particles[this.index(this.u + 1, this.v)],
              this.restDistance,
            ]);
          }
        }
      }
    }

    // Shear

    if (this.shearSprings) {
      for (this.v = 0; this.v <= this.h; this.v++) {
        for (this.u = 0; this.u <= this.w; this.u++) {
          if (this.v < this.h && this.u < this.w) {
            this.constrains.push([
              this.particles[this.index(this.u, this.v)],
              this.particles[this.index(this.u + 1, this.v + 1)],
              this.restDistanceS * this.restDistanceY,
            ]);

            this.constrains.push([
              this.particles[this.index(this.u + 1, this.v)],
              this.particles[this.index(this.u, this.v + 1)],
              this.restDistanceS * this.restDistance,
            ]);
          }
        }
      }
    }


    // Bending springs

    if (this.bendingSprings) {
      for (this.v = 0; this.v < this.h; this.v++) {
        for (this.u = 0; this.u < this.w; this.u++) {
          if (this.v < this.h - 1) {
            this.constrains.push([
              this.particles[this.index(this.u, this.v)],
              this.particles[this.index(this.u, this.v + 2)],
              this.restDistanceB * this.restDistanceY,
            ]);
          }

          if (this.u < this.w - 1) {
            this.constrains.push([
              this.particles[this.index(this.u, v)],
              this.particles[this.index(this.u + 2, v)],
              this.restDistanceB * this.restDistance,
            ]);
          }
        }
      }
    }


    // this.particles = particles;
    // this.constrains = constrains;

    // this.index = index;
  }

  index(u, v) {
    return u + (v * (this.w + 1));
  }
}

export default Cloth;

