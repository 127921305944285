/* eslint-disable */
import StageStore from './../StageStore';
import PageConstants from './../constants/PageConstants';
import ProjectConstants from './../constants/ProjectConstants';

import ProjectsNavigation from './../navi/ProjectsNavigation';
import ProjectsStore from '../ProjectsStore';
import { TweenLite, Power1 } from 'gsap';

import CSSPlugin from "gsap/CSSPlugin";

let instance = null;

class ProjectsPage {
  constructor() {
    if (!instance) {
      instance = this;

      this.updateStageF = this.updateStage.bind(this);

      // PROJECTS //
      this.projects_navi = new ProjectsNavigation();

      this.$project_name = document.getElementById("page-project-name");
      this.$project_about = document.getElementById("page-project-about");
      this.$project_services = document.getElementById("page-project-services");
      this.$project_awards = document.getElementById("page-project-awards");
      this.$project_client = document.getElementById("page-project-client");
      
      StageStore.on(PageConstants.STAGE_RESIZE, this.updateStageF);

    }

    return instance;
  }

  closeProject() {
    TweenLite.to("#page-project-container", 0.3, {autoAlpha:0, ease:Power1.easeOut});
  }
  
  init (){
    this.projects_navi.initNavi();
  }

  updateStage(event) {

  }
}

export default ProjectsPage;
