const ChessShader = {
    uniforms: {
        "resolution": { type: "vec2", value: { x: 0, y: 0 } },
    },

    vertexShader: [
        "varying vec2 vUv;",
        "void main() {",
        "vUv = uv;",
        "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
        "}"

    ].join("\n"),

    fragmentShader: `
        precision mediump float;

        uniform vec2 resolution;

        void main() {
            vec2 aspect = vec2(resolution.x / resolution.y, 1.0);
            vec2 st = gl_FragCoord.xy / resolution.xy * aspect.xy;
            float square_size = 0.02;
            vec2 square_pos = floor(st / square_size) * square_size;
            float tile = mod(floor(st.x/square_size) + floor(st.y/square_size), 2.0);
            gl_FragColor = vec4(vec3(tile), 1.0);
        }
    `

};

export { ChessShader }