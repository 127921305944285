<template>
<div class="projects-carusel-container" id="projects-carusel-container">

  <div class="portfolio-expanded-item portfolio-item">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12" >
          <div id="bootstrap-content-column-ref"></div>
        </div>
      </div>
    </div>
  </div>
  
  <main>
    <div id="background">
      <canvas id="cloth_canvas" width="100%" height="100%" style="width: 100%; height: 100%; cursor: auto;"></canvas>
    </div>
    <div id="page" ref="page">
      <div id="page-projects-container">
        <ul class="projects-navi" id="projectsNavi">
          <li 
            v-for="project in $store.getters.projects" 
            class="projects-item-navi"
            :key="project.title">
            <a href="#" rel="follow">
              <div class="text-navi-s">{{project.title}}</div>
              <div class="text-navi">{{project.title}}</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </main>
</div>
</template>

<script>
import App from '../js/page'
import ProjectsStore from '../js/ProjectsStore';
import ProjectConstants from '../js/constants/ProjectConstants';
import gsap from 'gsap';

// import emitter from  '@/js/emitter'
import { PortfolioTransitionsParams } from '@/js/constants/ProjectConstants';
import portfolioMixin from '@/mixins/portfolio-mixin'

export default{
  mixins: [portfolioMixin],
  emits: ['on-project-changed'],
  mounted(){
    this.app = new App(this);
    ProjectsStore.on(ProjectConstants.CARUSEL_PORTFOLIO_CHANGED, this.onProjectChanged);
    gsap.set(this.$refs.page, {autoAlpha: 0})
  },
  methods: {
    onPortfolioWillExpand(){
      console.log("WILL EXPAND")
      gsap.timeline()
        .to(this.$refs.page, {
            delay:0.2, ease:"power1.out", duration:0.3,
            autoAlpha: 1,
        });
    },
    onPortfolioExpanded(){
      gsap.timeline()
        .to(this.$refs.page, {
            ...PortfolioTransitionsParams,
            autoAlpha: 1,
        });
    },
    onPortfolioWillShrink(){
      gsap.timeline()
        .to(this.$refs.page, {
          ...PortfolioTransitionsParams,
          duration: PortfolioTransitionsParams.duration / 2,
          autoAlpha: 0,
      });
    },
    nextProject(){
      ProjectsStore.showNextProject();
    },
    showInfo(){
      ProjectsStore.showInfo();
    },
    onProjectChanged(store){
      this.$emit('on-project-changed', store.selectedProject);
    },
  },
}
</script>
