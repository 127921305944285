/* eslint-disable */
import emitter from '@/js/emitter';
// import OrbitControls from '@jsantell/three-orbit-controls';
import CameraControls from 'camera-controls';
import * as dat from 'dat.gui';
import DragControls from 'drag-controls';
import gsap from 'gsap';
import * as THREE from 'three';
import projectsStore from '../ProjectsStore';
import PageConstants from './../constants/PageConstants';
import ProjectConstants from './../constants/ProjectConstants';
import ProjectsStore from './../ProjectsStore';
import StageStore from './../StageStore';
import Detector from './../utils/Detector';
import Stats from './../utils/Stats';
import Cloth from './Cloth';
import ClothPhysics from './ClothPhysics';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { ShiftShader } from './postprocessing/ShiftShader';
import { DistortShader } from './postprocessing/DistortShader';
import { ChessShader } from './postprocessing/ChessShader';

class PageBackground {
  constructor(vue) {
    if (!Detector.webgl) Detector.addGetWebGLMessage();

    DragControls.install({ THREE });
    CameraControls.install({ THREE });

    this.vue = vue;
    
    this.projectInTransition = false;

    this.clock = new THREE.Clock();
    this.stats = new Stats();

    this.guiEnabled = true;

    this.clothPhysics;

    this.container;
    this.stats;
    this.controls;
    this.camera;
    this.scene;
    this.renderer;
    this.composer;

    this.chessContainer;
    this.chessRenderer;
    this.chessComposer;

    this.renderPass;
    this.shiftPass;
    this.chessPass;
    this.distortPass;
    this.copyPass;

    this.clothGeometry;
    this.groundMaterial;

    this.clothInitialPosition;

    this.sphere;
    this.table;
    this.boundingBox;
    this.object;
    this.pivot;
    this.collidableMeshList = [];

    this.gui;
    this.guiControls;

    this.poleMat;
    this.clothMaterial;
    this.materialShader;

    this.rotationTmp = this.rotationTmp2 = 0;

    this.ballMaterial;

    this.mouse = new THREE.Vector2(0, 0);

    this.rotation = new THREE.Vector2(0, 0);

    this.objects;
    this.tmpPos = new THREE.Vector3(0, 0, 0);

    this.mClicked = false;

    // this.positionBallZ = ballSize + 150;

    this.lightsArray;

    this.light1;
    this.light2;
    this.light3;

    this.helper1;
    this.helper2;
    this.helper3;

    this.lightDragger1;
    this.lightDragger2;
    this.lightDragger3;
    this.lightDragger1S;
    this.lightDragger2S;
    this.lightDragger3S;

    this.lightTmp1;
    this.lightTmp2;
    this.lightTmp3;

    this.guiVisible = 'none';

    this.clothShiness = 0;

    this.selectedCloth = 0;

    this.openedBox = true;

    this.dollyDistance = 700;
    this.expandedPortfolioItemRect = null;
  }

  enableGUI() {
    if (this.guiEnabled) {
      const that = this;


      this.guiControls = new function () {
        this.friction = that.friction;
        this.particles = that.xSegs;
        this.rotate = that.rotate;

        this.wind = that.wind;
        this.thing = that.thing;
        this.pinned = that.pinned;

        this.avoidClothSelfIntersection = that.avoidClothSelfIntersection;

        this.fabricLength = that.fabricLength;
        this.structuralSprings = that.structuralSprings;

        this.bendingSprings = that.bendingSprings;
        this.bendingSpringLengthMultiplier = that.restDistanceB;

        this.shearSprings = that.shearSprings;
        this.shearSpringLengthMultiplier = that.restDistanceS;

        this.clothColor = that.clothMaterial.color.getHex();
        this.clothSpecular = that.clothMaterial.specular.getHex();

        this.groundColor = 0x404761;
        this.groundSpecular = 0x404761;

        this.fogColor = that.scene.fog.color.getHex();

        this.light1 = that.light1.color.getHex();
        this.light2 = that.light2.color.getHex();
        this.light3 = that.light3.color.getHex();

        this.light1Intensity = that.light1.intensity;
        this.light2Intensity = that.light2.intensity;
        this.light3Intensity = that.light3.intensity;

        this.lightPosition1x = that.lightDragger1.position.x;
        this.lightPosition1y = that.lightDragger1.position.y;
        this.lightPosition1z = that.lightDragger1.position.z;

        this.lightPosition2x = that.lightDragger2.position.x;
        this.lightPosition2y = that.lightDragger2.position.y;
        this.lightPosition2z = that.lightDragger2.position.z;

        this.lightPosition3x = that.lightDragger3.position.x;
        this.lightPosition3y = that.lightDragger3.position.y;
        this.lightPosition3z = that.lightDragger3.position.z;

        this.lightPositionT1x = that.lightDragger1S.position.x;
        this.lightPositionT1y = that.lightDragger1S.position.y;
        this.lightPositionT1z = that.lightDragger1S.position.z;

        this.lightPositionT2x = that.lightDragger2S.position.x;
        this.lightPositionT2y = that.lightDragger2S.position.y;
        this.lightPositionT2z = that.lightDragger2S.position.z;

        this.lightPositionT3x = that.lightDragger3S.position.x;
        this.lightPositionT3y = that.lightDragger3S.position.y;
        this.lightPositionT3z = that.lightDragger3S.position.z;


        this.clothShiness = that.clothMaterial.shininess;

        console.log('that.clothShiness: ', that.clothShiness);

        this.clothEmissive = that.clothMaterial.emissive.getHex();

        that.helper1.update();
        that.helper2.update();
        that.helper3.update();
      }();

      this.gui = new dat.GUI();
      this.gui.remember(this.guiControls);
      this.gui.domElement.style.display = this.guiVisible;

      this.helper1.visible = false;
      this.helper2.visible = false;
      this.helper3.visible = false;

      this.lightDragger1.visible = false;
      this.lightDragger2.visible = false;
      this.lightDragger3.visible = false;
      this.lightDragger1S.visible = false;
      this.lightDragger2S.visible = false;
      this.lightDragger3S.visible = false;

      this.updateHelper();

      const f3 = this.gui.addFolder('Appearance');
      f3.addColor(this.guiControls, 'clothColor').name('cloth color').onChange((value) => { that.clothMaterial.color.setHex(value); that.clothMaterial.color.getHSL(that.clothColor); });
      f3.add(this.guiControls, 'clothShiness', 0, 100).name('cloth shininess').onChange((value) => {
        that.clothMaterial.shininess = value; console.log('VALUE:', that.clothShiness);
        that.clothShiness = that.clothMaterial.shininess;
      });
      f3.addColor(this.guiControls, 'clothEmissive', 0, 100).name('cloth emissive').onChange((value) => { that.clothMaterial.emissive.setHex(value); });
      f3.addColor(this.guiControls, 'clothSpecular').name('cloth reflection').onChange((value) => { that.clothMaterial.specular.setHex(value); });
      f3.addColor(this.guiControls, 'fogColor').onChange((value) => { that.scene.fog.color.setHex(value); that.renderer.setClearColor(that.scene.fog.color); });

      const f4 = this.gui.addFolder('Lights');
      f4.addColor(this.guiControls, 'light1').name('light1 color').onChange((value) => { that.light1.color.setHex(value); that.light1.color.getHSL(that.lightTmp1.colorHSL); });
      f4.addColor(this.guiControls, 'light2').name('light2 color').onChange((value) => { that.light2.color.setHex(value); that.light2.color.getHSL(that.lightTmp2.colorHSL); });
      f4.addColor(this.guiControls, 'light3').name('light3 color').onChange((value) => { that.light3.color.setHex(value); that.light3.color.getHSL(that.lightTmp3.colorHSL); });
      f4.add(this.guiControls, 'light1Intensity', 0, 8).name('light1 intensity').onChange((value) => { that.light1.intensity = value; that.lightTmp1.intensity = that.light1.intensity; });
      f4.add(this.guiControls, 'light2Intensity', 0, 8).name('light2 intensity').onChange((value) => { that.light2.intensity = value; that.lightTmp2.intensity = that.light2.intensity; });
      f4.add(this.guiControls, 'light3Intensity', 0, 8).name('light3 intensity').onChange((value) => { that.light3.intensity = value; that.lightTmp3.intensity = that.light3.intensity; });

      const f5 = this.gui.addFolder('Lights pos');
      f5.add(this.guiControls, 'lightPosition1x').name('light1 x').onChange((value) => { that.lightDragger1.position.x = value; that.helper1.update(); }).listen();
      f5.add(this.guiControls, 'lightPosition1y').name('light1 y').onChange((value) => { that.lightDragger1.position.y = value; that.helper1.update(); }).listen();
      f5.add(this.guiControls, 'lightPosition1z').name('light1 z').onChange((value) => { that.lightDragger1.position.z = value; that.helper1.update(); }).listen();
      f5.add(this.guiControls, 'lightPosition2x').name('light2 x').onChange((value) => { that.lightDragger2.position.x = value; that.helper2.update(); }).listen();
      f5.add(this.guiControls, 'lightPosition2y').name('light2 y').onChange((value) => { that.lightDragger2.position.y = value; that.helper2.update(); }).listen();
      f5.add(this.guiControls, 'lightPosition2z').name('light2 z').onChange((value) => { that.lightDragger2.position.z = value; that.helper2.update(); }).listen();
      f5.add(this.guiControls, 'lightPosition3x').name('light3 x').onChange((value) => { that.lightDragger3.position.x = value; that.helper3.update(); }).listen();
      f5.add(this.guiControls, 'lightPosition3y').name('light3 y').onChange((value) => { that.lightDragger3.position.y = value; that.helper3.update(); }).listen();
      f5.add(this.guiControls, 'lightPosition3z').name('light3 z').onChange((value) => { that.lightDragger3.position.z = value; that.helper3.update(); }).listen();

      f5.add(this.guiControls, 'lightPositionT1x').name('light1 target x').onChange((value) => { that.lightDragger1S.position.x = value; }).listen();
      f5.add(this.guiControls, 'lightPositionT1y').name('light1 target y').onChange((value) => { that.lightDragger1S.position.y = value; }).listen();
      f5.add(this.guiControls, 'lightPositionT1z').name('light1 target z').onChange((value) => { that.lightDragger1S.position.z = value; }).listen();
      f5.add(this.guiControls, 'lightPositionT2x').name('light2 target x').onChange((value) => { that.lightDragger2S.position.x = value; }).listen();
      f5.add(this.guiControls, 'lightPositionT2y').name('light2 target y').onChange((value) => { that.lightDragger2S.position.y = value; }).listen();
      f5.add(this.guiControls, 'lightPositionT2z').name('light2 target z').onChange((value) => { that.lightDragger2S.position.z = value; }).listen();
      f5.add(this.guiControls, 'lightPositionT3x').name('light3 target x').onChange((value) => { that.lightDragger3S.position.x = value; }).listen();
      f5.add(this.guiControls, 'lightPositionT3y').name('light3 target y').onChange((value) => { that.lightDragger3S.position.y = value; }).listen();
      f5.add(this.guiControls, 'lightPositionT3z').name('light3 target z').onChange((value) => { that.lightDragger3S.position.z = value; }).listen();
    }
  }

  keydown(e) {
    const keyName = e.keyCode;

    return;
    
    if (keyName === 32) {
      if (this.guiVisible === 'block') {
        this.guiVisible = 'none';

        this.helper1.visible = false;
        this.helper2.visible = false;
        this.helper3.visible = false;

        this.lightDragger1.visible = false;
        this.lightDragger2.visible = false;
        this.lightDragger3.visible = false;
        this.lightDragger1S.visible = false;
        this.lightDragger2S.visible = false;
        this.lightDragger3S.visible = false;

      } else {
        this.guiVisible = 'block';

        this.helper1.visible = true;
        this.helper2.visible = true;
        this.helper3.visible = true;

        this.lightDragger1.visible = true;
        this.lightDragger2.visible = true;
        this.lightDragger3.visible = true;
        this.lightDragger1S.visible = true;
        this.lightDragger2S.visible = true;
        this.lightDragger3S.visible = true;
      }

      const dom = this.gui.domElement;
      dom.style.display = this.guiVisible;
    }
  }

  clothChange() {

    //return;

    const len = this.clothPhysics.cloth.particles.length;
    let i = 0;

    //this.changeFog();

    for (i; i < len; i++) {
      this.clothPhysics.cloth.particles[i].positionTMP = this.clothPhysics.cloth.particles[i].position;

      if (this.openedBox) {
        const d = new THREE.Vector3(this.clothPhysics.cloth.particles[i].positionTMP.x, this.clothPhysics.cloth.particles[i].positionTMP.y, this.clothPhysics.cloth.particles[i].positionTMP.z).distanceTo(new THREE.Vector3(0, 0, 0));
        gsap.killTweensOf(this.clothPhysics.cloth.particles[i].positionTMP)
        gsap.to(this.clothPhysics.cloth.particles[i].positionTMP, { duration: d / 640, z: projectsStore.getCloth().main[i].z, ease: 'power3.out', delay: 20 / d, overwrite: true });
        gsap.to(this.clothPhysics.cloth.particles[i].positionTMP, { duration: d / 1300,
          x: projectsStore.getCloth().main[i].x, y: projectsStore.getCloth().main[i].y, ease: 'power3.out', delay: 20 / d, onUpdate: this.updatePrevious, onUpdateParams: [this.clothPhysics.cloth.particles[i].position, '{self}'],
        });
      } else {
        const d = new THREE.Vector3(this.clothPhysics.cloth.particles[i].positionTMP.x, this.clothPhysics.cloth.particles[i].positionTMP.y, this.clothPhysics.cloth.particles[i].positionTMP.z).distanceTo(new THREE.Vector3(0, 0, 0));

        gsap.killTweensOf(this.clothPhysics.cloth.particles[i].positionTMP)
        gsap.to(this.clothPhysics.cloth.particles[i].positionTMP, { duration: d / 4000, z: projectsStore.getCloth().crushed[this.selectedCloth].data[i].z + projectsStore.getCloth().crushed[this.selectedCloth].z, ease: 'power1.out', delay: 10 / d, overwrite: true });
        gsap.to(this.clothPhysics.cloth.particles[i].positionTMP, { duration: d / 2000, x: projectsStore.getCloth().crushed[this.selectedCloth].data[i].x + projectsStore.getCloth().crushed[this.selectedCloth].x, ease: 'power1.out', delay: 10 / d});
        gsap.to(this.clothPhysics.cloth.particles[i].positionTMP, { duration: d / 1000,
          y: projectsStore.getCloth().crushed[this.selectedCloth].y + projectsStore.getCloth().crushed[this.selectedCloth].data[i].y, ease: 'power1.out', delay: 10 / d, onUpdate: this.updatePrevious, onUpdateParams: [this.clothPhysics.cloth.particles[i].position, this.clothPhysics.cloth.particles[i].positionTMP],
        });
      }
    }
    
    //return;

    if (this.openedBox) {
      
      //return;

      const color1 = { h: 0, s: 0, l: 0 };
      const color2 = { h: 0, s: 0, l: 0 };
      const color3 = { h: 0, s: 0, l: 0 };

      this.light1.color.getHSL(color1);
      this.light2.color.getHSL(color2);
      this.light3.color.getHSL(color3);

      // this.light1.color.setHSL(0, 0, 0);
      // this.light2.color.setHSL(0, 0, 0);
      // this.light3.color.setHSL(0, 0, 0);

      const clothColorTmp = { h:0, s: 0, l: 0 };
      
      this.clothMaterial.color.getHSL(clothColorTmp);

      //console.log("OPENED BOX");

      //return;
      gsap.killTweensOf(clothColorTmp)
      gsap.to(clothColorTmp, {duration:0.3, 
        h: this.clothColor.h, s: this.clothColor.s, l: this.clothColor.l, onUpdate: (color) => { color.setHSL(clothColorTmp.h, clothColorTmp.s, clothColorTmp.l); }, onUpdateParams: [this.clothMaterial.color], ease: 'power1.out', overwrite: true
      });

      const initial2 = new THREE.Color(this.clothMaterial.specular.getHex());
      const value2 = new THREE.Color(0x1e1e1e);

      // gsap.to(initial2, 0, {
      //   r: value2.r, g: value2.g, b: value2.b, onUpdate: (specular) => { specular.set(initial2); }, onUpdateParams: [this.clothMaterial.specular], ease: 'power1.out',
      // });

      //return;

      gsap.to(color1, {duration:0.5,
        s: 0, onUpdate: (light) => { light.setHSL(color1.h, color1.s, color1.l); }, onUpdateParams: [this.light1.color], ease: 'power1.out', overwrite: true
      });
      gsap.to(color2, {duration:0.5,
        s: 0, onUpdate: (light) => { light.setHSL(color2.h, color2.s, color2.l); }, onUpdateParams: [this.light2.color], ease: 'power1.out', overwrite: true
      });
      gsap.to(color3, {duration:0.5,
        s: 0, onUpdate: (light) => { light.setHSL(color3.h, color3.s, color3.l); }, onUpdateParams: [this.light3.color], ease: 'power1.out', overwrite: true
      });

      //return;

      gsap.to(this.light1, {duration:0.2, intensity: 0.0, ease: 'power1.out', delay: 0, overwrite: true });
      gsap.to(this.light2, {duration:0.2, intensity: 0.0, ease: 'power1.out', delay: 0, overwrite: true });
      gsap.to(this.light3, {duration:0.2, intensity: 0.0, ease: 'power1.out', delay: 0, overwrite: true });

      //return;

      gsap.to(this.clothMaterial, {duration:1, shininess: 0.3, ease: 'power1.out', delay: 0, overwrite: true });

      // gsap.to('.text-navi-s', {duration:0.7, css: { color: projectsStore.getProjects()[ProjectsStore.getSelectedProject()].fontcolor }, ease: 'power1.out', delay: 0 });
      // gsap.to('.text-navi', {duration:0.7, css: { color: projectsStore.getProjects()[ProjectsStore.getSelectedProject()].fontcolor }, ease: 'power1.out', delay: 0 });

    } else {

      //return;

      const color1T = this.lightTmp1.colorHSL;
      const color2T = this.lightTmp2.colorHSL;
      const color3T = this.lightTmp3.colorHSL;

      const color1 = { h: 0, s: 0, l: 0 };
      const color2 = { h: 0, s: 0, l: 0 };
      const color3 = { h: 0, s: 0, l: 0 };

      this.light1.color.getHSL(color1);
      this.light2.color.getHSL(color2);
      this.light3.color.getHSL(color3);

      // this.light1.color.setHSL(0, 0, 0);
      // this.light2.color.setHSL(0, 0, 0);
      // this.light3.color.setHSL(0, 0, 0);

      //return;

      const initial2 = new THREE.Color(this.clothMaterial.specular.getHex());
      const value2 = new THREE.Color(0x2d2d2d);
//const value2 = new THREE.Color(0xa2fa8f);
      //const value2 = new THREE.Color(0xf09b3c);

      //const value2 = new THREE.Color(0x2d2d2a);
      // let value = new THREE.Color(0x70707);

      // gsap.to(initial2, {duration:1,
      //   r: value2.r, g: value2.g, b: value2.b, onUpdate: (specular) => { specular.set(initial2); }, onUpdateParams: [this.clothMaterial.specular], ease: 'power1.out',
      // });

      //return;

      const clothColorTmp = { h: this.clothColor.h, s: this.clothColor.s, l: this.clothColor.l };

      //return;

      gsap.to(clothColorTmp, {duration:0.3,
        h: .0, s: .0, l: .0, onUpdate: (color) => { color.setHSL(clothColorTmp.h, clothColorTmp.s, clothColorTmp.l);}, onUpdateParams: [this.clothMaterial.color], ease: 'power1.out', overwrite: true
      });

      //return;

      gsap.to(color1, {duration:0.5, 
        h: color1T.h, s: color1T.s, onUpdate: (light) => { this.light1.color.setHSL(color1.h, color1.s, color1.l); }, ease: 'power1.out', overwrite: true
      });
      gsap.to(color2, {duration:0.5,
        h: color2T.h, s: color2T.s, onUpdate: (light) => { this.light2.color.setHSL(color2.h, color2.s, color2.l); }, ease: 'power1.out', overwrite: true
      });
      gsap.to(color3, {duration:0.5,
        h: color3T.h, s: color3T.s, onUpdate: (light) => { this.light3.color.setHSL(color3.h, color3.s, color3.l); }, ease: 'power1.out', overwrite: true
      });

      gsap.to(this.light1, {duration:0.5, intensity: 2, ease: 'power1.out', overwrite: true });
      gsap.to(this.light2, {duration:0.5, intensity: 2, ease: 'power1.out', overwrite: true });
      gsap.to(this.light3, {duration:0.5, intensity: 2, ease: 'power1.out', overwrite: true });

      // gsap.to(this.light1, {duration:0.1, intensity: this.lightTmp1.intensity, ease: 'power1.in' });
      // gsap.to(this.light2, {duration:0.1, intensity: this.lightTmp2.intensity, ease: 'power1.in' });
      // gsap.to(this.light3, {duration:0.1, intensity: this.lightTmp3.intensity, ease: 'power1.in' });

      gsap.to(this.clothMaterial, { duration:0.5, shininess: 0.3, ease: 'power1.out', delay: 0, overwrite: true });

      this.selectedCloth += 1;

      // gsap.to('.text-navi-s', { duration:0.7, css: { color: '#d2d2d2' }, ease: 'power1.out', delay: 0 });
      // gsap.to('.text-navi', { duration:0.7, css: { color: '#d2d2d2' }, ease: 'power1.out', delay: 0 });

      if (this.selectedCloth >= projectsStore.getCloth().crushed.length) this.selectedCloth = 0;

    }

    // gsap.to(this.BadTVPass.uniforms['distortion'], { value: 0, duration: 0.5, ease: 'power1.out' });
    // gsap.to(this.BadTVPass.uniforms['distortion2'], { value: 0, duration: 0.5, ease: 'power1.out' });
    // gsap.to(this.RGBshiftPass.uniforms['amount'], { value: 0, duration: 0.5, ease: 'power1.out' });
    gsap.to(this.shiftPass.uniforms['displacement'], { value: 0, duration: 0.5, ease: 'power1.out' });
    gsap.to(this.shiftPass.uniforms['rgbshift'], { value: 0, duration: 0.5, ease: 'power1.out' });
  }

  updatePrevious(prev, val) {
    prev = val;
  }

  init() {
    this.clothPhysics = new ClothPhysics();

    this.objects = [];
    this.lightsArray = [];

    this.container = document.getElementById('background');

    this.mousePosition = new THREE.Vector2();

    this.scene = new THREE.Scene();
    this.scene.fog = new THREE.Fog(0x000000, 700, 1100);

    this.camera = new THREE.PerspectiveCamera(50, (StageStore.getViewPortWidth()) / StageStore.getViewPortHeight(), 1, 2000);
    this.camera.position.y = 0;
    this.camera.position.z = this.dollyDistance;
    this.camera.position.x = 0;
    this.camera.rotation.order = "YXZ"
    // this.camera.zoom = 3;


    projectsStore.camera = this.camera;
    projectsStore.scene = this.scene;
    this.vue.$store.dispatch('setThreeInitialized', true)

    // this.camera.lookAt(0,0,500);
    this.scene.add(this.camera);
  
    this.canvas = document.getElementById('cloth_canvas');

    this.renderer = new THREE.WebGLRenderer({ antialias: false, devicePixelRatio: 1, canvas: this.canvas });
    // renderer.setPixelRatio( window.devicePixelRatio );
    // renderer.setSize( window.innerWidth, StageStore.getViewPortHeight() );
    this.renderer.setClearColor(this.scene.fog.color);
    // console.log("%c 🌜: PageBackground -> init -> this.scene.fog.color ", "font-size:16px;background-color:#9fd635;color:black;", this.scene.fog.color)
    // renderer.setClearColor(0xffffff);

    this.container.appendChild(this.renderer.domElement);
    this.renderer.gammaInput = true;
    this.renderer.gammaOutput = true;
    this.renderer.shadowMap.enabled = false;

    this.controls = new CameraControls(this.camera, this.renderer.domElement);

    this.controls.dampingFactor = 0.2;
    this.controls.zoomSpeed = 1;
    this.controls.enabled = false;
    // this.controls.rotateSpeed = 0.17;

    this.controls.moveTo(0, 0, 0, false);

    // projectsStore.getCloth().crushed = _.shuffle(projectsStore.getCloth().crushed);

    /* this.controls.enablePan = false;
        this.controls.enableZoom = true;
        this.controls.enableDamping = true;
        this.controls.minPolarAngle = 0.8;
        this.controls.maxPolarAngle = 2.4;
        this.controls.dampingFactor = 0.07;
        this.controls.rotateSpeed = 0.17; */

    // this.camera.lookAt(0, 20000, 0);

    // this.camera.position.copy(controls.center).add(new THREE.Vector3(0, 0, -500));

    document.addEventListener('keydown', this.keydown.bind(this), false);
    // document.addEventListener("mousemove", mouseMove.bind(this), false);
    // document.addEventListener( 'mousedown', onDocumentMouseDown.bind(this), false );
    // document.addEventListener( 'mouseup', onDocumentMouseUp.bind(this), false );

    this.clothColor = { h: 0, s: 0, l: 0 };

    // lights (fourth thing you need is lights)
    let materials;
    this.scene.add(new THREE.AmbientLight(0xFFFFFF, 1));
    this.light1 = new THREE.DirectionalLight(0x5A563C, 0.4);
    this.light1.position.set(796, -200, 399);
    // light1.position.multiplyScalar( 2.3 );
    this.light1.castShadow = false;
    // light.shadowCameraVisible = true;
    this.light1.shadow.mapSize.width = 1024;
    this.light1.shadow.mapSize.height = 1024;

    this.lightTmp1 = { colorHSL: { h: 0, s: 0, l: 0 }, intensity: this.light1.intensity };

    this.light1.color.getHSL(this.lightTmp1.colorHSL);

    const d = 300;
    this.light1.shadow.camera.left = -d;
    this.light1.shadow.camera.right = d;
    this.light1.shadow.camera.top = d;
    this.light1.shadow.camera.bottom = -d;
    this.light1.shadow.camera.far = 1000;

    this.helper1 = new THREE.DirectionalLightHelper(this.light1, 0.7);

    this.scene.add(this.helper1);

    this.scene.add(this.light1);

    this.light2 = new THREE.DirectionalLight(0xE1E1D1, 0.4);
    this.light2.position.set(-597, 29, 143);
    // light2.position.multiplyScalar( 4.3 );
    this.light2.castShadow = false;
    // light.shadowCameraVisible = true;
    this.light2.shadow.mapSize.width = 1024;
    this.light2.shadow.mapSize.height = 1024;

    this.light2.shadow.camera.left = -d;
    this.light2.shadow.camera.right = d;
    this.light2.shadow.camera.top = d;
    this.light2.shadow.camera.bottom = -d;
    this.light2.shadow.camera.far = 1000;

    this.lightTmp2 = { colorHSL: { h: 0, s: 0, l: 0 }, intensity: this.light2.intensity };

    this.light2.color.getHSL(this.lightTmp2.colorHSL);

    this.helper2 = new THREE.DirectionalLightHelper(this.light2, 5);

    this.scene.add(this.helper2);

    this.scene.add(this.light2);


    this.light3 = new THREE.DirectionalLight(0x4B3935, 0.6);
    this.light3.position.set(72, 27, 1042);
    // light3.position.multiplyScalar( 4.3 );
    this.light3.castShadow = false;
    // light.shadowCameraVisible = true;
    this.light3.shadow.mapSize.width = 1024;
    this.light3.shadow.mapSize.height = 1024;

    this.light3.shadow.camera.left = -d;
    this.light3.shadow.camera.right = d;
    this.light3.shadow.camera.top = d;
    this.light3.shadow.camera.bottom = -d;
    this.light3.shadow.camera.far = 1000;

    this.lightTmp3 = { colorHSL: { h: 0, s: 0, l: 0 }, intensity: this.light3.intensity };

    this.light3.color.getHSL(this.lightTmp3.colorHSL);

    this.helper3 = new THREE.DirectionalLightHelper(this.light3, 4);

    this.scene.add(this.helper3);

    this.scene.add(this.light3);

    this.videos = [];
    this.textures = [];

    const projects = projectsStore.getProjects();

    projects.forEach((value, h) => {
      const image = projectsStore.imageRefs[h]
      const texture = new THREE.TextureLoader().load(image.src );
      texture.minFilter = THREE.LinearFilter;
      texture.magFilter = THREE.LinearFilter;
      texture.format = THREE.RGBFormat;
      texture.anisotropy = 1;

      this.textures.push(texture);
    });

    this.clothMaterial = new THREE.MeshToonMaterial({
      color: 0xffffff,
      specular: 0x1e1e1e,
      flatShading: false,
      wireframe: false,
      map: this.textures[0],
      shininess: 0,
      specularMap: this.textures[0],
      side: THREE.DoubleSide,
      alphaTest: 0,
    });

    this.clothShiness = this.clothMaterial.shininess;

    this.clothMaterial.color.getHSL(this.clothColor);

    const that = this;

    // this.clothMaterial.onBeforeCompile = function (shader) {
    //   shader.uniforms.time = { value: 0 };
    //   shader.fragmentShader = `uniform vec2 resolution;\nuniform float time;\n float nrand( vec2 n ){return fract(sin(dot(n.xy, vec2(12.9898, 78.233)))* 43758.5453);}\n\n float n1rand( vec2 n ){float t = fract( time ); float nrnd0 = nrand( n + 0.07*t ); return nrnd0;}\n\n float n2rand( vec2 n ){float t = fract( time ); float nrnd0 = nrand( n + 0.07*t ); float nrnd1 = nrand( n + 0.11*t ); return (nrnd0+nrnd1) / 2.0;}\n\n float n4rand( vec2 n ){float t = fract( time ); float nrnd0 = nrand( n + 0.07*t ); float nrnd1 = nrand( n + 0.11*t );	float nrnd2 = nrand( n + 0.13*t ); float nrnd3 = nrand( n + 0.17*t ); return (nrnd0+nrnd1+nrnd2+nrnd3) / 4.0;}\n\n${shader.fragmentShader}`;
    //   that.materialShader = shader;
    // };

    const geometryLightDragger = new THREE.BoxGeometry(10, 10, 10);
    const geometryLightDragger2 = new THREE.BoxGeometry(5, 5, 5);

    this.lightDragger1 = new THREE.Mesh(geometryLightDragger, new THREE.MeshLambertMaterial({ color: this.light1.color }));
    this.lightDragger2 = new THREE.Mesh(geometryLightDragger, new THREE.MeshLambertMaterial({ color: this.light2.color }));
    this.lightDragger3 = new THREE.Mesh(geometryLightDragger, new THREE.MeshLambertMaterial({ color: this.light3.color }));

    this.lightDragger1S = new THREE.Mesh(geometryLightDragger2, new THREE.MeshLambertMaterial({ color: this.light1.color }));
    this.lightDragger2S = new THREE.Mesh(geometryLightDragger2, new THREE.MeshLambertMaterial({ color: this.light2.color }));
    this.lightDragger3S = new THREE.Mesh(geometryLightDragger2, new THREE.MeshLambertMaterial({ color: this.light3.color }));

    this.lightDragger1S.position.set(232, -160, 290);
    this.lightDragger2S.position.set(294, -197, 413);
    this.lightDragger3S.position.set(0, 0, 500);

    this.scene.add(this.lightDragger1);
    this.scene.add(this.lightDragger2);
    this.scene.add(this.lightDragger3);
    this.scene.add(this.lightDragger1S);
    this.scene.add(this.lightDragger2S);
    this.scene.add(this.lightDragger3S);

    this.light1.target = this.lightDragger1S;
    this.light2.target = this.lightDragger2S;
    this.light3.target = this.lightDragger3S;

    this.lightDragger1.position.set(this.light1.position.x, this.light1.position.y, this.light1.position.z);
    this.lightDragger2.position.set(this.light2.position.x, this.light2.position.y, this.light2.position.z);
    this.lightDragger3.position.set(this.light3.position.x, this.light3.position.y, this.light3.position.z);
    this.lightDragger1S.position.set(this.light1.target.position.x, this.light1.target.position.y, this.light1.target.position.z);
    this.lightDragger2S.position.set(this.light2.target.position.x, this.light2.target.position.y, this.light2.target.position.z);
    this.lightDragger3S.position.set(this.light3.target.position.x, this.light3.target.position.y, this.light3.target.position.z);

    this.lightsArray.push(this.lightDragger1);
    this.lightsArray.push(this.lightDragger2);
    this.lightsArray.push(this.lightDragger3);
    this.lightsArray.push(this.lightDragger1S);
    this.lightsArray.push(this.lightDragger2S);
    this.lightsArray.push(this.lightDragger3S);

    this.clothInitialPosition = this.plane(this.clothPhysics.fabricLength * this.clothPhysics.proportionX, this.clothPhysics.fabricLength * this.clothPhysics.proportionY);
    
    this.clothGeometry = new THREE.ParametricGeometry(this.clothInitialPosition, this.clothPhysics.cloth.w, this.clothPhysics.cloth.h);
    this.clothGeometry.dynamic = false;

    this.object = new THREE.Mesh(this.clothGeometry, this.clothMaterial);
    this.object.position.z = -500;
    // this.object.center( this.object.position );

    this.object.castShadow = false;

    this.objects.push(this.object);

    this.pivot = new THREE.Group();
    this.pivot.position.z = 0;

    this.scene.add(this.pivot);
    this.pivot.add(this.object);

    const dragControls = new DragControls(this.lightsArray, this.camera, this.renderer.domElement);
    dragControls.addEventListener('dragstart', (event) => { that.controls.enabled = false; });
    dragControls.addEventListener('dragend', (event) => { that.controls.enabled = true; });

    StageStore.on(PageConstants.STAGE_RESIZE, this.onWindowResize.bind(this));

    // updateHelpers();

    // this.createThing('Ball');

    // pinCloth sets how the cloth is pinned
    this.clothPhysics.pinCloth('None');

    this.renderPass = new RenderPass( this.scene, this.camera );
    this.shiftPass = new ShaderPass( ShiftShader );

    this.composer = new EffectComposer( this.renderer );
    this.composer.setSize(window.innerWidth, window.innerHeight);
    this.composer.addPass( this.renderPass );
    this.composer.addPass( this.shiftPass );

    // chessboard canvas

    this.chessContainer = document.getElementById('chessboard');

    this.chessRenderer = new THREE.WebGLRenderer();
    this.chessRenderer.setPixelRatio( 1 );
    this.chessRenderer.setSize( this.chessContainer.clientWidth * 1.2, this.chessContainer.clientHeight * 1.2);
    this.chessContainer.appendChild( this.chessRenderer.domElement );

    this.chessComposer = new EffectComposer( this.chessRenderer );

    this.chessPass = new ShaderPass( ChessShader );
    this.distortPass = new ShaderPass( DistortShader );
    this.chessPass.uniforms['resolution'].value = { x: this.chessContainer.clientWidth * 1.2, y: this.chessContainer.clientHeight * 1.2}
    this.distortPass.uniforms['resolution'].value = { x: this.chessContainer.clientWidth * 1.2, y: this.chessContainer.clientHeight * 1.2}

    this.chessComposer.addPass( this.chessPass );
    this.chessComposer.addPass( this.distortPass );

    this.enableGUI();

    // this.stats.addToStage(document.body);

    this.animate();

    // StageStore.on(PageConstants.NAVI_OPENED, this.naviOpened.bind(this));
    // StageStore.on(PageConstants.NAVI_CLOSED, this.naviClosed.bind(this));

    ProjectsStore.on(ProjectConstants.CAMERA_ROTATE_START, this.rotateCameraStart.bind(this));
    ProjectsStore.on(ProjectConstants.CAMERA_ROTATE, this.rotateCamera.bind(this));

    ProjectsStore.on(ProjectConstants.SELECT_PROJECT_NOCLOTH, this.selectProjectNoCloth.bind(this));
    ProjectsStore.on(ProjectConstants.SELECT_PROJECT, this.selectProject.bind(this));
    
    emitter.on("on-video-list-ready", (videoArray) => {console.log("%c 🕞: PageBackground -> init -> videoArray ", "font-size:16px;background-color:#7deb11;color:black;", videoArray)})
    
    //emitter.on("on-portfolio-expand-project", this.onProjectWillExpand.bind(this))
    emitter.on("on-portfolio-scroll", this.onPortfolioScrollChange.bind(this))
    emitter.on("on-project-boundingrect-update", ({rect}) => this.expandedPortfolioItemRect = rect)
    emitter.on("on-portfolio-expand-project", this.onProjectWillExpand.bind(this))
    emitter.on("on-portfolio-shrink-project", this.onProjectWillShrink.bind(this))
    emitter.on("on-portfolio-project-click", this.onProjectListMouseOver.bind(this))
    emitter.on("on-portfolio-project-mouseover", this.onProjectListMouseOver.bind(this))
    emitter.on("on-portfolio-project-scroll_change", this.onProjectListScrollChange.bind(this))
    emitter.on("on-portfolio-project-mouseout", this.onProjectListMouseOut.bind(this))
    emitter.on("on-all-videos-loaded", this.onAllVideosLoaded.bind(this))

    window.addEventListener('mousemove', (event) => {
      this.mousePosition = { x: event.clientX + window.innerWidth / 2, y: window.innerHeight - event.clientY + 100 }
    })

  }

  onAllVideosLoaded() {
    const projects = projectsStore.getProjects();
    projects.forEach((value, h) => {
      const video = projectsStore.videoRefs[h]

      const texture = new THREE.VideoTexture(video);
      texture.minFilter = THREE.LinearFilter;
      texture.magFilter = THREE.LinearFilter;
      texture.format = THREE.RGBFormat;
      texture.anisotropy = 1;

      this.videos.push(video);
      const oldTexture = this.textures[h];
      this.textures[h] = texture;
      oldTexture.dispose();
    });
  }

  onPortfolioScrollChange({ deltaY, direction, caruselaPositionY }){
    //return;
    
    // console.log("%c 💐: PageBackground -> onPortfolioScrollChange -> deltaY, direction, caruselaPositionY ", "font-size:16px;background-color:#8f92d0;color:white;", deltaY, direction, caruselaPositionY)
    if (this.projectInTransition) return;

    //if (direction <= 0){

      if (caruselaPositionY > StageStore.browser_height/4){
        if (this.openedBox){
          this.rotateCameraStart(false);
        }
        //this.rotateCameraDirect(deltaY / 10);
        //console.log("!! ROTATION !!")

        ProjectsStore.setCameraRotation(-deltaY / 300);

      }else{
        //

        //console.log("caruselaPositionY ", caruselaPositionY)

        if (caruselaPositionY < StageStore.browser_height/4 && caruselaPositionY >= 0 && deltaY !== 0){
          if (!this.openedBox) this.selectProject();
        }
      }
    //}
  }
  onProjectListScrollChange({rect}){
    const itemSizeInPX  = rect;
    const { position, size } = this.getPlaneFitedToPortfolioItemSize(rect);
    this.setViewport(itemSizeInPX.x - position.x, itemSizeInPX.y - position.y, size.width, size.height);
  }
  onProjectListMouseOver({id, rect}){
    //return;

    const itemSizeInPX  = rect;
    const { position, size } = this.getPlaneFitedToPortfolioItemSize(rect);

    const projectID = id;

    this.clothMaterial.map = this.textures[projectID];
    this.clothMaterial.specularMap = this.textures[projectID];
    this.clothMaterial.needsUpdate = true;


    this.videos.forEach((value, index) => {
      if(index != id)
        value?.pause();
    });
    //return;

    // this.videos[projectID].currentTime = 0;

    this.videos[projectID]?.play();

    this.setViewport(itemSizeInPX.x - position.x, itemSizeInPX.y - position.y, size.width, size.height);

  }
  onProjectListMouseOut({id, rect}){
    this.videos[id]?.pause();
    // na mouse out trzeba to ładnie wygaszać
    // moze draw na dodatkowy plane, ktory by zostawał w miejscu komponentu video, zeby HTMLowy element mógł to ładnie przykryć?

    //this.renderer.setViewport(0, 0, window.innerWidth, StageStore.getViewPortHeight());
  }
  onProjectWillExpand({id, rect, triggeredBy}){
    
    console.log("PROJECT WILL EXPAND");

    this.projectInTransition = true;

    //return;
    this.expandedPortfolioItemRect = rect;
    const itemSizeInPX  = rect;
    const { scale, position, size, planeSizeInPX } = this.getPlaneFitedToPortfolioItemSize(rect);
    
    var obj = {x: itemSizeInPX.x - position.x, y: itemSizeInPX.y - position.y, width: size.width, height: size.height}

    this.setViewport(obj.x, obj.y, obj.width, obj.height);
    
    //return;

    // CLOTH ACTION
    this.openedBox = false;

    this.selectedCloth = 3;

    //this.clothMaterial.color = new THREE.Color(0x000000)
    // this.clothMaterial.color.getHSL({h:0,s:0,l:0});
    //console.log("COLOR: ", this.clothMaterial.color);// = 0x000000;

    this.rotateCameraDirect(Math.PI * 2)

    // this.lightTmp1.intensity = 0;
    // this.lightTmp2.intensity = 0;
    // this.lightTmp3.intensity = 0;

    gsap.to(this.light1, {duration:0, intensity: 0, ease: 'power1.out', overwrite: true });
    gsap.to(this.light2, {duration:0, intensity: 0, ease: 'power1.out', overwrite: true });
    gsap.to(this.light3, {duration:0, intensity: 0, ease: 'power1.out', overwrite: true });

    this.clothChange();

    // ProjectsStore.selectProjectDirect(id, false);
    
    //return

    var rotationCloth = {r:this.controls._spherical.theta};
    //var direction = rotationCloth.r < 0 ? -1 : 1;
    var rolls = -1.5;
    
    if (triggeredBy == 'click'){
      gsap.to(this.camera, {duration: 0.5,
        zoom: 0.4, ease: 'power1.out', onUpdate(ref) { ref.updateProjectionMatrix() ;}, onUpdateParams: [this.camera], overwrite:true
      });
    }
    
    var whereRotate = this.whereRotateIt();

    whereRotate += Math.PI * rolls * -1;

    gsap.to (rotationCloth, {
      r: whereRotate,
      ease: 'power1.in',
      duration: .35,
      onUpdate: () => {
        this.rotateCameraDirect(rotationCloth.r)
      },
      onComplete: (ref) =>{
        // ProjectsStore.setCameraRotation(0);
        ref.selectProject();
      },
      onCompleteParams:[this]
    })

    // END CLOTH ACTION //

    gsap.to (obj, {
      width: StageStore.getViewPortWidth(),
      height: StageStore.getViewPortHeight(),
      x: 0,
      y: StageStore.getCameraViewOffsetY(),
      duration: .55,
      ease: 'power1.inOut',
      onUpdate: () => {
        this.setViewport(obj.x, obj.y, obj.width, obj.height);
      },
      onComplete: () => this.onProjectExpandComplete()
    })
  }
  onProjectExpandComplete(){
    this.projectInTransition = false;
  }
  onProjectWillShrink(){
    this.projectInTransition = true;

    const itemSizeInPX  = this.expandedPortfolioItemRect;
    const { scale, position, size, planeSizeInPX } = this.getPlaneFitedToPortfolioItemSize(itemSizeInPX);
    
    // CLOTH ACTION
    this.openedBox = false;

    this.selectedCloth = 3;

    this.clothChange();

    //return;

    // this.clothMaterial.needsUpdate = true;
    // this.clothMaterial.flatShading = false;

    var whereRotate = this.whereRotateIt();
    var rolls = 1.5;

    whereRotate += Math.PI * rolls * 1;

    gsap.to(this.camera, {duration: 0.5,
      zoom: 0.5, ease: 'power1.out', onUpdate(ref) { ref.updateProjectionMatrix() ;}, onUpdateParams: [this.camera], overwrite:true
    });

    var rotationCloth = {r: this.controls._spherical.theta };

    gsap.to (rotationCloth, {
      r: whereRotate,
      duration: 0.3,
      ease: 'power1.in',
      onUpdate: () => {
        this.rotateCameraDirect(rotationCloth.r)
      },
      onComplete: (ref) =>{

        //ProjectsStore.setCameraRotation(0);
        ref.selectProject();
        
      },
      onCompleteParams:[this]
    })

    // END CLOTH ACTION //
    
    var obj = {x: 0, y: StageStore.getCameraViewOffsetY(), width: StageStore.getViewPortWidth(), height: StageStore.getViewPortHeight() }

    gsap.to (obj, {
      x: itemSizeInPX.x - position.x,
      y: itemSizeInPX.y - position.y,
      width: size.width, 
      height: size.height,
      duration: 0.35,
      ease: 'power1.inOut',
      
      onUpdate: () => {
        this.setViewport(obj.x, obj.y, obj.width, obj.height);
      },
      onComplete: () => {
        // ProjectsStore.setCameraRotation(0);
        // this.selectProject();

        this.onProjectShrinkComplete();
      }
    })
  }
  onProjectShrinkComplete(){
    this.projectInTransition = false;
    // this.vue.$store.dispatch('onShrinkPortfolioComplete')
  }
  getPlaneFitedToPortfolioItemSize (rect){

    const planeSizeInPX = this.getPlaneProjectionSize();
    const itemSizeInPX  = rect;

    const scale = {
      x: itemSizeInPX.width / planeSizeInPX.width,
      y: itemSizeInPX.height / planeSizeInPX.height
    }
    
    const position = {
      x: planeSizeInPX.x * scale.x,
      y: planeSizeInPX.y * scale.y
    }

    const size = {
      width: (planeSizeInPX.width + planeSizeInPX.x * 2) * scale.x,
      height: (planeSizeInPX.height + planeSizeInPX.y * 2) * scale.y
    }

    return {scale, position, size, planeSizeInPX}
  }
  plane ( width, height ) {

    return function ( u, v, target ) {

      const x = ( u - 0.5 ) * width;
      const y = ( v + 0.5 ) * height;
      const z = 0;

      if(!target)
        target = new THREE.Vector3(x, y, z);

      return target.set( x, y, z );

    };
    
  }

  changeFog(){

    if (this.openedBox){
      gsap.to(this.scene.fog, 0.3, {near: 2000, far:2000, ease: 'power4.in'});
    }else{
      gsap.to(this.scene.fog, 0.3, {near: 300, far:2100, ease: 'power4.out'});
    }
  }

  selectProjectNoCloth() {
    

    console.log("// SELECT PROJECT TO JEST W OGOLE ZBĘDNE ?? //")
    //return;

    //this.openedBox = true;

    const projectID = ProjectsStore.getSelectedProject();
    this.clothMaterial.map = this.textures[projectID];
    this.clothMaterial.specularMap = this.textures[projectID];


    this.videos.forEach((value, index) => {
      // console.log(value);

      value?.pause();
    });

    this.videos[projectID]?.play();

    //this.clothChange();

    const rotationPos = this.controls._spherical.theta % (Math.PI * 2);

    let whereRotate = this.controls._spherical.theta - rotationPos;

    // if (whereRotate % (2*Math.PI) != 0) {
    //   this.object.geometry.dynamic = true
    //   this.object.geometry.__dirtyVertices = true;
    //   this.object.geometry.__dirtyNormals = true;

    //   this.object.flipSided = true;

    //   //flip every vertex normal in mesh by multiplying normal by -1
    //   for(var i = 0; i<this.object.geometry.faces.length; i++) {
    //     this.object.geometry.faces[i].normal.x = -1*this.object.geometry.faces[i].normal.x;
    //     this.object.geometry.faces[i].normal.y = -1*this.object.geometry.faces[i].normal.y;
    //     this.object.geometry.faces[i].normal.z = -1*this.object.geometry.faces[i].normal.z;
    //   }

    //   this.object.geometry.computeVertexNormals();
    //   this.object.geometry.computeFaceNormals();
    // }else{
    //   this.object.geometry.dynamic = true
    //   this.object.geometry.__dirtyVertices = true;
    //   this.object.geometry.__dirtyNormals = true;

    //   this.object.flipSided = false;

    //   //flip every vertex normal in mesh by multiplying normal by -1
    //   for(var i = 0; i<this.object.geometry.faces.length; i++) {
    //     this.object.geometry.faces[i].normal.x = -1*this.object.geometry.faces[i].normal.x;
    //     this.object.geometry.faces[i].normal.y = -1*this.object.geometry.faces[i].normal.y;
    //     this.object.geometry.faces[i].normal.z = -1*this.object.geometry.faces[i].normal.z;
    //   }

    //   this.object.geometry.computeVertexNormals();
    //   this.object.geometry.computeFaceNormals();
    // }

    this.clothMaterial.needsUpdate = true;

    //console.log("whereRotate: ", whereRotate);
    // this.controls.rotateTo(whereRotate, 90*Math.PI/180, true);

    // var rotationCloth = {r:this.controls._spherical.theta};

    // gsap.to (rotationCloth, {
    //   r: whereRotate,
    //   duration: 0.4,
    //   ease: 'power1.out',
    //   onUpdate: () => {
    //     this.rotateCameraDirect(rotationCloth.r)
    //   },
    //   onComplete: (ref) =>{

    //     //ProjectsStore.setCameraRotation(0);
    //     //ref.selectProject();
        
    //   },
    //   onCompleteParams:[this]
    // })

    // //this.controls.rotateTo(whereRotate, 90 * Math.PI / 180, true);

    // gsap.to(this.camera, {duration:0.7,
    //   zoom: 1, ease: 'power1.out', onUpdate(ref) { ref.updateProjectionMatrix(); }, onUpdateParams: [this.camera], overwrite: true,
    // });


    // this.controls.dollyTo(this.dollyDistance, true);
  }

  whereRotateIt(){

    let rotationPos = this.controls._spherical.theta % (Math.PI * 2);

    let whereRotate = this.controls._spherical.theta - rotationPos;
    
    if (Math.abs(rotationPos) > Math.PI){

      if (rotationPos > 0){
        rotationPos = Math.PI * 2 - rotationPos;
        whereRotate = this.controls._spherical.theta + rotationPos;
      }else{
        rotationPos = -Math.PI * 2 - rotationPos;
        whereRotate = this.controls._spherical.theta + rotationPos;
      }
    }

    return whereRotate;
  }

  selectProject() {
    this.projectInTransition = true;

    this.openedBox = true;

    const projectID = ProjectsStore.getSelectedProject();
    this.clothMaterial.map = this.textures[projectID];
    this.clothMaterial.specularMap = this.textures[projectID];


    this.videos.forEach((value, index) => {
      // console.log(value);

      value?.pause();
    });

    this.videos[projectID]?.play();

    this.clothMaterial.needsUpdate = true;

    this.clothChange();

    var whereRotate = this.whereRotateIt();

    var rotationCloth = {r:this.controls._spherical.theta};

    gsap.to (rotationCloth, {
      r: whereRotate,
      duration: 0.3,
      ease: 'power1.out',
      onUpdate: () => {
        this.rotateCameraDirect(rotationCloth.r)
      },
      onComplete: (ref) =>{

        //ProjectsStore.setCameraRotation(0);
        //ref.selectProject();
        this.projectInTransition = false;
      },
      onCompleteParams:[this]
    })

    
    //this.controls.rotateTo(whereRotate, 90 * Math.PI / 180, true);

    gsap.to(this.camera, {duration:0.8,
      zoom: 1, ease: 'power3.out', onUpdate(ref) { ref.updateProjectionMatrix(); }, onUpdateParams: [this.camera], overwrite: true,
    });


    // this.controls.dollyTo(this.dollyDistance, true);
  }

  rotateCameraStart(blockAnimation) {

    this.projectInTransition = blockAnimation !== null ? blockAnimation : true;

    this.openedBox = false;

    this.light1.intensity = 0;
    this.light2.intensity = 0;
    this.light3.intensity = 0;

    this.clothChange();

    gsap.to(this.camera, {duration: 0.3,
      zoom: 0.55, ease: 'power1.out', onUpdate(ref) { ref.updateProjectionMatrix() ;}, onUpdateParams: [this.camera], overwrite:true
    });


    // this.controls.dollyTo(this.dollyDistance + 300, true);
  }

  rotateCamera() {

    if (!this.openedBox) {
    
      var rotation = Math.abs(ProjectsStore.getCameraRotationn()*20 );

      var rotation2 = Math.abs(ProjectsStore.getCameraRotationn()*10 );

      this.rotationTmp += (rotation - this.rotationTmp)/5;

      this.rotationTmp2 += (rotation2 - this.rotationTmp2)/2;
      
      gsap.set(this.light1, {intensity: Math.min(5, this.lightTmp1.intensity + this.rotationTmp * 0.5) });
      gsap.set(this.light2, {intensity: Math.min(5, this.lightTmp2.intensity + this.rotationTmp * 0.5) });
      gsap.set(this.light3, {intensity: Math.min(5, this.lightTmp3.intensity + this.rotationTmp * 0.5) });

      gsap.set(this.clothMaterial, {shininess: Math.min(5, this.clothShiness + this.rotationTmp2) });
      
      const initial2 = new THREE.Color(this.clothMaterial.specular.getHex());
      const value2 = new THREE.Color(0x1e1e1e);

      // this.BadTVPass.uniforms['time'].value += 0.01;
      // this.BadTVPass.uniforms['distortion'].value = this.rotationTmp * 0.05;
      // this.RGBshiftPass.uniforms['amount'].value = this.rotationTmp * 0.0005;

      this.shiftPass.uniforms['displacement'].value = Math.min(Math.max(this.rotationTmp * 0.001, 0.005), 0.01);
      this.shiftPass.uniforms['rgbshift'].value = Math.min(this.rotationTmp * 0.005, 0.035);

      // gsap.to(initial2, 0.5, {
      //   r: value2.r, g: value2.g, b: value2.b, onUpdate: (specular) => { specular.set(initial2); }, onUpdateParams: [this.clothMaterial.specular], ease: 'power1.out',
      // });

      this.controls.rotate(ProjectsStore.getCameraRotationn() * 0.5, 0, false);
    
    }
  }

  rotateCameraDirect(rotation) {

    // gsap.set(this.light1, {intensity: this.lightTmp1.intensity + rotation });
    // gsap.set(this.light2, {intensity: this.lightTmp2.intensity + rotation });
    // gsap.set(this.light3, {intensity: this.lightTmp3.intensity + rotation });


    this.controls.rotateTo(rotation, 90 * Math.PI / 180, false);
  }

  onWindowResize() {
    this.camera.aspect = (StageStore.getViewPortWidth()) / StageStore.getViewPortHeight();
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(StageStore.getViewPortWidth(), window.innerHeight);
    this.composer.setSize(StageStore.getViewPortWidth(), window.innerHeight);
    const chessSize = { x: this.chessContainer.clientWidth * 1.2, y: this.chessContainer.clientHeight * 1.2};
    this.chessRenderer.setSize(chessSize.x, chessSize.y);
    this.chessComposer.setSize(chessSize.x, chessSize.y);
    this.chessPass.uniforms['resolution'].value = { x: chessSize.x, y: chessSize.y }
    this.distortPass.uniforms['resolution'].value = { x: chessSize.x, y: chessSize.y }

    var bootstrapColumnWidth = document.getElementById('bootstrap-content-column-ref').getBoundingClientRect().width;
    this.fitCameraToSpecificPlaneWidth(bootstrapColumnWidth)
    
    this.getPlaneProjectionSize()
    this.setViewport (0, 
        StageStore.getCameraViewOffsetY(), 
        StageStore.getViewPortWidth(), 
        StageStore.getViewPortHeight());
  }

  updateHelper() {
    //

    if (!this.guiEnabled) return;

    this.light1.position.copy(this.lightDragger1.position);
    this.light2.position.copy(this.lightDragger2.position);
    this.light3.position.copy(this.lightDragger3.position);

    this.light1.target.position.copy(this.lightDragger1S.position);
    this.light2.target.position.copy(this.lightDragger2S.position);
    this.light3.target.position.copy(this.lightDragger3S.position);

    this.guiControls.lightPosition1x = this.light1.position.x;
    this.guiControls.lightPosition1y = this.light1.position.y;
    this.guiControls.lightPosition1z = this.light1.position.z;

    this.guiControls.lightPosition2x = this.light2.position.x;
    this.guiControls.lightPosition2y = this.light2.position.y;
    this.guiControls.lightPosition2z = this.light2.position.z;

    this.guiControls.lightPosition3x = this.light3.position.x;
    this.guiControls.lightPosition3y = this.light3.position.y;
    this.guiControls.lightPosition3z = this.light3.position.z;

    this.guiControls.lightPositionT1x = this.lightDragger1S.position.x;
    this.guiControls.lightPositionT1y = this.lightDragger1S.position.y;
    this.guiControls.lightPositionT1z = this.lightDragger1S.position.z;

    this.guiControls.lightPositionT2x = this.lightDragger2S.position.x;
    this.guiControls.lightPositionT2y = this.lightDragger2S.position.y;
    this.guiControls.lightPositionT2z = this.lightDragger2S.position.z;

    this.guiControls.lightPositionT3x = this.lightDragger3S.position.x;
    this.guiControls.lightPositionT3y = this.lightDragger3S.position.y;
    this.guiControls.lightPositionT3z = this.lightDragger3S.position.z;

    this.helper1.update();
    this.helper2.update();
    this.helper3.update();
  }

  animate() {
    
    requestAnimationFrame(this.animate.bind(this));
    if (!this.vue.$store.getters.isPortfolioVisible) {
      
      // update and render chess canvas

      let newX = this.distortPass.uniforms['mousePosition'].value.x;
      let newY = this.distortPass.uniforms['mousePosition'].value.y;
      newX = (newX * 0.9 + this.mousePosition.x * 0.1);
      newY = (newY * 0.9 + this.mousePosition.y * 0.1);

      this.distortPass.uniforms['mousePosition'].value = { x: newX, y: newY };
      this.chessComposer.render();

      return;
    }

    // if (!controls.enabled){
    this.updateHelper();
    // }

    /* if (mClicked){

            mouse.set( ( mouse.x / window.innerWidth ) * 2 - 1, - ( mouse.y / StageStore.getViewPortHeight() ) * 2 + 1 );
            raycaster.setFromCamera( mouse, camera );
            var intersects = raycaster.intersectObjects( objects );

            if ( intersects.length > 0 ) {
                var intersect = intersects[ 0 ];
                // delete cube

                tmpPos.copy( intersect.point ).add( intersect.face.normal );

                tmpPos.z = positionBallZ;

                prevBallPosition.copy(ballPosition);

                ballPosition = tmpPos;

                //console.log(positionBallZ);

                positionBallZ -= 5;

                if (positionBallZ < -100){
                    positionBallZ = -100;
                }
            }

        }else{
            positionBallZ = ballSize + 150;
        } */

    const time = Date.now();

    // this.controls.target.set(0, 0, 500);

    // console.log(this.controls._spherical.theta*180/Math.PI);

    this.render();
    this.stats.update();
    // controls.update();
  }

  restartCloth() {
    this.pivot.remove(this.object);

    // clothInitialPosition = plane( 500, 500 );

    this.clothPhysics.cloth = new Cloth(this.clothPhysics.xSegs, this.clothPhysics.ySegs, this.clothPhysics.fabricLength * this.clothPhysics.proportionX, this.clothPhysics.fabricLength * this.clothPhysics.proportionY, this.clothInitialPosition);

    console.log("restart: ", this.clothPhysics.cloth.particles);

    // for (let i = 0; i < this.clothPhysics.cloth.particles.length; i++) {
    //   this.clothPhysics.cloth.particles[i].position.x = projectsStore.getCloth().main[i].x;
    //   this.clothPhysics.cloth.particles[i].position.y = projectsStore.getCloth().main[i].y;
    //   this.clothPhysics.cloth.particles[i].position.z = projectsStore.getCloth().main[i].z;
    // }
    
    this.clothPhysics.GRAVITY = 0;// 9.81 * 140; //
    this.clothPhysics.gravity = new THREE.Vector3(0, -this.clothPhysics.GRAVITY, 0).multiplyScalar(this.clothPhysics.MASS);

    // recreate cloth geometry

    this.clothGeometry = new THREE.ParametricGeometry(this.clothInitialPosition, this.clothPhysics.cloth.w, this.clothPhysics.cloth.h);
    this.clothGeometry.dynamic = true;

    // recreate cloth mesh
    this.object = new THREE.Mesh(this.clothGeometry, this.clothMaterial);
    //this.object.position.z = -500;

    console.log("this.clothPhysics.cloth.h", this.clothPhysics.cloth.h * this.clothPhysics.cloth.restDistanceY);

    this.object.position.set(0, -this.clothPhysics.cloth.h * this.clothPhysics.cloth.restDistanceY, 0);

    // this.object.center( this.object.position );
    // object.castShadow = false;

    this.pivot.add(this.object); // adds the cloth to the scene
  }

  render() {
    // return
    const p = this.clothPhysics.cloth.particles;
    const len = p.length;
    let i = 0;
    let il = len;

    for (i, il = len; i < il; i++) {
      this.clothGeometry.vertices[i].copy(p[i].position);
    }

    // recalculate cloth normals
    this.clothGeometry.computeFaceNormals();
    this.clothGeometry.computeVertexNormals();

    this.clothGeometry.normalsNeedUpdate = true;
    this.clothGeometry.verticesNeedUpdate = true;

    // console.log("render: ", this);

    // update sphere position from ball position
    // sphere.position.copy( ballPosition );


    // /* this.sphere.position.x = StageStore.getMouse().x - StageStore.browser_width / 2 ;
    // this.sphere.position.y = - (StageStore.getMouse().y - StageStore.browser_height / 2); */

    // // this.clothPhysics.ballPosition.copy(this.sphere.position);

    const delta = this.clock.getDelta();

    const time = Date.now();

    // this.clothPhysics.simulate(time, this.sphere);

    // console.log("OKEJ");

    this.shiftPass.uniforms['time'].value += delta;

    this.controls.update(delta);

    if(projectsStore.renderToCanvas == false)
      this.composer.render();
    // this.renderer.render(this.scene, this.camera); // render the scene
    
  }



  getPlaneProjectionSize (){
    var dist = 700//this.camera.position.z + 0 
    var vFOV = this.camera.fov * Math.PI / 180;        // convert vertical fov to radians
    
    var height = 2 * Math.tan( vFOV / 2 ) * dist;
    var width = height * this.camera.aspect;
    
    var fractionWidth = 640 / width;
    
    var fractionHeight = 360 / height;

    var realWidth = (StageStore.getViewPortWidth()) * fractionWidth;
    var realHeight = StageStore.getViewPortHeight() * fractionHeight;

    var x = (StageStore.getViewPortWidth()) / 2 - realWidth / 2;
    var y = StageStore.getViewPortHeight() / 2 - realHeight / 2;

    this.vue.$store.dispatch('setThreePlaneSize', {width: realWidth, height: realHeight})

    // var fixed = document.createElement('div')
    //     fixed.classList.add('fixed-three')
    //     document.body.appendChild(fixed)
    // var fixedRect = document.createElement('div')
    //     fixedRect.classList.add('fixed-rect')
    //     fixedRect.style.width = realWidth + 'px';
    //     fixedRect.style.height = realHeight + 'px';
    // fixed.appendChild(fixedRect)


    return {realHeight, realWidth, width: realWidth, height: realHeight, x, y}
  }

  fitCameraToSpecificPlaneWidth (desiredWidth){
    if(window.innerWidth < StageStore.mobileThreshold){
      desiredWidth = StageStore.getViewPortHeight() / 0.5625
      
      if(desiredWidth < StageStore.getViewPortWidth())
        desiredWidth = StageStore.getViewPortWidth()
    }
    
    var dist = 700//this.camera.position.z + 0 
    var fractionW = desiredWidth / (StageStore.getViewPortWidth());
    // var fractionH = desiredHeight / StageStore.getViewPortHeight();

    var newWidth = 640 / fractionW
    // var newHeight = 360 / fractionH
    
    var tan = (newWidth / this.camera.aspect) / dist / 2;
    var newFOV = (Math.atan(tan) * 2) / (Math.PI / 180)

    this.camera.fov = newFOV;
    this.camera.updateProjectionMatrix();
  }

  // original three pre v102 setViewport() implementation (based on top left corner instead of lower left)
  // https://github.com/mrdoob/three.js/issues/12210
  setViewport(x, y, width, height) {
    this.renderer.setViewport(x, this.canvas.height - y - height, width, height);
  }
}

export default PageBackground;
