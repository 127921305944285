/* eslint-disable */
import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import store from './store'

if(process.env.NODE_ENV !== 'development') {
    console.log = () => {}
    console.error = () => {}
}

window.initVue = function(){
    // if(window.typekitFontsLoaded == true && window.isPageInited == false){
        // window.isPageInited = true
        createApp(App).use(router).use(store).mount('#app')
    // }
}

window.initVue()
