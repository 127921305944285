export default{
    methods: {

        toggleSelect(){
            this.isActive = !this.isActive;
        },
        onMouseUp(){
            this.isActive = false;
        }
        
    }
}