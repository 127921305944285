import Utils from './../utils/Utils';

const obj = Utils.keyMirror({
  PROJECTS_LOADED: 'PROJECTS_LOADED',
  CLOTH_LOADED: 'CLOTH_LOADED',
  MOBILE_LOADED: 'MOBILE_LOADED',
});

export default obj;

