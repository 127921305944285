import { PORTFOLIO_STATE } from "../js/constants/PortfolioState.js"

export default{
    data() {
        return {
            mobileThreshold: 576,
            tabletThreshold: 1024,
            mobileRightColumnSpacing: 18,
        }
    },
    methods: {
        recalculateMobileRightColumnSpacing () {
            this.mobileRightColumnSpacing = window.innerWidth <= this.tabletThreshold ? 0 : 18
        },
        onPortfolioShrinked () {
            
        },
        onPortfolioWillShrink () {
            
        },
        onPortfolioWillExpand () {
            
        },
        onPortfolioExpanded () {

        },
        onPortfolioStateChange (newValue, oldValue) {
            switch (newValue) {
                case PORTFOLIO_STATE.SHRINKED:
                    this.onPortfolioShrinked(newValue, oldValue);
                break;
                case PORTFOLIO_STATE.WILL_SHRINK:
                    this.onPortfolioWillShrink(newValue, oldValue);
                break;
                case PORTFOLIO_STATE.EXPANDED:
                    this.onPortfolioExpanded(newValue, oldValue);
                break;
                case PORTFOLIO_STATE.WILL_EXPANDED:
                    this.onPortfolioWillExpand(newValue, oldValue);
                break;
            }
        }
    },
    computed: {
        portfolioScrollOffset () {
            return this.$store.getters.portfolioScrollOffset
        },
        projectViewState () {
            return this.$store.getters.projectViewState
        },
        isPortfolioExpanded () {
            return this.$store.getters.projectViewState == PORTFOLIO_STATE.EXPANDED
        },
        isPortfolioShrinked () {
            return this.$store.getters.projectViewState == PORTFOLIO_STATE.SHRINKED
        },
        isProjectMouseOvered(){
            return this.$store.getters.isProjectHovered //&& this.$store.getters.isPortfolioShrinked
        },
        windowWidth(){
            return this.$store.getters.windowWidth
        },
        windowHeight(){
            return this.$store.getters.windowHeight
        },
        portfolioCaruselHeight (){
            return this.windowWidth <= this.mobileThreshold ? this.windowWidth * 16 / 9 * 0.5625 : this.windowHeight;
        },
        expandedPortfolioVisibility (){
            return this.$store.getters.isProjectMouseOvered
        },
        isMobile(){
            return this.$store.getters.isMobile
        },
        isRightColumnExpanded(){
            return this.$store.getters.isRightColumnExpanded
        },
        leftColumnMaxWidth(){
            this.recalculateMobileRightColumnSpacing()
            let desktopMaxWidth = this.windowWidth * 0.75;
            let mobileMaxWidth = this.windowWidth - this.mobileRightColumnSpacing;

            return this.isMobile ? mobileMaxWidth : desktopMaxWidth;
        },
        rightColumnMinWidth(){
            let desktopMinWidth = this.windowWidth * 0.25;
            let mobileMinWidth = this.windowWidth - this.mobileRightColumnSpacing;

            return this.isMobile ? mobileMinWidth : desktopMinWidth;
        },
        homeVisibility(){
            return this.$store.getters.homeVisibility;
        },
        // rightColumnMinWidth(){ 
        //     return this.isMobile ? this.windowWidth : 335;
        // }
      },
    watch:{
        portfolioScrollOffset () {
            // console.log("%c 🌱: portfolio mixin -> portfolioScrollOffset ", "font-size:16px;background-color:#9f395e;color:white;", newValue)
        },
        projectViewState (newValue, oldValue) {
            this.onPortfolioStateChange(newValue, oldValue)
        }
    }
}