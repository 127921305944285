import Utils from './../utils/Utils';

const obj = Utils.keyMirror({
    PROJECT_OPEN: 'PROJECT_OPEN',
    PROJECT_CLOSE: 'PROJECT_CLOSE',
    CAMERA_ROTATE: 'CAMERA_ROTATE',
    SELECT_PROJECT: 'SELECT_PROJECT',
    SELECT_PROJECT_NOCLOTH: 'SELECT_PROJECT_NOCLOTH',
    UPDATE_SCROLLER_PROJECT: 'UPDATE_SCROLLER_PROJECT',
    CAMERA_ROTATE_START: 'CAMERA_ROTATE_START',
    CARUSEL_PORTFOLIO_CHANGED: 'CARUSEL_PORTFOLIO_CHANGED',
    ENTER_PROJECTS: 'ENTER_PROJECTS',
    CLOSE_PROJECTS: 'CLOSE_PROJECTS',
});

export default obj;


export const PortfolioTransitionsParams = {
    duration: 1,
    ease: 'power1.inOut',
}

export const ClothTransitionsParams = {
    ease: 'power1.in',
    duration: .25,
}

export const ClothTransitionsParamsFast = {
    duration: .3,
}

export const PortfolioTransitionsParamsFastIn = {
    duration: .35,
    ease: 'power3.in',
}

export const PortfolioTransitionsParamsFastOut = {
    duration: .35,
    ease: 'power3.out',
}
  