<template>
  <div class="right-column-wrapper" :style="{left: colRightWidth < rightColumnMinWidth ? `${-(rightColumnMinWidth - colRightWidth) / 2}px` : 0}">
    <div
      class="right-column-container"   
      :style="{ width: isPortfolioShrinked ? '100%' : `${lastColRightWidth}px`}">
        <ProjectsList 
          @on-all-video-loaded="$emit('on-all-video-loaded')"
          :style="{'pointer-events' : isPortfolioShrinked ? 'all' : 'none'}"></ProjectsList>
    </div>
  </div>
</template>

<script>
import ProjectsList from '@/views/ProjectsList';
import portfolioMixin from '@/mixins/portfolio-mixin';

export default {
  mixins: [portfolioMixin],
  emits:['on-all-video-loaded'],
  components:{
    ProjectsList
  },
  props: ['lastColRightWidth', 'colRightWidth']
}
</script>
