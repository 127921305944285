<template>
  <div>
    <router-view/>
  </div>
  
</template>

<script>
import { onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'

export default {
  setup(){

    const store = useStore();

    const onResize = () => {
      store.dispatch('onResize', {width: window.innerWidth, height: window.innerHeight});
    }
    
    onMounted(() => {
      window.addEventListener('orientationchange', onResize);
      window.addEventListener('resize', onResize);
      onResize();
    })

    onBeforeUnmount(() => {
      window.removeEventListener('orientationchange', onResize);
      window.removeEventListener('resize', onResize);
    })
  }

}
</script>
<style lang="scss">
@import './scss/main';
</style>
