<template>
    <div class="portfolio-item portfolio-end-border bm-container-wrapper">

        <!-- SPACE FOR CARUSEL -->
        <div class="portfolio-space-for-carusel">
            <div class="scroll-to-read">Scroll to read case study</div>
        </div>

        <div class="bm-row bm-container bm-sm bm-md bm-xs">
            <div class="bm-col-md-2 bm-col-xs-1"></div>
            <div class="bm-col-md-14 bm-col-xs-16 portfolio-item-content no-gutters">

                <ItemDescription
                    lead="The Prix Pictet, founded in 2008, is the world's leading award for photography and sustainability, spotlighting crucial environmental issues through various photographic genres."
                    role="UI/UX, Motion design, Art direction, Development" client="Prix Pictet" year="2023" link="https://prix.pictet.com" :recognition="[
                        'People’s Lovie Award',
                        'Lovie Awards Silver',
                    ]"/>

                <div class="bm-col-14 portfolio-row-margin">
                    <VideoPlayer :topPosition="topPosition" :hasSound="false" :hasInfo="true"
                        path="/assets/portfolio/prix/video/01.mp4" poster="/assets/portfolio/prix/video/01.webp">
                    </VideoPlayer>
                </div>

                <!-- <div class="bm-row portfolio-images bm-col-14"> -->
                    <div class="portfolio-item--images-mobile-row odd">
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/prix/mobile/01m.webp"
                                        src="/assets/portfolio/prix/mobile/01.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-col-md-5 bm-col-xs-2"></div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/prix/mobile/02m.webp"
                                        src="/assets/portfolio/prix/mobile/02.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img ">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/prix/mobile/03m.webp"
                                        src="/assets/portfolio/prix/mobile/03.webp" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bm-col-xs-10 bm-col-sm-8 bm-col-11">
                        <div class="portfolio-img">
                            <div class="img-with-ratio-4-3">
                                <DeferredImage placeholderSrc="/assets/portfolio/prix/desktop/03m.webp"
                                    src="/assets/portfolio/prix/desktop/03.webp" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="portfolio-item--images-mobile-row even">
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/prix/mobile/04m.webp"
                                        src="/assets/portfolio/prix/mobile/04.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-d-none bm-d-xs-block bm-col-xs-2"></div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/prix/mobile/05m.webp"
                                        src="/assets/portfolio/prix/mobile/05.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-col-md-5 bm-col-xs-9"></div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img ">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/prix/mobile/06m.webp"
                                        src="/assets/portfolio/prix/mobile/06.webp" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->

                <div class="bm-col-14 portfolio-row-margin">
                    <VideoPlayer :topPosition="topPosition" :hasSound="false" :hasInfo="true"
                        path="/assets/portfolio/prix/video/02.mp4" poster="/assets/portfolio/prix/video/02.webp">
                    </VideoPlayer>
                </div>
            </div>
            <div class="bm-col-md-2 bm-col-xs-1"></div>
        </div>
    </div>
</template>
<script>
import VideoPlayer from '@/components/portfolio/VideoPlayer'
import ItemDescription from '@/components/portfolio/ItemDescription'
import portfolioItemMixin from '@/mixins/portfolio-item-mixin';
import DeferredImage from '@/components/portfolio/DeferredImage'


export default {
    mixins: [portfolioItemMixin],
    components: {
        VideoPlayer,
        ItemDescription,
        DeferredImage,

    }
}
</script>