import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue'
// import ProjectsList from '../views/ProjectsList.vue'
// import PortfolioExpanded from '../views/PortfolioExpanded.vue'
// import gsap from 'gsap'

const routes = [
  {
    path: "/:catchAll(.*)",
    component: Home,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // gsap.to('.right-column-content', {
  //   duration: 0.75, 
  //   opacity: 0,
  //   ease: "power3.inOut",
  //   onComplete: () => {
  //     gsap.to('.right-column-content', {
  //       opacity: 1,
  //       duration: 0.75, 
  //       ease: "power3.inOut",
  //     });
      next()
  //   }
  // })
  
})

export default router
