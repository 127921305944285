import StageStore from './../StageStore';
import PageConstants from './../constants/PageConstants';

let instance = null;

class MainPage {
  constructor() {
    if (!instance) {
      instance = this;

      this.updateStageF = this.updateStage.bind(this);

      StageStore.on(PageConstants.STAGE_RESIZE, this.updateStageF);
    }

    return instance;
  }

  // updateStage(event) {
  updateStage() {
    // console.log("this", this);
    // console.log("event", event);
    // console.log('MAINEPAGE state', StageStore.getViewPortWidth(), StageStore.getViewPortHeight());
  }

}

export default MainPage;
