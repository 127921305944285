import _ from 'lodash';

const Utils = {

  keyMirror(keys) {
    keys = Array.isArray(keys) ? keys : Object.keys(keys);
    return _.reduce(keys, (res, v) => {
      res[v] = v;
      return res;
    }, {});
  },

  setElementTransform(ele, x, y) {
    this.v = `translate3d(${x},${y}, 0px)`;
    ele.style.transform = this.v;
    ele.style.WebkitTransform = this.v;
  }
};

export default Utils;
