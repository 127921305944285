<template>
  <div class="select-container">
    <div class="select-clickoutside-are" v-show="isActive" @mouseup="onMouseUp"></div>
    <div class="select-value bright-body-size-form" @click="toggleSelect" @blur="blur">{{'\xa0'}} 
      <span v-if="!multi">
        <span class="multi-select-value" v-for="item in modelValue" v-bind:key="'item-'+item">{{ item }}</span>
      </span>
    </div>
    <div class="select-items-container" v-show="isActive">
      <div class="select-items" v-show="isActive">
        <div class="select-item bright-body-size-form" @click="onValueSelected(item)" v-for="item in options" :key="item">{{ item }} <span class="select-item-remove" v-if="this.value.includes(item)">x</span></div>
      </div>
    </div>
    <div v-if="multi">
      <span v-for="v in value" v-bind:key="'value' + v">{{v}}</span>
    </div>
  </div>
</template>

<script>
import selectMixin from "@/mixins/select"
export default {
  props:{
    options: Array,
    multi: Boolean,
    modelValue: Array
  },
  mixins: [selectMixin],
  emits: ['update:modelValue'],
  data() {
    return {
      isActive: false, 
      value: []
    }
  },
  methods: {
    
    blur() {
      console.log('BLUR');
      
    },

    onValueSelected(value){
      if(!this.value.includes(value)){
        this.value.push(value)
        this.$emit('update:modelValue', this.value)
      } else {
        this.value = this.value.filter(item => item != value);
        this.$emit('update:modelValue', this.value)
      }
      // this.isActive = false;
    }
  },
}
</script>
