<template>
<div ref="videoContainer">
  <!-- <div class="col"> -->
    <!-- <div  style="display: flex; color: white; font-size: 10px;">
      DEBUG 
      <div>{{isMobile ? pathMobile : path}}</div>
      <div>{{isMuted}}</div>
    </div> -->
    <div ref="videoWrapper" class="video-container">
      <video ref="video" class="video-player" playsinline loop muted :poster="poster">
        <!-- <source :src="isMobile ? path : path" type="video/mp4"> -->
      </video>
      <div 
        ref="soundToggle"
        v-if="hasSound" 
        class="video-unmute"
        @mouseover="onMouseOver"
        @mouseout="onMouseOut"
        @mousedown="onMouseDown"
        @mouseup="onMouseUp">
        <span ref="muteLabel" style="pointer-events: none">
          {{ soundLabel }}
        </span>
      </div>
      <img class="video-poster" ref="poster" :src="poster" />
      <img class="video-background" ref="posterBackground" :src="poster" />
    </div>
    <!-- <div class="info-container" v-if="hasSound">
      <div class="info">(INFO)</div>
      <div 
        class="info" 
        @mousedown="onMouseDown" 
        @mouseup="onMouseUp">{{ this.isMuted ? 'Unmute' : 'Mute' }}</div>
    </div> -->
  <!-- </div> -->
</div>
</template>

<script>
import { PORTFOLIO_STATE } from "@/js/constants/PortfolioState.js"
import gsap from 'gsap'

export default{
  props: {
    path: String,
    pathMobile: String,
    poster: String,
    topPosition: Number,
    hasSound: Boolean,
    hasInfo: Boolean
  },
  data(){
    return {
      topOffset: 0,
      height: 0,
      isInViewPort: false,
      y: 0,
      video: null,
      isVisible: false,
      isMuted: true,
      soundLabel: 'Unmute',
      videoTimeout: null,
      mouseDownTime: new Date(),
      loaded: false
    }
  },
  mounted() {
    this.$nextTick (() => this.onResize())
    setTimeout (() => {
      this.onResize()
    }, 1000)
    
    // this.video = this.$refs.video
    // if(this.video.parentNode)
    //     this.$refs.videoWrapper.removeChild(this.video);
  },
  methods: {
    onMouseDown(){
      this.mouseDownTime = new Date()
    },
    onMouseUp(){
      var timeDiff = new Date() - this.mouseDownTime
      if (timeDiff < 150){
        this.onClick()
      }
    },
    onClick(){
      this.isMuted = !this.isMuted
    },
    onMouseOver(){
      gsap.to(this.$refs.soundToggle, {duration: 0.3, ease: 'power3.out', opacity: 0.97})
    },
    onMouseOut(){
      gsap.to(this.$refs.soundToggle, {duration: 0.3, ease: 'power3.out', opacity: 0})
    },
    onResize (){
      this.topOffset = this.$refs.videoContainer.offsetTop
      this.height = this.$refs.videoContainer.getBoundingClientRect().height

      this.isInView()
    },
    toggleVideo () {
      
      if (this.isInViewPort && this.isPortfolioExpanded){
        this.isVisible = true
        
        // this.$refs.videoWrapper.appendChild(this.video);
        // this.$refs.video && this.$refs.video.play()
        // console.log("%c 🏧: toggleVideo -> play ", "font-size:16px;background-color:#d5da7f;color:black;", this.isVisible, this.path)
      }else{
        this.isVisible = false
        
        // if(this.$refs.video.parentNode)
        //   this.$refs.videoWrapper.removeChild(this.video);
        this.isMuted = true
        // this.$refs.video && this.$refs.video.pause()
        // console.log("%c 👨‍👩‍👦: toggleVideo -> this.isVisible ", "font-size:16px;background-color:#74227b;color:white;", this.isVisible, this.path)

      }
    },
    isInView (){
      const rect = this.$refs.videoContainer.getBoundingClientRect();
      if (
        rect.top > window.innerHeight ||
        rect.bottom < 0
      ) {
        this.isInViewPort = false;
      } else {
        this.isInViewPort = true;
      }
      // var viewportTop = this.top - this.scrollY 
      // var viewportBottom = viewportTop + this.height

  
      // if (viewportTop + this.windowHeight > 0 && viewportTop < this.$store.getters.windowHeight && viewportBottom > 0){
      //   this.isInViewPort = true
      // } else {
      //   this.isInViewPort = false
      // }

    }
  },
  computed: {
    scrollY (){
      return this.$store.getters.portfolioScrollOffset
    },
    top(){
      return this.topPosition + this.topOffset
    },
    isPortfolioExpanded () {
      return this.$store.getters.projectViewState == PORTFOLIO_STATE.EXPANDED ? true : false
    },
    windowWidth(){
      return this.$store.getters.windowWidth
    },
    windowHeight(){
      return this.$store.getters.windowHeight
    },
    isMobile(){
      return this.$store.getters.isMobile
    },
  },
  watch: {
    isVisible(){
      if(this.isVisible){
        if(!this.loaded) {
          this.loaded = true;
          const source = document.createElement('source');


          source.src = this.path;
          source.type = 'video/mp4';

          const onPlay = () => {
            this.$refs.video.removeEventListener('play', onPlay);
            gsap.to(this.$refs.video, { opacity: 1, scale: 1, duration: 0.5, onComplete: () => {
              this.$refs.videoWrapper.removeChild(this.$refs.poster);
              this.$refs.videoWrapper.removeChild(this.$refs.posterBackground);
            } })
          }

          this.$refs.video.addEventListener('play', onPlay);
          this.$refs.video.appendChild(source);

          gsap.to([this.$refs.poster, this.$refs.posterBackground], { opacity: 1, scale: 1, duration: 0.5 })
        }
        clearTimeout(this.videoTimeout)
        this.videoTimeout = setTimeout( () => {
          this.isVisible && this.$refs.video && this.$refs.video.play()
        }, this.isMobile ? 500 : 0)
      }else{
        clearTimeout(this.videoTimeout)
        this.$refs.video && this.$refs.video.pause()
      }
    },
    isMuted(){
      this.$refs.video.muted = this.isMuted
      gsap.timeline()
        .to(this.$refs.muteLabel, { duration: 0.2, ease: 'power1.in', opacity: 0, y: this.isMuted ? 10 : -10, onComplete: () => this.soundLabel = this.isMuted ? 'Unmute' : 'Mute' })
        .set(this.$refs.muteLabel, { y: this.isMuted ? -10 : 10 })
        .to(this.$refs.muteLabel, { duration: 0.2, ease: 'power1.out', opacity: 1, y: 0 })
    },
    isPortfolioExpanded(){
      this.toggleVideo()
    },
    isInViewPort(){
      this.toggleVideo()
    },
    topPosition(){
      this.isInView()
    },
    scrollY(){
      // this.topOffset = this.$refs.videoContainer.offsetTop
      this.isInView()
    },
    windowWidth(){
      this.$nextTick(() => {
        this.onResize()
      })
    },
    windowHeight(){
      this.$nextTick(() => {
        this.onResize()
      })
    }
  }
}
</script>