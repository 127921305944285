<template>
  <!-- <div class="left-column-bg"></div> -->
  <div class="left-column-wrapper home" :class="rwdClassList"
    :style="{ 'width': isPortfolioShrinked ? '100%' : `${lastColLeftWidth}px` }">
    <div class="border-bottom">
      <div class="bm-container-wrapper bm-xs bm-sm bm-md bm-lg bm-xl" :style="{ 'max-width': `${leftColumnMaxWidth}px` }">
        <div class="bm-container home-section"
          :style="{ 'min-height': `${windowHeight - 25}px` }">
          <!-- GRID DEBUG -->
          <!-- <div class="bm-row debug-info">
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
            <div class="bm-col-1">
              <div class="debug-bg"></div>
            </div>
          </div> -->
          <div class="bm-row" style="height: 0; overflow: hidden;">
            <div ref="leftMarginColumn" class="bm-col-1"></div>
          </div>
          <div class="bm-row">
            <div class="bm-d-flex bm-col-sm-12 bm-col-md-10 bm-offset-md-1 bm-col-xs-12 bright-nav-size"
              >
              <div class="start-a-project-rect intro-animation-1"></div>
              <div class="start-a-project intro-animation-1" @click="onStartProject" @mouseover="onSocialMouseOver"
                @mouseout="onSocialMouseOut">
                Start a project
                <div class="border-in-transition"></div>
              </div>
              <div :style="{'display': colLeftWidth > 400 ? 'block' : 'none'}" class="small-cta small-body ml hide-on-mobile intro-animation-1 project-prompter">Let's storytell!</div>
            </div>

            <div
              class="bm-d-flex bm-col-10 bm-col-sm-6 bm-col-md-6 bm-col-xs-6 contact bm-flex-end bright-nav-size"
              >
              <div class="see-work intro-animation-1" @click="onSeeWorkClick">
                See work
              </div>
              <div class="small-cta small-body ml intro-animation-1 contactdetails" @mouseover="onSocialMouseOver" @mouseout="onSocialMouseOut" @click="onCareersClick">
                careers
                <div class="border-in-transition"></div>
              </div>
              <a class="small-cta small-body ml intro-animation-1 contactdetails" href="mailto:contact@wearebright.studio" @mouseover="onSocialMouseOver" @mouseout="onSocialMouseOut">
                contact@wearebright.studio
                <div class="border-in-transition"></div>
              </a>
            </div>

          </div>

          <div class="bm-d-flex col flex-column align-items-end clear-padding">
            <div class="bm-row bright-intro-container">
              <!-- <div class="bm-col-2"></div> -->
              <div class="bm-offset-3 bm-col-12 bm-col-xs-18 bm-offset-xs-0">
                <div class="bright-intro intro-animation-1  bright-body-size">
                  Boutique digital agency crafting digital presence <br class="hide-on-mobile" />for brands, financial and cultural institutions.
                </div>
              </div>
              <!-- <div class="bm-col-2"></div> -->
            </div>
            <div class="bm-row">
              <div class="bm-col-18 bm-col-sm-18 bm-offset-sm-0 bm-col-md-16 bm-offset-md-1 bm-col-xl-16 bm-offset-xl-1">
                <div class="bright-logo-text bright-logo-size" id="home-logo-text">
                  <!-- Bright -->
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1195.1 284.53">
                    <g>
                      <polygon class="bright-logo" points="1143.61 279.82 1143.61 60.13 1195.1 60.13 1195.1 4.72 1031.22 4.72 1031.22 60.13 1082.7 60.13 1082.7 279.82 1143.61 279.82"/>
                      <polygon class="bright-logo" points="873.67 279.82 873.67 164.27 954.23 164.27 954.23 279.82 1015.54 279.82 1015.54 4.72 954.23 4.72 954.23 108.86 873.67 108.86 873.67 4.72 812.75 4.72 812.75 279.82 873.67 279.82"/>
                      <path class="bright-logo" d="m643.93,117.51l-14.15,53.45h106.11c-6.29,31.83-29.47,56.59-71.53,56.59-47.95,0-83.71-36.55-83.71-85.28,0-45.98,34.58-83.71,81.35-83.71,28.69,0,51.88,14.93,66.02,36.16l49.12-35.76C752,24.37,713.49,0,661.61,0c-79.39,0-145.02,64.06-145.02,142.27s62.49,142.27,145.02,142.27c93.93,0,132.83-70.74,133.23-117.9.39-29.08-14.54-49.12-53.45-49.12h-97.46Z"/>
                      <rect class="bright-logo" x="436.02" y="4.72" width="60.91" height="275.1"/>
                      <path class="bright-logo" d="m318.6,59.74c21.61,0,33.8,12.97,33.8,30.65,0,16.51-12.18,29.87-33.8,29.87h-42.84v-60.52h42.84Zm54.23,98.64c26.33-14.93,39.3-41.66,39.3-70.74,0-45.59-32.62-82.92-91.18-82.92h-106.11v275.1h60.92v-108.86h39.69l36.94,108.86h67.2l-46.77-121.44Z"/>
                      <path class="bright-logo" d="m59.74,227.15v-64.06h42.44c21.62,0,34.19,15.33,34.19,31.44,0,18.86-13.76,32.62-34.98,32.62h-41.66ZM93.14,57.38c20.04,0,31.83,12.58,31.83,28.69s-12.58,29.87-31.44,29.87h-33.8v-58.56h33.4Zm65.63,76.64c14.54-13.36,25.15-30.26,25.15-53.05,0-40.87-31.44-76.24-91.57-76.24H0v275.1h101.79c62.09,0,93.93-37.73,93.93-82.53,0-24.76-14.15-49.12-36.94-63.27Z"/>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- How we work -->
    <div class="border-bottom intro-animation-1">
      <div class="bm-container-wrapper" :style="{ 'max-width': `${leftColumnMaxWidth}px` }">
        <div class="bm-container bm-container-padding bright-body-size">
          <div class="bm-row body-text">
            <div class="bm-col-18">
              <h2 class="home-title">Who we are</h2>
            </div>
          </div>
          <div class="bm-row">
            <div
              class="bm-col-18 bm-col-sm-18 bm-offset-sm-0 bm-col-md-16 bm-offset-md-1 bm-col-xl-16 bm-offset-xl-1 body-text"
            >
              Since 2013 we craft digital presence for brands, cultural institutions and individuals. In our work we
              strive to to balance research, intuition, craft and technology to create apps, websites,
              brands and digital content. We are not afraid of bold solutions and we love to work with brave and
              passionate people. We have an ambition to approach each project in a unique way and are always eager to
              discover more. We work globally from our studio in Warsaw.
            </div>
          </div>
          <div class="bm-row what-we-do">
            <div class="bm-d-flex body-text flex-no-wrap what-we-do-wrapper align-items-center"
            >
              <div class="bm-do-list">
                <div class="bm-do-item">Websites</div>
                <div class="bm-do-item">Experiences</div>
                <div class="bm-do-item">Digital branding</div>
                <div class="bm-do-item">Technomagic</div>
                <div class="bm-do-item">Apps</div>
                <div class="bm-do-item">Design Systems</div>
                <div class="bm-do-item">Artificial</div>
              </div>
              <div class="bm-do-list">
                <div class="bm-do-item">Websites</div>
                <div class="bm-do-item">Interactive experiences</div>
                <div class="bm-do-item">Digital branding</div>
                <div class="bm-do-item">Technomagic</div>
                <div class="bm-do-item">Apps</div>
                <div class="bm-do-item">Design Systems</div>
                <div class="bm-do-item">Artificial</div>
              </div>
              <div class="bm-do-list">
                <div class="bm-do-item">Websites</div>
                <div class="bm-do-item">Experiences</div>
                <div class="bm-do-item">Digital branding</div>
                <div class="bm-do-item">Technomagic</div>
                <div class="bm-do-item">Apps</div>
                <div class="bm-do-item">Design Systems</div>
                <div class="bm-do-item">Artificial</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- What we do -->
    <!-- <div class="border-bottom">
      <div class="bm-container-wrapper" :style="{'max-width': `${leftColumnMaxWidth}px`}">
        <div class="bm-container bm-container-padding">
          <div class="bm-row body-text">
            <div class="bm-col-18">
              <h2 class="home-title">What we do</h2>
            </div>
          </div>
          <div class="bm-row">
            <div class="bm-d-flex bm-col-12 bm-col-sm-18 bm-offset-sm-0 bm-col-md-12 bm-offset-md-3 bm-col-xl-12 bm-offset-xl-3 body-text italic justify-content-center flex-wrap" :style="{'font-size': `${brightBodySize}px`}">
              <div class="bm-do-item">Websites</div><div class="bm-do-item">Interactive experiences</div><div class="bm-do-item">Digital branding</div><div class="bm-do-item">Mobile Apps</div><div class="bm-do-item">Design Systems</div><div class="bm-do-item">Graphic Design</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Awards -->
    <div class="border-bottom">
      <div class="bm-container-wrapper" :style="{ 'max-width': `${leftColumnMaxWidth}px` }">
        <div class="bm-container bm-container-padding">
          <div class="bm-row body-text">
            <div class="bm-col-18">
              <h2 class="home-title bright-body-size">Awards</h2>
            </div>
          </div>
          <div class="bm-row bright-nav-size" >
            <!-- <div class="bm-col-1"></div> -->
            <div
              class="bm-col-8 bm-col-xs-18 bm-col-sm-16 bm-col-md-16 bm-col-xl-8 bm-offset-xs-0 bm-offset-sm-1 bm-offset-md-1 bm-offset-xl-1">
              <div class="awarditem">
                <div class="award-number">11</div>
                <div class="award-type">Awwwards</div>
                <div class="award-name">Site of the day</div>
              </div>
              <div class="awarditem">
                <div class="award-number">10</div>
                <div class="award-type">The FWA</div>
                <div class="award-name">Site of the day</div>
              </div>
              <div class="awarditem">
                <div class="award-number">1</div>
                <div class="award-type">European Design awards</div>
                <div class="award-name">Design</div>
              </div>
              <div class="awarditem">
                <div class="award-number">4</div>
                <div class="award-type">Behance</div>
                <div class="award-name">Recognitions</div>
              </div>
              <!-- <TeamView :colLeftWidth="colLeftWidth"></TeamView> -->
            </div>
            <div
              class="bm-col-8 bm-col-xs-18 bm-col-sm-16 bm-col-md-16 bm-col-xl-8 bm-offset-xs-0 bm-offset-sm-1 bm-offset-md-1 bm-offset-xl-0">
              <div class="awarditem">
                <div class="award-number">2</div>
                <div class="award-type">Webby awards</div>
                <div class="award-name">Cultural institutions</div>
              </div>
              <div class="awarditem">
                <div class="award-number">3</div>
                <div class="award-type">Lovie awards</div>
                <div class="award-name">Cultural institutions</div>
              </div>
              <div class="awarditem">
                <div class="award-number">4</div>
                <div class="award-type">KTR</div>
                <div class="award-name">Klub twórców reklamy</div>
              </div>
              <div class="awarditem">
                <div class="award-number">1</div>
                <div class="award-type">Kreatura</div>
                <div class="award-name">Konkurs niezaleznej kreacji</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div>
      <div class="bm-container-wrapper" :style="{ 'max-width': `${leftColumnMaxWidth}px` }">
        <div class="bm-container">
          <div class="bm-row footer">
            <div class="bm-d-flex bm-col-sm-6 bm-col-md-8 bm-offset-md-1 bm-col-xs-9 bright-nav-size"
              >
              <a class="small-cta small-body" href="mailto:contact@wearebright.studio" @mouseover="onSocialMouseOver" @mouseout="onSocialMouseOut">
                contact@wearebright.studio
                <div class="border-in-transition"></div>
              </a>
              <div href="tel:+48534707278" class="small-cta small-body ml phone" @mouseover="onSocialMouseOver" @mouseout="onSocialMouseOut">(+48) 534707278
                <div class="border-in-transition"></div>
              </div>
            </div>

            <div
              class="bm-d-flex bm-col-10 bm-col-sm-12 bm-col-md-8 bm-col-xs-9 bm-flex-end bright-nav-size"
              >
                <!-- <div class="small-cta small-body ml" href="https://www.behance.net/bright_media/"
                  @mouseover="onSocialMouseOver" @mouseout="onSocialMouseOut">BE <div class="border-in-transition">
                  </div></div> -->
                  <div class="small-cta small-body" @mouseover="onSocialMouseOver" @mouseout="onSocialMouseOut" @click="onCareersClick">
                    Careers
                    <div class="border-in-transition"></div>
                  </div>
                  <a class="small-cta small-body ml" href="https://www.facebook.com/brightmediapl/" target="_blank" 
                    @mouseover="onSocialMouseOver" @mouseout="onSocialMouseOut">FB <div class="border-in-transition">
                    </div></a>
                  <a class="small-cta small-body ml"
                    href="https://www.linkedin.com/company/brightmedia---independent-digital-studio/" target="_blank" 
                    @mouseover="onSocialMouseOver" @mouseout="onSocialMouseOut">LN<div class="border-in-transition">
                    </div></a>
                  <a class="small-cta small-body ml" href="https://twitter.com/brightmediapl" target="_blank" @mouseover="onSocialMouseOver"
                    @mouseout="onSocialMouseOut">TW<div class="border-in-transition"></div></a>
                <!-- <div class="small-cta small-body ml" href="https://www.facebook.com/brightmediapl/"
                  @mouseover="onSocialMouseOver" @mouseout="onSocialMouseOut">FB <div class="border-in-transition">
                  </div></div>
                <div class="small-cta small-body ml"
                  href="https://www.linkedin.com/company/brightmedia---independent-digital-studio/"
                  @mouseover="onSocialMouseOver" @mouseout="onSocialMouseOut">LN<div class="border-in-transition">
                  </div></div>
                <div class="small-cta small-body ml" href="https://twitter.com/brightmediapl" @mouseover="onSocialMouseOver"
                  @mouseout="onSocialMouseOut">TW<div class="border-in-transition"></div></div> -->
            </div>

          </div>
          <div class="bm-d-flex col flex-column align-items-end clear-padding">
            <div class="bm-row bright-intro-container">
              <div class="bm-offset-3 bm-col-12 bm-col-xs-18 bm-offset-xs-0">
                <div class="bright-intro2 intro-animation-1  bright-body-size">
                  <!-- <div class="project-prompter"></div> -->
                  <div class="say-hi project-prompter2" @click="onStartProject"> </div>
                </div>
              </div>
            </div>
            <div class="bm-row">
              <div class="bm-col-18 bm-col-sm-18 bm-offset-sm-0 bm-col-md-16 bm-offset-md-1 bm-col-xl-16 bm-offset-xl-1">
                <div class="bright-logo-text bright-logo-footer bright-logo-size" id="home-logo-text">
                  <!-- Bright -->
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1195.1 284.53">
                    <g>
                      <polygon class="bright-logo" points="1143.61 279.82 1143.61 60.13 1195.1 60.13 1195.1 4.72 1031.22 4.72 1031.22 60.13 1082.7 60.13 1082.7 279.82 1143.61 279.82"/>
                      <polygon class="bright-logo" points="873.67 279.82 873.67 164.27 954.23 164.27 954.23 279.82 1015.54 279.82 1015.54 4.72 954.23 4.72 954.23 108.86 873.67 108.86 873.67 4.72 812.75 4.72 812.75 279.82 873.67 279.82"/>
                      <path class="bright-logo" d="m643.93,117.51l-14.15,53.45h106.11c-6.29,31.83-29.47,56.59-71.53,56.59-47.95,0-83.71-36.55-83.71-85.28,0-45.98,34.58-83.71,81.35-83.71,28.69,0,51.88,14.93,66.02,36.16l49.12-35.76C752,24.37,713.49,0,661.61,0c-79.39,0-145.02,64.06-145.02,142.27s62.49,142.27,145.02,142.27c93.93,0,132.83-70.74,133.23-117.9.39-29.08-14.54-49.12-53.45-49.12h-97.46Z"/>
                      <rect class="bright-logo" x="436.02" y="4.72" width="60.91" height="275.1"/>
                      <path class="bright-logo" d="m318.6,59.74c21.61,0,33.8,12.97,33.8,30.65,0,16.51-12.18,29.87-33.8,29.87h-42.84v-60.52h42.84Zm54.23,98.64c26.33-14.93,39.3-41.66,39.3-70.74,0-45.59-32.62-82.92-91.18-82.92h-106.11v275.1h60.92v-108.86h39.69l36.94,108.86h67.2l-46.77-121.44Z"/>
                      <path class="bright-logo" d="m59.74,227.15v-64.06h42.44c21.62,0,34.19,15.33,34.19,31.44,0,18.86-13.76,32.62-34.98,32.62h-41.66ZM93.14,57.38c20.04,0,31.83,12.58,31.83,28.69s-12.58,29.87-31.44,29.87h-33.8v-58.56h33.4Zm65.63,76.64c14.54-13.36,25.15-30.26,25.15-53.05,0-40.87-31.44-76.24-91.57-76.24H0v275.1h101.79c62.09,0,93.93-37.73,93.93-82.53,0-24.76-14.15-49.12-36.94-63.27Z"/>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="bm-row footer" >
            <div class="bm-d-flex bm-col-sm-6 bm-col-md-8 bm-offset-md-1 bm-col-xs-9 bright-nav-size">
              <div class="small-body">
                © BRIGHTMEDIA 2023
              </div>
            </div>
            <div class="bm-d-flex bm-col-10 bm-col-sm-12 bm-col-md-8 bm-col-xs-9 bm-flex-end bright-nav-size">
              <div class="small-cta small-body">
                <!-- <div class="small-cta small-body" @mouseenter="redrawBorder" @click="onCareersClick">
                  Careers
                  <div class="border-redraw-transition left"></div>
                  <div class="border-redraw-transition right"></div>
                </div> -->
                
                <div class="small-cta small-body ml" @click="onCookiesClick" @mouseover="onSocialMouseOver" @mouseout="onSocialMouseOut">
                  Cookie policy
                  <div class="border-in-transition"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isPortfolioShrinked && !isMobile" ref="colorApla" class="left-column-hover-apla"
      :style="{ opacity: (((windowWidth / 2) / colLeftWidth) - 1) > 0.9 ? 0.9 : (((windowWidth / 2) / colLeftWidth) - 1) }">
    </div>

  </div>
</template>

<script>
/* eslint-disable */
// import lottie from 'lottie-web';

// import * as animationData from '../json/data.json';

import portfolioMixin from '@/mixins/portfolio-mixin';
// import TeamView from '@/components/home/TeamView';
import gsap from 'gsap';
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import redrawBorder from '@/mixins/redraw-border';


// import { onUpdated } from '@vue/runtime-core';
import emitter from '@/js/emitter'

gsap.registerPlugin(Draggable, InertiaPlugin);

export default {
  mixins: [portfolioMixin, redrawBorder],
  components: {
    // TeamView
  },
  emits: ['on-see-work', 'on-start-project', 'on-careers-click', 'on-cookies-click'],
  props: ['lastColLeftWidth', 'colLeftWidth'],
  data() {
    return {
      lockedWidth: 0,
      bgColor: "#f7f7f2",
      bgColorAlpha: "#f7f7f200",
      newsletterEmail: ''
    }
  },
  mounted() {

    /*var animation = lottie.loadAnimation({
      container: document.getElementById('intro-logo'), // required
      animationData: animationData.default,
      renderer: 'svg', // required
      autoplay: false, // optional
      loop: false,
      name: "Bright signature", // optional
    });

    animation.play();

    // var tl = gsap.timeline({repeat:0, yoyo: false});

    // var animFrames = {frame:0};

    // tl.to(
    //   animFrames,  
    //   {
    //     duration:1,
    //     frame:40,

    //     onUpdate: () => {
    //       console.log(Math.round(animFrames.frame));

    //       animation.goToAndStop(Math.round(animFrames.frame), true)},
    //       ease: "linear.none"
    //     }

    //   );


    emitter.on("on-left-column-scroll", (position) => {

      var where = Math.round(40 - position.offsetY / 2);

      if (where < 0) where = 0;

      //animation.goToAndStop(where, true)

      if (where > 20) {
        //animation.play();
        animation.setSpeed(0.1);
      } else {
        animation.setSpeed(-0.1);
      }

      //animation.play();
      //console.log("where: ", where);


      //animation.goToAndStop(where, true)
    })

    emitter.on("on-overscroll", (position) => {

      var where = Math.round(34 - position.y / 4);

      if (where < 0) where = 0;

      animation.goToAndStop(where, true)
    })*/

    this.onResize()

    this.activateProjectPrompts();

    // this.activateWhatWeDo()

    setTimeout(this.activateWhatWeDo, 100);
  },
  methods: {

    onResize() {
      this.$nextTick(() => {
        this.meassureForm()
        this.setInterpolatedFontSizes();
      })

    },
    meassureForm() {

      const leftPadding = Math.round(this.$refs.leftMarginColumn.getBoundingClientRect().width) + (this.colLeftWidth < 768 ? 5 : 15);
      this.$store.dispatch('setHomeColumnWidth', leftPadding)

    },
    onSeeWorkClick() {
      this.$emit('on-see-work')
    },
    onStartProject() {
      this.$emit("on-start-project")
    },
    onCareersClick() {
      this.$emit('on-careers-click')
    },
    onCookiesClick() {
      this.$emit('on-cookies-click');
    },
    onMouseOver(e) {
      if (e.target && !e.target.tl) {
        e.target.tl = gsap.timeline({ paused: true })
          .to(e.target.querySelector('.border-out-transition'), { width: 0, left: '100%', duration: 0.3, ease: 'power1.inOut' })
          .set(e.target.querySelector('.border-out-transition'), { left: '0%' })
          .to(e.target.querySelector('.border-out-transition'), { width: '100%', duration: 0.3, ease: 'power1.inOut' })
      }

      if (e.target.tl.isActive() == false) {
        e.target.tl.play(0)
      }
    },
    onSocialMouseOut(e) {
      gsap.to(e.target.querySelector('.border-in-transition'), { width: '0%', left: '100%', duration: 0.3, ease: 'power.inOut' })
    },
    onSocialMouseOver(e) {
      gsap.set(e.target.querySelector('.border-in-transition'), { width: '0%', left: '0%', duration: 0.3, ease: 'power1.inOut' })
      gsap.to(e.target.querySelector('.border-in-transition'), { width: '100%', left: '0%', duration: 0.3, ease: 'power1.inOut' })
    },

    activateWhatWeDo() {

      const wrapper = document.querySelector(".what-we-do");
      const boxes = gsap.utils.toArray(".bm-do-list");

      const loop = horizontalLoop(boxes, {
        paused: false,
        draggable: true, // make it draggable
        center: false, // active element is the one in the center of the container rather than th left edge
        speed: 1,
        repeat: -1,
        onChange: (element, index) => { // when the active element changes, this function gets called.
          // activeElement && activeElement.classList.remove("active");
          // element.classList.add("active");
          // activeElement = element;
        }
      });

      // boxes.forEach((box, i) => box.addEventListener("click", () => loop.toIndex(i, {duration: 0.8, ease: "power1.inOut"})));
      // document.querySelector(".toggle").addEventListener("click", () => wrapper.classList.toggle("show-overflow"));
      // document.querySelector(".next").addEventListener("click", () => loop.next({duration: 0.4, ease: "power1.inOut"}));
      // document.querySelector(".prev").addEventListener("click", () => loop.previous({duration: 0.4, ease: "power1.inOut"}));




      /*
      This helper function makes a group of elements animate along the x-axis in a seamless, responsive loop.

      Features:
      - Uses xPercent so that even if the widths change (like if the window gets resized), it should still work in most cases.
      - When each item animates to the left or right enough, it will loop back to the other side
      - Optionally pass in a config object with values like draggable: true, center: true, speed (default: 1, which travels at roughly 100 pixels per second), paused (boolean), repeat, reversed, and paddingRight.
      - The returned timeline will have the following methods added to it:
        - next() - animates to the next element using a timeline.tweenTo() which it returns. You can pass in a vars object to control duration, easing, etc.
        - previous() - animates to the previous element using a timeline.tweenTo() which it returns. You can pass in a vars object to control duration, easing, etc.
        - toIndex() - pass in a zero-based index value of the element that it should animate to, and optionally pass in a vars object to control duration, easing, etc. Always goes in the shortest direction
        - current() - returns the current index (if an animation is in-progress, it reflects the final index)
        - times - an Array of the times on the timeline where each element hits the "starting" spot.
      */
      function horizontalLoop(items, config) {
        items = gsap.utils.toArray(items);
        config = config || {};
        let onChange = config.onChange,
          lastIndex = 0,
          tl = gsap.timeline({
            repeat: config.repeat, onUpdate: onChange && function () {
              let i = tl.closestIndex()
              if (lastIndex !== i) {
                lastIndex = i;
                onChange(items[i], i);
              }
            }, paused: config.paused, defaults: { ease: "none" }, onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)
          }),
          length = items.length,
          startX = items[0].offsetLeft,
          times = [],
          widths = [],
          spaceBefore = [],
          xPercents = [],
          curIndex = 0,
          center = config.center,
          pixelsPerSecond = (config.speed || 1) * 100,
          snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
          timeOffset = 0,
          container = center === true ? items[0].parentNode : gsap.utils.toArray(center)[0] || items[0].parentNode,
          totalWidth,
          getTotalWidth = () => items[length - 1].offsetLeft + xPercents[length - 1] / 100 * widths[length - 1] - startX + spaceBefore[0] + items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") + (parseFloat(config.paddingRight) || 0),
          populateWidths = () => {
            let b1 = container.getBoundingClientRect(), b2;
            items.forEach((el, i) => {
              widths[i] = parseFloat(gsap.getProperty(el, "width", "px"));
              xPercents[i] = snap(parseFloat(gsap.getProperty(el, "x", "px")) / widths[i] * 100 + gsap.getProperty(el, "xPercent"));
              b2 = el.getBoundingClientRect();
              spaceBefore[i] = b2.left - (i ? b1.right : b1.left);
              b1 = b2;
            });
            gsap.set(items, { // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
              xPercent: i => xPercents[i]
            });
            totalWidth = getTotalWidth();
          },
          timeWrap,
          populateOffsets = () => {
            timeOffset = center ? tl.duration() * (container.offsetWidth / 2) / totalWidth : 0;
            center && times.forEach((t, i) => {
              times[i] = timeWrap(tl.labels["label" + i] + tl.duration() * widths[i] / 2 / totalWidth - timeOffset);
            });
          },
          getClosest = (values, value, wrap) => {
            let i = values.length,
              closest = 1e10,
              index = 0, d;
            while (i--) {
              d = Math.abs(values[i] - value);
              if (d > wrap / 2) {
                d = wrap - d;
              }
              if (d < closest) {
                closest = d;
                index = i;
              }
            }
            return index;
          },
          populateTimeline = () => {
            let i, item, curX, distanceToStart, distanceToLoop;
            tl.clear();
            for (i = 0; i < length; i++) {
              item = items[i];
              curX = xPercents[i] / 100 * widths[i];
              distanceToStart = item.offsetLeft + curX - startX + spaceBefore[0];
              distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
              tl.to(item, { xPercent: snap((curX - distanceToLoop) / widths[i] * 100), duration: distanceToLoop / pixelsPerSecond }, 0)
                .fromTo(item, { xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100) }, { xPercent: xPercents[i], duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond, immediateRender: false }, distanceToLoop / pixelsPerSecond)
                .add("label" + i, distanceToStart / pixelsPerSecond);
              times[i] = distanceToStart / pixelsPerSecond;
            }
            timeWrap = gsap.utils.wrap(0, tl.duration());
          },
          refresh = (deep) => {
            let progress = tl.progress();
            tl.progress(0, true);
            populateWidths();
            deep && populateTimeline();
            populateOffsets();
            deep && tl.draggable ? tl.time(times[curIndex], true) : tl.progress(progress, true);
          },
          proxy;
        gsap.set(items, { x: 0 });
        populateWidths();
        populateTimeline();
        populateOffsets();
        window.addEventListener("resize", () => refresh(true));
        function toIndex(index, vars) {
          vars = vars || {};
          (Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length); // always go in the shortest direction
          let newIndex = gsap.utils.wrap(0, length, index),
            time = times[newIndex];
          if (time > tl.time() !== index > curIndex) { // if we're wrapping the timeline's playhead, make the proper adjustments
            time += tl.duration() * (index > curIndex ? 1 : -1);
          }
          if (time < 0 || time > tl.duration()) {
            vars.modifiers = { time: timeWrap };
          }
          curIndex = newIndex;
          vars.overwrite = true;
          gsap.killTweensOf(proxy);
          return tl.tweenTo(time, vars);
        }
        tl.next = vars => toIndex(curIndex + 1, vars);
        tl.previous = vars => toIndex(curIndex - 1, vars);
        tl.current = () => curIndex;
        tl.toIndex = (index, vars) => toIndex(index, vars);
        tl.closestIndex = setCurrent => {
          let index = getClosest(times, tl.time(), tl.duration());
          setCurrent && (curIndex = index);
          return index;
        };
        tl.times = times;
        tl.progress(1, true).progress(0, true); // pre-render for performance
        if (config.reversed) {
          tl.vars.onReverseComplete();
          tl.reverse();
        }
        if (config.draggable && typeof (Draggable) === "function") {
          proxy = document.createElement("div")
          let wrap = gsap.utils.wrap(0, 1),
            ratio, startProgress, draggable, dragSnap,
            align = () => tl.progress(wrap(startProgress + (draggable.startX - draggable.x) * ratio)),
            syncIndex = () => tl.closestIndex(true);
          typeof (InertiaPlugin) === "undefined" && console.warn("InertiaPlugin required for momentum-based scrolling and snapping. https://greensock.com/club");
          draggable = Draggable.create(proxy, {
            trigger: items[0].parentNode,
            type: "x",
            onPressInit() {
              gsap.killTweensOf(tl);
              startProgress = tl.progress();
              refresh();
              ratio = 1 / totalWidth;
              gsap.set(proxy, { x: startProgress / -ratio })
            },
            onDrag: align,
            onThrowUpdate: align,
            inertia: true,
            snap: value => {
              let time = -(value * ratio) * tl.duration(),
                wrappedTime = timeWrap(time),
                snapTime = times[getClosest(times, wrappedTime, tl.duration())],
                dif = snapTime - wrappedTime;
              Math.abs(dif) > tl.duration() / 2 && (dif += dif < 0 ? tl.duration() : -tl.duration());
              return (time + dif) / tl.duration() / -ratio;
            },
            onRelease: syncIndex,
            onThrowComplete: syncIndex
          })[0];
          tl.draggable = draggable;
        }
        tl.closestIndex(true);
        onChange && onChange(items[curIndex], curIndex);
        return tl;
      }

    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    activateProjectPrompts() {
      let prompts = [
        "Let's storytell!",
        "Let's engage!",
        "Let's technomagic!",
        "Let's marvel!",
        "Let's give it a try!",
        "Let's experiment!",
        "Let's go artificial!",
        "Let's innovate!",
        "Let's get serious!",
        "Let's have fun!",
        "Let's entertain!"
      ]

      let prompts2 = [
        "Let's ignite creativity",
        "Let's build digital art",
        "Let's blend tech and magic",
        "Let's engage in depth",
        "Let's navigate AI",
        "Let's stir up some fun",
        "Let's seize opportunities",
        "Let's cultivate innovation",
        "Let's weave a story!",
        "Let's create experiences",
        "Let's unleash technomagic",
        "Let's tackle serious work",
        "Let's explore AI",
        "Let's inject some fun",
        "Let's give it a go",
        "Let's dive into experiments"
      ]

      prompts = this.shuffleArray(prompts);

      prompts2 = this.shuffleArray(prompts2);

      const maxPromptLength = prompts.reduce((max, cur) => Math.max(max, cur.length), 0)
      prompts = prompts.map(prompt => prompt + Array(maxPromptLength - prompt.length).join(' '))
      
      const maxPromptLength2 = prompts2.reduce((max, cur) => Math.max(max, cur.length), 0)
      prompts2 = prompts2.map(prompt => prompt + Array(maxPromptLength2 - prompt.length).join(' '))

      let promptCounter = 0
      let characterIndex = 0
      const prompters = gsap.utils.toArray(document.querySelectorAll('.project-prompter'))
      let interval = null
      const exchangeCharacter = () => {
        const nextPrompt = prompts[promptCounter + 1 >= prompts.length ? 0 : promptCounter + 1]
        const newPrompt = prompters[0].innerHTML.split('')
        newPrompt.splice(characterIndex, 1, nextPrompt[characterIndex])
        prompters.forEach(prompter => {
          if(prompter.innerHTML[characterIndex] === newPrompt[characterIndex]) return
          prompter.innerHTML = newPrompt.join('')
        })

        characterIndex++
        if(characterIndex >= maxPromptLength) {
          characterIndex = 0
          promptCounter++

          if(promptCounter >= prompts.length) {
            promptCounter = 0
          }

          clearInterval(interval)
          setTimeout(() => {
            interval = setInterval(exchangeCharacter, 40)
          }, 4000)
        }
      }
      
      interval = setInterval(exchangeCharacter, 40)



      let promptCounter2 = 0
      let characterIndex2 = 0
      const prompters2 = gsap.utils.toArray(document.querySelectorAll('.project-prompter2'))
      let interval2 = null

      const exchangeCharacter2 = () => {
        const nextPrompt = prompts2[promptCounter2 + 1 >= prompts2.length ? 0 : promptCounter2 + 1]
        const newPrompt = prompters2[0].innerHTML.split('')
        newPrompt.splice(characterIndex2, 1, nextPrompt[characterIndex2])
        prompters2.forEach(prompter => {
          if(prompter.innerHTML[characterIndex2] === newPrompt[characterIndex2]) return
          prompter.innerHTML = newPrompt.join('')
        })

        characterIndex2++
        if(characterIndex2 >= maxPromptLength2) {
          characterIndex2 = 0
          promptCounter2++

          if(promptCounter2 >= prompts2.length) {
            promptCounter2 = 0
          }

          clearInterval(interval2)
          setTimeout(() => {
            interval2 = setInterval(exchangeCharacter2, 40)
          }, 4000)
        }
      }
      
      interval2 = setInterval(exchangeCharacter2, 40)
    },
    validateNewsletterEmail() {
      this.$refs.emailWrapper.classList.remove('error');
      this.$refs.emailError.innerHTML = ""
      let isEmailValid = true;
      if(!this.newsletterEmail || !validator.validate(this.newsletterEmail)){
        this.$refs.emailWrapper.classList.add('error');
        this.$refs.emailError.innerHTML = "Email is incorrect"
        isEmailValid = false;
      }
      return isEmailValid;
    },
    submitNewsletterEmail() {
      if(this.validateNewsletterEmail()){
        console.log("email ", this.email, ' submitted')
      }
    },
    interpolateFontSize(sizes, viewportWidth) {
      const maxSize = sizes.find(size => size.width >= viewportWidth) || sizes[sizes.length - 1];
      const minSize = sizes.slice().reverse().find(size => size.width <= viewportWidth) || sizes[0];
      const newSize = gsap.utils.mapRange(minSize.width, maxSize.width, minSize.fontSize, maxSize.fontSize, viewportWidth);
      // console.log('resizing', viewportWidth, minSize, maxSize, newSize);
      return newSize;
    },
    setInterpolatedFontSizes() {
      let width = this.isPortfolioShrinked ? this.colLeftWidth : this.lastColLeftWidth;
      width = Math.max(width, this.isMobile ? window.innerWidth : window.innerWidth / 2);

      const bodySizes = [
        { width: 390, fontSize: 26*0.9 },
        { width: 768, fontSize: 33*0.9 },
        { width: 1024, fontSize: 41*0.9 },
        { width: 1680, fontSize: 51*0.9 },
      ]
      gsap.set('.bright-body-size', { fontSize: this.interpolateFontSize(bodySizes, width) + 'px'})

      const bodySizesForm = [
        { width: 390, fontSize: 26*0.9 },
        { width: 768, fontSize: 33*0.8 },
        { width: 1024, fontSize: 36*0.8 },
        { width: 1280, fontSize: 41*0.8 },
        { width: 1680, fontSize: 51*0.8 },
      ]
      gsap.set('.bright-body-size-form', { fontSize: this.interpolateFontSize(bodySizesForm, width) + 'px'})


      const navSizes = [
        { width: 390, fontSize: 10 },
        { width: 768, fontSize: 14 },
      ]
      gsap.set('.bright-nav-size', { fontSize: this.interpolateFontSize(navSizes, width) + 'px'})

      const logoSizes = [
        { width: 390, fontSize: 106 },
        { width: 768, fontSize: 184 },
        { width: 1024, fontSize: 244 },
        { width: 1680, fontSize: 393 },
      ]
      gsap.set('.bright-logo-size', { fontSize: this.interpolateFontSize(logoSizes, width) + 'px'})
    }
  },
  computed: {
    containerWidth() {
      var width = this.isPortfolioShrinked ? this.colLeftWidth : this.lastColLeftWidth

      if (this.isMobile) {
        width = this.windowWidth - this.mobileRightColumnSpacing
      } else if (this.windowWidth > 1200 && width < 900) {
        width = 900
      }

      return width
    },
    rwdClassList() {
      return {
        'bm-xs bm-sm bm-md bm-lg bm-xl': this.containerWidth >= 1200,
        'bm-xs bm-sm bm-md bm-lg': this.containerWidth >= 992 && this.containerWidth < 1200,
        'bm-xs bm-sm bm-md': this.containerWidth >= 768 && this.containerWidth < 992,
        'bm-xs bm-sm': this.containerWidth >= 576 && this.containerWidth < 768,
        'bm-xs': this.containerWidth < 576,
      }
    },
    rwdName() {
      if (this.containerWidth >= 1200) return 'xl'
      if (this.containerWidth >= 992) return 'lg'
      if (this.containerWidth >= 768) return 'md'
      if (this.containerWidth >= 576) return 'sm'
      if (this.containerWidth < 576) return 'xs'
    },
    brightFontSize() {

      // console.log("this.isPortfolioShrinked ", this.isPortfolioShrinked);

      var width = this.isPortfolioShrinked ? this.colLeftWidth : this.lastColLeftWidth;

      // if(width > this.leftColumnMaxWidth - 25) 
      //   width = this.leftColumnMaxWidth - 25

      // if(this.isMobile){
      //   width = this.windowWidth - 50
      // }else if(width < 900){
      //   width = 900
      // }else if(width > 1300){
      //   width = 1300
      // }

      var size = 393;

      var newsize = size * width / 1680;


      // var width = this.containerWidth <= 1024 ?  this.windowWidth - 25 : this.containerWidth 
      // width = width > 1600 ? 1600 : width

      return newsize;//width / 3.5
    },
    brightBodySize() {
      // return 51;
      // console.log("this.isPortfolioShrinked ", this.isPortfolioShrinked);

      var width = this.isPortfolioShrinked ? this.colLeftWidth : this.lastColLeftWidth;

      // if(width > this.leftColumnMaxWidth - 25) 
      //   width = this.leftColumnMaxWidth - 25

      // if(this.isMobile){
      //   width = this.windowWidth - 50
      // }else if(width < 900){
      //   width = 900
      // }else if(width > 1300){
      //   width = 1300
      // }

      // const sizes = [
      //   { width: 390, fontSize: 26 },
      //   { width: 768, fontSize: 33 },
      //   { width: 1024, fontSize: 41 },
      //   { width: 1680, fontSize: 51 },
      // ]

      // return this.interpolateFontSize(sizes, width);

      var size = 60;

      var newsize = size * width / 1680;

      // if (this.isMobile && window.innerWidth <= 390) {
      //   newsize = size * window.innerWidth / 765;
      // }

      // console.log("size ", size);
      // console.log("width ", width);
      // console.log("newsize ", newsize);

      return newsize;//width / 3.5
    },
  },
  watch: {
    colLeftWidth() {
      if(this.colLeftWidth >= window.innerWidth / 2) this.setInterpolatedFontSizes();
    },
    windowWidth() {
      this.onResize()
    },
    isPortfolioShrinked() {
      this.lockedWidth = this.colLeftWidth;
    },
    isProjectMouseOvered() {

      if (!this.isPortfolioShrinked || this.isRightColumnExpanded) return;

      const params = { duration: 0.2, ease: 'power2.out' }

      // const params2 = { duration: 0.35, ease: 'power2.out' }


      if (this.isProjectMouseOvered) {
        gsap.killTweensOf('.left-column-wrapper')
        gsap.killTweensOf('.left-column-wrapper .body-text')
        gsap.killTweensOf(['.border-bottom'])
        // gsap.killTweensOf('.team-row')

        // gsap.to('.left-column-wrapper', { backgroundColor: this.bgColor, ...params })
        // gsap.to (['.left-column-wrapper', '.left-column-wrapper .body-text'], { color: '#464332', ...params })
        // gsap.to (['.border-bottom', '.border-out-transition'], { 'borderBottomColor': '#464332',  ...params })
        // gsap.to('.team-row', { opacity: 0.9, ...params })

      } else {
        gsap.killTweensOf('.left-column-wrapper')
        gsap.killTweensOf('.left-column-wrapper .body-text')
        gsap.killTweensOf(['.border-bottom'])
        // gsap.killTweensOf('.team-row')

        // gsap.to('.left-column-wrapper', { backgroundColor: this.bgColor, ...params })
        gsap.to(['.left-column-wrapper', '.left-column-wrapper .body-text'], { color: '#000000', ...params })
        gsap.to(['.border-bottom', '.border-out-transition'], { 'borderBottomColor': '#000000', ...params })
        // gsap.to('.team-row', { opacity: 1, ...params })
      }
    }
  }
}
</script>