/* eslint-disable */
import EventEmitter from 'events';
import PageConstants from './constants/PageConstants';
import DataConstants from './constants/DataConstants';

let instance = null;

class StageStore extends EventEmitter {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }

    this.browser_width = 0;
    this.browser_height = 0;

    this.mouse = { x: 0, y: 0 };

    this.mobile = 0;

    this.state = {
      navi_opened: false,
    };

    this.mobileThreshold = 576;

    return instance;
  }

  getViewPortWidth() {
    return this.browser_width;
  }
  
  getViewPortHeight() {
    if(this.browser_width < this.mobileThreshold){
      return (this.browser_width - 25) * 16 / 9 * 0.5625;
    }else{
      return this.browser_height;
    }
  }

  getCameraViewOffsetY() {
    if(this.browser_width < this.mobileThreshold){
      return this.browser_height / - 2 + (this.browser_height / 2  );
    }else{
      return 0;
    }
  }

  getMouse(x, y) {
    return this.mouse;
  }

  setNaviState(what) {
    this.state.navi_opened = what;

    if (this.state.navi_opened) {
      this.emit(PageConstants.NAVI_OPENED, this);
    } else {
      this.emit(PageConstants.NAVI_CLOSED, this);
    }
  }
  
  getNaviState() {
    return this.state.navi_opened;
  }

  setMobile(_mobile) {
    if (_mobile !== '') {
      this.mobile = parseInt(_mobile);
    } else{
      this.mobile = 0;
    }

    this.emit(DataConstants.MOBILE_LOADED, this);
  }

  setMouse(x, y) {
    this.mouse.x = x;
    this.mouse.y = y;
  }

  setBrowserSize(w, h) {
    this.browser_width = w;
    this.browser_height = h;

    this.emit(PageConstants.STAGE_RESIZE, this);
  }
}

const stageStore = new StageStore();
export default stageStore;
