import StatsJS from 'stats.js';

require('./stats.scss');

let instance = null;

class Stats {
  constructor() {
    if (!instance) {
      this.stats = new StatsJS();
      this.guiVisible = 'block';

      document.addEventListener('keydown', this.keydown.bind(this), false);

      instance = this;
    }

    return instance;
  }

  addToStage(where) {
    this.stats.dom.style.bottom = 0;
    this.stats.dom.style.top = 'auto';

    where.appendChild(this.stats.dom);
  }

  update() {
    this.stats.update();
  }

  visible(what) {
    this.stats.domElement.style.display = what;
  }

  keydown(event) {
    const keyName = event.keyCode;

    if (keyName === 32) {
      if (this.guiVisible === 'block') {
        this.guiVisible = 'none';
      } else {
        this.guiVisible = 'block';
      }

      this.visible(this.guiVisible);
    }
  }
}

export default Stats;
