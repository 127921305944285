export default class InfinityScroller{
    constructor(el, { onClick }){
      
      this.el = el
      this.items = []
      this.targetOffsetY = 0
      this.currentOffsetY = 0

      this.scrollerEl = document.getElementById('right-column-content')
      this.scrollerItemHeight = 0

      this.isEnabled = true
      this.forceUpdate = false
      
      this.startDragY = 0
      this.transformY = 0
      this.isDragging = false

      this.mouseDownTime = 0
      this.mouseDownTimeOut = null
      this.startDragDelay = 150;
      this.speed = 0

      this.onClickCB = onClick
      
      this.init()
      
    }

    init (){

      this.el.querySelectorAll('.portfolio-item--short').forEach((item, index) => {
        var boundingRect = item.getBoundingClientRect()
        item.topPosition = boundingRect.top
        item.boundingRect = boundingRect
        item.projectID = index
        
        this.items.push(item)
      })
      
      this.onMouseUpBind = this.onMouseUp.bind(this)
      this.onMouseMoveBind = this.onMouseMove.bind(this)
      this.onTouchMoveBind = this.onTouchMove.bind(this)

      this.el.addEventListener('wheel', this.onScroll.bind(this), {passive: true})
      this.el.addEventListener('mousedown', this.onMouseDown.bind(this))
      this.el.addEventListener('touchstart', this.onMouseDown.bind(this))
      
      window.addEventListener('resize', this.onResize.bind(this))
      window.requestAnimationFrame(() => this.updateLoop())

      this.onSizeChanged()

    }

    onMouseDown(e){
      
      this.mouseDownTime = new Date()

      this.el.addEventListener('mouseup', this.onMouseUpBind)
      this.el.addEventListener('mouseleave', this.onMouseUpBind)
      this.el.addEventListener('touchcancel', this.onMouseUpBind)
      this.el.addEventListener('touchleave', this.onMouseUpBind)
      this.el.addEventListener('touchend', this.onMouseUpBind)


    
      this.el.addEventListener('mousemove', this.onMouseMoveBind)
      this.el.addEventListener('touchmove', this.onTouchMoveBind)
      
      this.isDragging = true

      const touch = e.touches ? e.touches[0] : e; 
      this.startDragY = touch.clientY;
      
      this.targetOffsetY = this.currentOffsetY
      this.speed = 0
      
      this.transformY = this.targetOffsetY;

    }

    onMouseUp(e){

      this.el.removeEventListener('mouseleave', this.onMouseUpBind)
      this.el.removeEventListener('mouseup', this.onMouseUpBind)
      this.el.removeEventListener('touchcancel', this.onMouseUpBind)
      this.el.removeEventListener('touchleave', this.onMouseUpBind)
      this.el.removeEventListener('touchend', this.onMouseUpBind)



      this.el.removeEventListener('mousemove', this.onMouseMoveBind)
      this.el.removeEventListener('touchmove', this.onTouchMoveBind)
      
      const touch = e.changedTouches ? e.changedTouches[0] : e
      
      var diff = touch.clientY - this.startDragY
      
      
      this.transformY += diff
      this.isDragging = false

      var timeDiff = new Date() - this.mouseDownTime

      this.speed = (this.targetOffsetY - this.currentOffsetY) * 0.9;
            
      if (timeDiff < this.startDragDelay && Math.abs(diff) < 10){
        
        this.speed = 0
        this.onClickCB()

      }

      console.log("SPEEED AFTER MOUSE UP: ", this.speed);

      // this.checkBoundries()
      // this.snapToNearestMember()
    }
    
    onMouseMove(e) {
      this.onDrag(e)
    }
    onTouchMove(e) {
      const touch = e.touches[0];
      this.onDrag(touch)
    }
    onDrag(e) {
      if(this.isDragging){
        var diff = e.clientY - this.startDragY
        this.targetOffsetY = this.transformY - diff
      }
    }
    
    onResize(){
      this.items.forEach((item) => {
        var boundingRect = item.getBoundingClientRect()
        item.topPosition = boundingRect.top
        item.boundingRect = boundingRect
      })
      this.scrollToProject(0)
      this.onSizeChanged()
      this.forceUpdate = true
    }

    onScroll(e){
      
      if(this.isEnabled == false)
        return 

      this.targetOffsetY += e.deltaY;
      
    }
    
    updateLoop(){
      this.update()
      window.requestAnimationFrame(() => this.updateLoop())
    }

    update(){
      
      if(this.currentOffsetY != this.targetOffsetY || this.forceUpdate || this.speed != 0){
        
        this.speed *= 0.9
        
        if(Math.abs(this.speed) < .05)
          this.speed = 0

        this.targetOffsetY += this.speed

        var deltaY = (this.targetOffsetY - this.currentOffsetY) / 4
          deltaY = Math.round(deltaY * 1000) / 1000;

        this.currentOffsetY = this.currentOffsetY + deltaY
        
        if(this.onScrollCallback)
          this.onScrollCallback({ deltaY })

        if(Math.abs(this.targetOffsetY - this.currentOffsetY) < 0.1){
          this.currentOffsetY = this.targetOffsetY
        }

        this.scrollerEl.style.transform = `translateY(${-this.currentOffsetY}px)`
        
        this.sortItems()

      }
      
      this.forceUpdate = false
    }

    sortItems (){
      var direction = this.currentOffsetY <= this.targetOffsetY ? 1 : -1;
      var swapBuffor
      
      if(direction == 1) {
        
        do {
          swapBuffor = []
          this.items.forEach((el, index) => {
            if(el.topPosition - this.currentOffsetY + el.boundingRect.height <= 0) {
              swapBuffor.push(index);
            }
          })
          
          swapBuffor.forEach(() => {
            var item = this.items.shift();
            this.items.push(item);
          })
          
          if(swapBuffor.length)
            this.onSizeChanged()

        }while(swapBuffor.length)

      }else if(direction == -1) {

        do {
          swapBuffor = []
          this.items.forEach((el, index) => {
            
            if(el.topPosition - this.currentOffsetY > window.innerHeight) {
              
              swapBuffor.push(index);
            }
          })
          
          swapBuffor.forEach(() => {
            var item = this.items.pop();
            item.topPosition = this.items[0].topPosition - item.boundingRect.height;

            this.items.unshift(item);
          })
          
          if(swapBuffor.length)
            this.onSizeChanged()

        }while(swapBuffor.length)

      }

      this.items.forEach((el) => {
        
        var top = Math.round(el.topPosition - this.currentOffsetY)
        var bottom = Math.round(el.topPosition + el.boundingRect.height - this.currentOffsetY)

        if (top <= window.innerHeight && bottom > window.innerHeight){
          this.currentProjectInViewportId = el.id;
          this.currentProjectInViewY      = el.topPosition;
        }
      })
        
    }
    
    scrollByDelta(deltaY){
      this.targetOffsetY += deltaY
    }

    scrollToProject(id){
      
      var item = this.items.find((el) => el.projectID == id)
      
      this.targetOffsetY = item.topPosition
      this.currentOffsetY = item.topPosition

      this.forceUpdate = true
      this.update()
    }
    
    onSizeChanged(){
      var height = this.items[0].topPosition;
      this.items.forEach((item) => {
        item.style.transform = `translateY(${height}px)`
        item.topPosition = height
        height += item.boundingRect.height
      })
    }

    toggleScrolling(isEnabled){
      
      this.isEnabled = isEnabled

    }

    addListener(callback){
      this.onScrollCallback = callback
    }

    setMomentum(){
      this.targetOffsetY = this.currentOffsetY;
    }
    
}