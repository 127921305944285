import gsap from 'gsap';

export default {
    methods: {
        redrawBorder(e) {
            gsap.set(e.target.querySelector('.border-redraw-transition.left'), { width: '0%', })
            gsap.set(e.target.querySelector('.border-redraw-transition.right'), { width: '100%' })
            gsap.to(e.target.querySelector('.border-redraw-transition.left'), { width: '100%', duration: 0.8, ease: 'power1.inOut' })
            gsap.to(e.target.querySelector('.border-redraw-transition.right'), { width: '0%', duration: 0.4, ease: 'power1.inOut' })
        },
    }
}