<template>
    <div class="portfolio-item portfolio-end-border bm-container-wrapper">

        <!-- SPACE FOR CARUSEL -->
        <div class="portfolio-space-for-carusel">
            <div class="scroll-to-read"> Scroll to read case study </div>
        </div>

        <div class="bm-row bm-container bm-sm bm-md bm-xs">
            <div class="bm-col-md-2 bm-col-xs-1"></div>
            <div class="bm-col-md-14 bm-col-xs-16 portfolio-item-content no-gutters">

                <ItemDescription
                    lead="Katarzyna Konkowska is a stage and exhibition designer. Her designs are influenced by abstraction and futurism, inspired by imaginary objects, nature and light. Playing with gravity, giving lightness to constructions of several tons, creating installations that seem to fly — that is what inspired us to create an interactive online installation in the heart of the site."
                    role="UI UX, Responsive design, Art direction" client="Katarzyna Konkowska" year="2014" :recognition="[
                        'Awwwards SOTD',
                        'The FWA SOTD',
                    ]" link="https://example.com" />



                <!-- <div class="row portfolio-item-description">
                <div class="ol-12 col-xl-4 offset-xl-1 col-lg-5 offset-lg-0 col-md-6 offset-md-0 col-sm-10 offset-sm-1">
                    <div class="portfolio-item-title">A portfolio website for a stage designer.</div>
                    <div class="portfolio-item-subtitle body-text color-white">Katarzyna Konkowska is a stage and exhibition designer. Her designs are influenced by abstraction and futurism, inspired by imaginary objects, nature and light. Playing with gravity, giving lightness to constructions of several tons, creating installations that seem to fly — that is what inspired us to create an interactive online installation in the heart of the site.</div>
                </div>
                <div class="col-12 col-lg-5 offset-lg-2 col-md-5 offset-md-1 col-sm-10 offset-sm-1 body-text color-white">
                    <div class="row row-table">
                        <div class="col-6 col-lg-4 color-grey"> Role</div>
                        <div class="col-6 col-lg-8">
                        <div>UI UX</div>
                        <div>Responsive design</div>
                        <div>Art direction</div>
                        </div>
                    </div>
                    
                    <div class="row row-table">
                        <div class="col-6 col-lg-4 color-grey">Year</div>
                        <div class="col-6 col-lg-8">2014</div>
                    </div>

                    <div class="row row-table">
                        <div class="col-6 col-lg-4 color-grey">Recognitions</div>
                        <div class="col-6 col-lg-8">
                            <div>Awwwards SOTD</div>
                            <div>The FWA SOTD</div>
                        </div>
                    </div>

                    <div class="row row-table">
                        <div class="col-6 col-lg-4 color-grey">Link</div>
                        <div class="col-6 col-lg-8">dreamteam.pl</div>
                    </div>
                </div>
            </div>
            
            <VideoPlayer :topPosition="topPosition" 
                :hasSound="true"
                :hasInfo="true"
                path="./assets/portfolio/dream/video/dream.mp4" 
                poster="/assets/portfolio/posters/dream.jpg"></VideoPlayer> -->

                <div class="bm-col-14 portfolio-row-margin">
                    <VideoPlayer :topPosition="topPosition" :hasSound="true" :hasInfo="true"
                        path="./assets/portfolio/dream/video/dream.mp4" poster="/assets/portfolio/posters/dream.jpg">
                    </VideoPlayer>
                </div>
            </div>
            <div class="bm-col-md-2 bm-col-xs-1"></div>
        </div>
    </div>
</template>
<script>
import VideoPlayer from '@/components/portfolio/VideoPlayer'
import ItemDescription from '@/components/portfolio/ItemDescription'
import portfolioItemMixin from '@/mixins/portfolio-item-mixin';
// import DeferredImage from '@/components/portfolio/DeferredImage'


export default {
    mixins: [portfolioItemMixin],
    components: {
        VideoPlayer,
        ItemDescription,
        // DeferredImage,
    }
}
</script>