<template>
  <div 
    ref=wrapper 
    class="portfolio-container">
    <!-- <div style="position: fixed; margin: 10px; color: green; top: 0; left: 0; z-index: 10000; pointer-events: none;">
      <div>this.currentProjectInViewportId: {{ currentProjectInViewportId }}</div>
      <div>this.currentOffsetY: {{ currentOffsetY }}</div>
      <div>this.projectsId: {{ projectsId }}</div>
      <div @click="onDebugClick(0)">0</div>
      <div @click="onDebugClick(1)">1</div>
      <div @click="onDebugClick(2)">2</div>
      <div @click="onDebugClick(3)">3</div>
      <div @click="onDebugClick(4)">4</div>
      <div @click="onDebugClick(5)">5</div>
    </div> -->
    <div ref=scroller class="portfolio-scroller" 
      :style="{visibility: expandedPortfolioVisibility ? 'visible' : 'hidden'}">
      
      <PortfolioExpandedCarusel 
        ref="carusel"
        :style="`transform: translateY(${currentProjectInViewY}px)`"
        @on-project-changed="onProjectChanged"></PortfolioExpandedCarusel>

      <div class="portfolio-expanded-item">
        <PortfolioExpandedItem 
          :ref="setItemRef"
          :key="'project-' + (index - 1)"
          :projectId="index-1"
          :isInViewPort="index-1 == currentProjectInViewportId"
          v-for="index in $store.getters.projects.length" 
          @onSizeChanged="onSizeChanged"/>
      </div>

      <div ref="caruselMask" class="projects-carusel-mask" :style="`transform: translateY(${currentProjectInViewY}px)`">
        <div ref="caruselMaskTop" class="projects-carusel-apla projects-carusel-apla--top"></div>
        <div 
          ref="caruselMaskBottom" class="projects-carusel-apla projects-carusel-apla--bottom"
          :style="{'top': `${this.portfolioCaruselHeight}px`}">
          </div>
      </div>
    </div>
    /// NOTE: Disable completely - no click on left to close. Just drag.
    <div 
      v-show="isPortfolioExpanded && !isDraging && !isMobile && false"
      ref="closePortfolio"
      class="close-portfolio" 
      @mouseover="onMouseOver(this)" 
      @mouseout="onMouseOut(this)" 
      @click="shrinkProjectAndChangeRoute">
    </div>
    
    <div 
      v-show="isRightColumnExpanded"
      class="close-portfolio-x" 
      @touchstart="e => { e.preventDefault(); shrinkProjectAndChangeRoute() }"
      @mouseover="onMouseOver(this)" 
      @mouseout="onMouseOut(this)"
      @click="shrinkProjectAndChangeRoute">
      ✕
    </div>
    
  </div>
</template>

<script>
// var json = require('@/json/portfolio.json');
import PortfolioExpandedItem from '@/views/PortfolioExpandedItem';
import PortfolioExpandedCarusel from '@/views/PortfolioExpandedCarusel';
import ProjectsStore from '@/js/ProjectsStore';
import gsap from  'gsap'
import ProjectConstants, { PortfolioTransitionsParamsFastOut } from '@/js/constants/ProjectConstants';
import portfolioMixin from '@/mixins/portfolio-mixin';
import emitter from  '@/js/emitter'
import router from '../router';
// import * as dat from 'dat.gui';

export default {
  mixins: [portfolioMixin],
  components: {
    PortfolioExpandedItem,
    PortfolioExpandedCarusel
  },
  emits: ['on-close-right-column'],
  data(){
    return {
      projectIdOffset: 0,
      lastScroll: 0,
      targetOffsetY : 0,
      currentOffsetY : 0,
      // itemInViewId: 0,
      items: [],
      portfolioItems: [],
      currentProjectInViewportId: 0,
      currentProjectInViewY: 0,
      forceUpdate: false,
      isDraging: false,
      isStarted: false,
      isClothOpened: false,
      speed: 0,
      rubberDamping: 0.25,
      inertiaDamping: 0.9,
    }
  },
  mounted(){
    this.portfolioItems.forEach((item) => {
      this.items.push(item)
    })

    this.$refs.wrapper.addEventListener('wheel', this.onScroll, {passive: true});
    this.$refs.wrapper.addEventListener('mousedown', this.onMouseDown.bind(this))
    this.$refs.wrapper.addEventListener('touchstart', this.onMouseDown.bind(this))

    window.requestAnimationFrame(this.updateLoop)
    // ProjectsStore.on(ProjectConstants.ENTER_PROJECTS, this.onEnterProjects);
    ProjectsStore.on(ProjectConstants.CLOSE_PROJECTS, this.shrinkProject);
    // ProjectsStore.on('BM_ENTER_PROJECT', this.onEnterProject);
    emitter.on("on-portfolio-project-click", this.onSelectProject)
    emitter.on("on-carusel-startdrag", this.onCaruselStartDrag)
    emitter.on("on-carusel-stopdrag", this.onCaruselStopDrag)
    emitter.on("on-carusel-drag-canceled", this.onMouseDown)

    // var gui = new dat.GUI();
    //   const scrollerFolder = gui.addFolder('Portfolio sxpanded');
    //   scrollerFolder.add(this, 'lastScroll').listen();
    //   scrollerFolder.add(this, 'targetOffsetY').listen();
    //   scrollerFolder.add(this, 'currentOffsetY').listen();
    //   scrollerFolder.add(this, 'speed').listen();
    //   scrollerFolder.add(this, 'rubberDamping', 0, 1, 0.05).listen();
    //   scrollerFolder.add(this, 'inertiaDamping', 0, 1, 0.05).listen();
    //   scrollerFolder.open();

  },
  beforeUpdate() {
    this.portfolioItems = []
  },
  methods:{

    onMouseDown(e){
      
      if(window.caruselMouseDown)
        return

      this.mouseDownTime = new Date()

      this.$refs.wrapper.addEventListener('mouseup', this.onMouseUp)
      this.$refs.wrapper.addEventListener('mouseleave', this.onMouseUp)
      this.$refs.wrapper.addEventListener('touchcancel', this.onMouseUp)
      this.$refs.wrapper.addEventListener('touchleave', this.onMouseUp)
      this.$refs.wrapper.addEventListener('touchend', this.onMouseUp)


    
      this.$refs.wrapper.addEventListener('mousemove', this.onMouseMove)
      this.$refs.wrapper.addEventListener('touchmove', this.onTouchMove)
      
      this.isDragging = true

      const touch = e.touches ? e.touches[0] : e; 
      this.startDragX = touch.clientX;
      this.startDragY = touch.clientY;

      this.targetOffsetY = this.currentOffsetY;
      this.speed = 0;
      
      this.transformY = this.targetOffsetY;

    },

    onMouseUp(e){
      
      this.$refs.wrapper.removeEventListener('mouseleave', this.onMouseUp)
      this.$refs.wrapper.removeEventListener('mouseup', this.onMouseUp)
      this.$refs.wrapper.removeEventListener('touchcancel', this.onMouseUp)
      this.$refs.wrapper.removeEventListener('touchleave', this.onMouseUp)
      this.$refs.wrapper.removeEventListener('touchend', this.onMouseUp)



      this.$refs.wrapper.removeEventListener('mousemove', this.onMouseMove)
      this.$refs.wrapper.removeEventListener('touchmove', this.onMouseMove)
      
      const touch = e.changedTouches ? e.changedTouches[0] : e
      
      var diff = touch.clientY - this.startDragY
      
      this.transformY += diff
      this.isDragging = false

      var timeDiff = new Date() - this.mouseDownTime
      
      this.speed = this.targetOffsetY - this.currentOffsetY
      
      if (timeDiff < this.startDragDelay && Math.abs(diff) < 10){
        this.speed = 0
        this.onClickCB()
      }
    },
    
    onMouseMove(e) {
      this.onDrag(e)
    },

    onTouchMove(e) {
      const touch = e.touches[0];
      this.onDrag(touch)
    },

    onDrag(e) {
      if(this.isDragging){
        var diffY = e.clientY - this.startDragY
        this.targetOffsetY = this.transformY - diffY
      }
    },
    
    onDebugClick(id) {
      this.onSelectProject({id})
    },
    setItemRef(el) {
      if (el) {
        this.portfolioItems.push(el)
      }
    },
    updateLoop(time){
      this.update(time)
      window.requestAnimationFrame(this.updateLoop)
    },
    update(time, direction = 0){
      var deltaY = this.currentOffsetY - this.targetOffsetY
      
      if(deltaY != 0 || this.forceUpdate || this.speed != 0){
        
        this.speed *= this.inertiaDamping
        
        if(Math.abs(this.speed) < .05)
          this.speed = 0

        this.targetOffsetY += this.speed
        
        this.currentOffsetY = this.currentOffsetY + (this.targetOffsetY - this.currentOffsetY) * this.rubberDamping;
        

        // this.currentOffsetY = Math.round(this.currentOffsetY * 100) / 100;
        

        this.$refs.scroller.style.transform = `translateY(${-this.currentOffsetY}px)`
        
        this.$store.dispatch('updatePortfolioScrollOffset', this.currentOffsetY)
        this.sortItems(direction)

        if (Math.abs(deltaY) < .05) {
          this.currentOffsetY = this.targetOffsetY
        }

      }

      emitter.emit("on-portfolio-scroll", {
        deltaY,
        speedY: deltaY,
        direction: deltaY < 0 ? -1 : 1,
        caruselaPositionY: this.caruselYpositionOnScreen
      })

      if (this.caruselYpositionOnScreen > this.windowHeight/4){
        
        this.isClothOpened = false;

      }else{

        this.isClothOpened = true;
      }

      this.forceUpdate = false
    },
    /**
     * Debug method for entering project programmaticaly.
     * Can be triggered by ProjectsStore.
     */
    onEnterProject (id) {
      console.log('ENTER PROJECT', id);
      this.onSelectProject({id: id, triggeredBy: 'drag'});
    },
    onEnterProjects () {
      console.log('ENTER PROJECTS');
      this.onSelectProject({id: 1, triggeredBy: 'click'});
    },
    onSelectProject ({id, triggeredBy}){
      if(triggeredBy === 'carusel' && router.currentRoute.value.path != '/') {
        const projectNames = this.$store.getters.projects.map(project => project.title.toLowerCase().replaceAll(' ', '-'))
        router.replace('/projects/' + projectNames[id]);

      }

      var item = this.items.find((el) => el.projectId == id)
      
      this.currentProjectInViewportId = id;

      console.log("ON SELECT PROJECT DIRECT 1");

      // if(this.caruselYpositionOnScreen != 0){
        this.targetOffsetY = item.topPosition - this.caruselYpositionOnScreen
        var direction = this.currentOffsetY > this.targetOffsetY ? -1 : 1
        this.currentOffsetY = item.topPosition - this.caruselYpositionOnScreen
        
        this.forceUpdate = true
        this.update(0, direction)
        this.centerCaruselInViewport ()
      // }else{
      //   this.currentOffsetY = item.topPosition
      //   this.targetOffsetY = item.topPosition
      //   console.log('%c targetoffset, currentoffset', 'color: red; background: blue', this.targetOffsetY, this.currentOffsetY)

      //   this.forceUpdate = true
      //   this.update()
      // }
      
      this.isClothOpened = true;

      console.log("ON SELECT PROJECT DIRECT 2");

      ProjectsStore.selectProjectDirect(this.currentProjectInViewportId, false, true, triggeredBy);
      
    },
    centerCaruselInViewport (duration = 0){
      gsap.killTweensOf(this)
      gsap.to (this, {
        targetOffsetY: this.currentOffsetY + this.caruselYpositionOnScreen,
        ...PortfolioTransitionsParamsFastOut,
        // ease: 'power1.inOut',
        duration: duration ? duration : PortfolioTransitionsParamsFastOut.duration / 2,
      })
    },
    onCaruselStartDrag() {
      
      this.isDraging = true;
      const y = this.currentOffsetY + this.caruselYpositionOnScreen - this.windowHeight / 2 + this.portfolioCaruselHeight / 2;
      
      if (this.caruselYpositionOnScreen != y) {

        gsap.to (this, {
          targetOffsetY: y,
          ease: 'power3.out',
          duration: 0.4,
        })
      }

    },
    onCaruselStopDrag() {

      console.log("onCaruselStopDrag");

      this.isDraging = false;
      // gsap.killTweensOf(this)
    },
    onMouseOver(ref){

      console.log("this ", this);

      if (ref.target && ref.target.getElementsByClassName("brackets")){
        gsap.to(ref.target.getElementsByClassName("brackets")[0], {duration:0.5, ease: 'power1.out', x:-10, alpha:0});
        gsap.to(ref.target.getElementsByClassName("brackets")[1], {duration:0.5, ease: 'power1.out', x:10, alpha:0});
      }
      this.$store.dispatch('toggleHome', true);
    },
    onMouseOut(){
      if (this.isPortfolioExpanded || this.isRightColumnExpanded) this.$store.dispatch('toggleHome', false);
    },
    onProjectChanged(id){
      //console.log('/// onProjectChanged', id);

      // if(id != this.currentProjectInViewportId)
        this.onSelectProject({id, triggeredBy : 'carusel'})
    },
    shrinkProject(){

      if (this.isPortfolioExpanded){
      
        ProjectsStore.updatePortfolioView();
        this.$store.dispatch('shrinkPortfolio');
        this.$store.dispatch('toggleHome', false);
      } else if(this.isRightColumnExpanded) {
        this.$emit('on-close-right-column');
      }
      
    },
    shrinkProjectAndChangeRoute() {
      this.shrinkProject();
      router.push('/');
    },  
    sortItems (direction = 0){
      
      if(direction == 0)
        direction = this.currentOffsetY > this.targetOffsetY ? -1 : 1;
        
      var swapBuffor
      var loopCount = 0
      if(direction == 1) {
        
        do {
          loopCount ++
          swapBuffor = []
          this.items.forEach((el, index) => {
            if(el.topPosition - this.currentOffsetY + el.boundingRect.height <= 0) {
              swapBuffor.push(index);
            }
          })

          

          swapBuffor.forEach(() => {
            var item = this.items.shift();
            this.items.push(item);
          })
          
          if(swapBuffor.length)
            this.onSizeChanged()

        }while(swapBuffor.length && loopCount < 20)

      }else if(direction == -1) {

        do {
          loopCount ++
          swapBuffor = []
          this.items.forEach((el, index) => {
            
            if(el.topPosition - this.currentOffsetY > window.innerHeight) {
              
              swapBuffor.push(index);
            }
          })
          
          // console.log("%c 🕑: sortItems -> swapBuffor ", "font-size:16px;background-color:#1f51dc;color:white;", direction, swapBuffor)

          swapBuffor.forEach(() => {
            var item = this.items.pop();
            item.topPosition = this.items[0].topPosition - item.boundingRect.height;

            this.items.unshift(item);
          })
          
          if(swapBuffor.length)
            this.onSizeChanged()

        }while(swapBuffor.length && loopCount < 20)

      }

      if(loopCount > 16){
        console.error("%c 🕥: PortfolioExpanded -> sortItems -> NEVER ENDING LOOP TERMINATED", "font-size:16px;background-color:#FFFFFF;color:white;", loopCount)
      }

      this.items.forEach((el) => {
        
        var top = Math.round(el.topPosition - this.currentOffsetY) - 500;
        var bottom = Math.round(el.topPosition + el.boundingRect.height - this.currentOffsetY) - 500;

        if (top <= window.innerHeight && bottom > window.innerHeight){
          if(this.currentProjectInViewportId != el.projectId){
            this.currentProjectInViewportId = el.projectId;
            ProjectsStore.selectProjectDirect(this.currentProjectInViewportId, false, true, 'scroll');
          }
          
          this.currentProjectInViewY      = el.topPosition;
        }
      })
      
    },
    onScroll(e){
      
      if(this.isPortfolioExpanded && this.isDraging == false)
        this.targetOffsetY += e.deltaY;
      
    },
    onResize(){
      
      var item = this.items.find((el) => el.projectId == this.currentProjectInViewportId)

      this.$nextTick(() =>{
        this.currentOffsetY = item.topPosition
        this.targetOffsetY = item.topPosition
        this.update()
      })
      
    },
    onSizeChanged(){
      var height = this.items[0].topPosition;
      this.items.forEach((item) => {
        item.$el.style.transform = `translateY(${height}px)`
        item.topPosition = height;
        height += item.boundingRect.height;
      })
      this.forceUpdate = true
    },
    onPortfolioWillShrink(){
      gsap.to('.portfolio-expanded-item', { ...PortfolioTransitionsParamsFastOut, opacity: 0 })
      gsap.to (this, {
        targetOffsetY: this.currentOffsetY + this.$refs.carusel.$el.getBoundingClientRect().y ,
        ...PortfolioTransitionsParamsFastOut,
      })
    },
    onPortfolioWillExpand(){
      gsap.to('.portfolio-expanded-item', { ...PortfolioTransitionsParamsFastOut, opacity: 1 })
    },
  },
  computed: {
    caruselYpositionOnScreen (){
      return this.currentProjectInViewY - this.currentOffsetY
    }
  },
  watch:{
    homeVisibility(){
      
      if (this.isPortfolioExpanded && this.homeVisibility) { 
        gsap.to(this.$refs.closePortfolio, { ease: 'power1.out', duration: 0.3, width: ProjectsStore.const.CLOSE_PROJECT_WIDTH - 30, overwrite: true })
      }else if(this.homeVisibility == false) {
        gsap.to(this.$refs.closePortfolio, { ease: 'power1.out', duration: 0.1, width: 30, overwrite: true })
      }
    },
    isClothOpened(){
      gsap.to(this.$refs.caruselMaskBottom, { duration: 0.35, ease: 'power2.out', autoAlpha: this.isClothOpened ? 0 : 1})
    },
    isDraging(){
      gsap.to(this.$refs.caruselMaskTop, { duration: 0.35, ease: 'power2.out', autoAlpha: this.isDraging ? 1 : 0})
      gsap.to(this.$refs.caruselMaskBottom, { duration: 0.35, ease: 'power2.out', autoAlpha: this.isDraging ? 1 : 0})
    },
    currentProjectInViewportId(){
      const projectNames = this.$store.getters.projects.map(project => project.title.toLowerCase().replaceAll(' ', '-'))
      router.replace('/projects/' + projectNames[this.currentProjectInViewportId]);
    },
    scrollerOffeset(){
      this.onScroll();
    },
    windowWidth(){
      this.onResize()
    },
    windowHeight(){
      this.onResize()
    },
  }
}
</script>