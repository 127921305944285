<template>
    <div class="portfolio-item portfolio-end-border bm-container-wrapper">

        <!-- SPACE FOR CARUSEL -->
        <div class="portfolio-space-for-carusel">
            <div class="scroll-to-read"> Scroll to read case study </div>
        </div>

        <div class="bm-row bm-container bm-sm bm-md bm-xs">
            <div class="bm-col-md-2 bm-col-xs-1"></div>
            <div class="bm-col-md-14 bm-col-xs-16 portfolio-item-content no-gutters">

                <ItemDescription
                    lead="Pictet is one of the largest Swiss banks and primarily offers services in wealth management, asset management and asset servicing, to private clients and institutions. Since 2018 we help them translate their visual language into a cohesive digital presence."
                    role="UI/UX, Art direction, Development, Digital Design System" client="pictet group" year="2018 - now" link="https://www.pictet.com" :recognition="[
                        'Lovie Awards Shortlist',
                    ]"/>

                <!-- <div class="ol-12 col-xl-4 offset-xl-1 col-lg-5 offset-lg-0 col-md-6 offset-md-0 col-sm-10 offset-sm-1">
                    <div class="portfolio-item-title">A digital partnership with one of the largest Swiss private banks</div>
                    <div class="portfolio-item-subtitle body-text color-white">Pictet is it is one of the largest Swiss banks and primarily offers services in wealth management, asset management and asset servicing, to private clients and institutions. Since 2018 we help them translate their visual language into a cohesive digital presence.</div>
                </div>
                <div class="col-12 col-lg-5 offset-lg-2 col-md-5 offset-md-1 col-sm-10 offset-sm-1 body-text color-white">
                    <div class="row row-table">
                        <div class="col-6 col-lg-4 color-grey"> Role</div>
                        <div class="col-6 col-lg-8">
                        <div>UI UX</div>
                        <div>Responsive design</div>
                        <div>Art direction</div>
                        </div>
                    </div>
                    
                    <div class="row row-table">
                        <div class="col-6 col-lg-4 color-grey">Year</div>
                        <div class="col-6 col-lg-8">2018 - now</div>
                    </div>

                    <div class="row row-table">
                        <div class="col-6 col-lg-4 color-grey">Recognitions</div>
                        <div class="col-6 col-lg-8">
                            <div>Awwwards SOTD</div>
                            <div>The FWA SOTD</div>
                        </div>
                    </div>
                </div> -->

                <div class="bm-col-14 portfolio-row-margin">
                    <VideoPlayer :topPosition="topPosition" :hasSound="false" :hasInfo="false"
                        path="/assets/portfolio/pictet/Video/01.mp4" poster="/assets/portfolio/pictet/Video/01.webp">
                    </VideoPlayer>
                </div>

                <!-- <div class="bm-row portfolio-images bm-col-14"> -->
                    <div class="portfolio-item--images-mobile-row odd">
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/pictet/mobile/01m.webp"
                                        src="/assets/portfolio/pictet/mobile/01.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-col-md-5 bm-col-xs-2"></div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/pictet/mobile/02m.webp"
                                        src="/assets/portfolio/pictet/mobile/02.webp" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="bm-col-md-3 bm-col-xs-7">
                            <div class="portfolio-img ">
                                <div class="img-with-ratio-mobile">
                                    <DeferredImage placeholderSrc="/assets/portfolio/pictet/mobile/03m.webp"
                                        src="/assets/portfolio/pictet/mobile/03.webp" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bm-col-11 bm-col-xs-10 bm-col-sm-8">
                        <div class="portfolio-img">
                            <div class="img-with-ratio-4-3">
                                <DeferredImage placeholderSrc="/assets/portfolio/pictet/desktop/03m.webp"
                                    src="/assets/portfolio/pictet/desktop/03.webp" alt="" />
                            </div>
                        </div>
                    </div>
                    <!-- <div class="bm-row portfolio-images bm-col-14"> -->
                        <div class="portfolio-item--images-mobile-row even">
                            <div class="bm-col-md-3 bm-col-xs-7">
                                <div class="portfolio-img">
                                    <div class="img-with-ratio-mobile">
                                        <DeferredImage placeholderSrc="/assets/portfolio/pictet/mobile/04m.webp"
                                            src="/assets/portfolio/pictet/mobile/04.webp" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="bm-d-none bm-d-xs-block bm-col-xs-2"></div>
                            <div class="bm-col-md-3 bm-col-xs-7">
                                <div class="portfolio-img">
                                    <div class="img-with-ratio-mobile">
                                        <DeferredImage placeholderSrc="/assets/portfolio/pictet/mobile/05m.webp"
                                            src="/assets/portfolio/pictet/mobile/05.webp" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="bm-col-md-5 bm-col-xs-9"></div>
                            <div class="bm-col-md-3 bm-col-xs-7">
                                <div class="portfolio-img ">
                                    <div class="img-with-ratio-mobile">
                                        <DeferredImage placeholderSrc="/assets/portfolio/pictet/mobile/06m.webp"
                                            src="/assets/portfolio/pictet/mobile/06.webp" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- </div> -->
                <!-- </div> -->

                <div class="bm-col-14 portfolio-row-margin">
                    <VideoPlayer :topPosition="topPosition" path="/assets/portfolio/pictet/Video/03.mp4"
                        poster="/assets/portfolio/pictet/Video/03.webp"></VideoPlayer>
                </div>
            </div>
            <div class="bm-col-md-2 bm-col-xs-1"></div>
        </div>
    </div>
</template>
<script>
import VideoPlayer from '@/components/portfolio/VideoPlayer'
import ItemDescription from '@/components/portfolio/ItemDescription'
import DeferredImage from '@/components/portfolio/DeferredImage'
import portfolioItemMixin from '@/mixins/portfolio-item-mixin';

export default {
    mixins: [portfolioItemMixin],
    components: {
        VideoPlayer,
        ItemDescription,
        DeferredImage,
    }
}
</script>