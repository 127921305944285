
<template>
  <div>
    <div 
      v-for="(project, i) in $store.getters.projects"
      class="portfolio-item--short intro-animation-2" 
      :ref="setItemRef"
      :key="project.title" 
      :data-id="i"
      
      @mouseover="onMouseOver(i)"
      @touchstart="onMouseOver(i)"
      @mouseout="onMouseOut(i)"
      @touchend="onMouseOut(i)">
      <div class="portfolio-item-container pointer-events-none">
        <div class="portfolio-item-image-wrapper">
          <video
            v-if="beginVideoLoading"
            class="portfolio-item--minwidth portfolio-item-image" 
            preload="metadata"
            :controls="false"
            @loadedmetadata="e => onLoadedmetadata(e, i)"
            :autoplay="true"
            :muted="true"
            :loop="true"
            :playsinline="true"
            :id="'project_img'+ i" 
            :src="isMobile ? project.cover_mobile : project.cover"
            ></video>
            <img :alt="'Still cover of project ' + i" class="portfolio-item--minwidth portfolio-item-image" :src="project.cover_still" :id="beginVideoLoading ? '' : 'project_img'+ i" :ref="setImageRef"/>
        </div>
        <div 
          class="thumb-title col flex-2 whitespace-nowrap"
          :style="{ width: `${videoWidth + 20}px` }">
          <div
            style="position: relative"
            :style="{ left: `${( Math.max((rightColumnMinWidth - windowWidth + 15), 0) )}px` }">
            {{project.title}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emitter from  '@/js/emitter'
import gsap from  'gsap'
import { PortfolioTransitionsParamsFastOut } from '@/js/constants/ProjectConstants';
import portfolioMixin from '@/mixins/portfolio-mixin';
import projectsStore from '@/js/ProjectsStore';
import ProjectsStore from '../js/ProjectsStore';
import ProjectConstants from '../js/constants/ProjectConstants';
import InfinityScroller from '@/js/InfinityScroller'
import router from '../router';

export default {
  mixins: [portfolioMixin],
  emits: ['on-all-video-loaded'],
  data(){
    return {
      selectedProject: null,
      selectedProjectBoundingRect: null,
      selectedProjectStartScrollY: 0,
      isAnimated: false,
      isMouseOver: false,
      items: [],
      videoRefs: [],
      imageRefs: [],
      scrollDidChange: false,
      scrollChangeInterval: null,
      itemTextHeight: 190,
      videoLoadCounter: 0,
      beginVideoLoading: false,
    }
  },
  beforeUpdate() {
    this.items = []
    // this.videoRefs = []
    // this.imageRefs = []
  },
  mounted(){
    window.addEventListener('mousemove', (e) => {this.mouseX = e.clientX; this.mouseY = e.clientY})

    projectsStore.imageRefs = this.imageRefs;
    this.videoRefs = ProjectsStore.projects.map(() => null)
    
    const currentRoute = router.currentRoute.value.path;
    if(currentRoute.startsWith('/projects')) {
      this.beginVideoLoading = true;
    }

    console.log("this.selectedProject 1", this.selectedProject);
  
    this.scroller = new InfinityScroller(document.querySelector(".right-column-fixed"), { onClick: () => {

      console.log("this.selectedProject 2", this.selectedProject);

      this.onClick(this.selectedProject)

    }})
    
    this.scroller.addListener(this.onScroll);
    
    emitter.on("on-left-column-scroll", ({ deltaY }) => {
      if (!this.isMobile)
        this.scroller.scrollByDelta(deltaY / 10)
    })

    this.scrollChangeInterval = setInterval(() => this.onScrollInterval (), 500);
    
    ProjectsStore.on(ProjectConstants.CARUSEL_PORTFOLIO_CHANGED, this.onProjectChanged);
    ProjectsStore.on(ProjectConstants.ENTER_PROJECTS, this.onClick);
  },
  methods:{
    onLoadedmetadata(e, id){
      
      this.videoLoadCounter ++
      
      if(id !== this.selectedProject) e.target.pause();

      this.videoRefs[id] = e.target;

      if(this.videoLoadCounter == this.$store.getters.projects.length) {

        projectsStore.videoRefs = this.videoRefs;

        this.$emit('on-all-video-loaded')
        emitter.emit('on-all-videos-loaded')
      }
    },
    onProjectChanged(store){
      
      if(this.isPortfolioExpanded == false)
        return
      
      this.selectedProject = store.selectedProject
      this.scroller.scrollToProject(this.selectedProject)
      
      this.selectedProjectBoundingRect = document.getElementById('project_img' + this.selectedProject).getBoundingClientRect()
      
      emitter.emit("on-project-boundingrect-update", { id: this.selectedProject, rect: this.selectedProjectBoundingRect })
      
    },
    centerScroll (){
      console.log("%c 🇺🇲: centerScroll -> centerScroll ", "font-size:16px;background-color:#476ed4;color:white;")
      
      this.scroller.scrollToProject(this.selectedProject)
      this.selectedProjectBoundingRect = document.getElementById('project_img' + this.selectedProject).getBoundingClientRect()
      
      const params = { id: this.selectedProject, rect: { x: this.selectedProjectBoundingRect.x, y: this.selectedProjectBoundingRect.y, left: this.selectedProjectBoundingRect.left, top: this.selectedProjectBoundingRect.top, width: this.selectedProjectBoundingRect.width, height: this.selectedProjectBoundingRect.height } }

      emitter.emit("on-project-boundingrect-update", params)

    },
    setItemRef(item){
      if (item)
        this.items.push (item);
    },
    setVideoRef(video){
      if (video)
        this.videoRefs.push (video);
    },
    setImageRef(image){
      if (image)
        this.imageRefs.push (image);
    },
    onMouseOver (id){
      this.beginVideoLoading = true;

      console.log("mouse over: ", id);

      this.$store.dispatch('setIsProjectHovered', true)
      
      if (this.isPortfolioShrinked && !this.isAnimated){
        this.isMouseOver = true;
        this.selectedProject = parseInt(id);

        this.selectedProjectBoundingRect = document.getElementById('project_img' + id).getBoundingClientRect()

        emitter.emit("on-portfolio-project-mouseover", { id: parseInt(id), rect: this.selectedProjectBoundingRect })
        // this.$store.dispatch('setIsProjectMouseOvered', true)
      }

    },
    onMouseOut (id){

      if (this.isPortfolioShrinked && !this.isAnimated){
        
        this.isMouseOver = false;
        this.$store.dispatch('setIsProjectMouseOvered', false)
        this.$store.dispatch('setIsProjectHovered', false)

        
        emitter.emit("on-portfolio-project-mouseout", { id: parseInt(id) })
      }
    },
    onClick(id = null, trigger = 'click') {
      if (this.isPortfolioShrinked && !this.isAnimated) {
        this.beginVideoLoading = true;

        this.scroller.setMomentum(0, 0);
        const midScreen = this.$store.getters.windowHeight/2;
        if (id === null) {
          
          const items = [...document.querySelectorAll('.portfolio-item--short')];
          const closest = items.reduce((aggr, item, idx) => {
            const rect = item.getBoundingClientRect();
            const mid = rect.top + rect.height / 2;
            const dist = Math.abs(mid - midScreen);
            if (aggr.dist === null || aggr.dist > dist) {
              aggr.dist = dist;
              aggr.idx = idx;
              aggr.rect = rect;
            }
            return aggr;
          }, {dist: null, idx: null, rect: null});
          console.log("CLOSEST", closest);
          id = closest.idx; 
        } 

        console.log('project_img' + parseInt(id));

        this.selectedProjectBoundingRect = document.getElementById('project_img' + parseInt(id)).getBoundingClientRect()
        
        if (trigger == 'click'){
          emitter.emit("on-portfolio-project-click", { id: parseInt(id), rect: this.selectedProjectBoundingRect, triggeredBy: 'click' })
        }else{
          emitter.emit("on-portfolio-project-click", { id: parseInt(id), rect: this.selectedProjectBoundingRect, triggeredBy: 'drag' })
        }
    
        this.$store.dispatch('setIsProjectMouseOvered', true)
        
        this.isMouseOver = false;
        this.selectedProject = id;
        this.listTransitionOut (trigger);

        document.getElementById('project_img' + parseInt(id)).style.opacity = 1;

        if(['/'].includes(router.currentRoute.value.path)) {
          const projectNames = this.$store.getters.projects.map(project => project.title.toLowerCase().replaceAll(' ', '-'))
          router.push('/projects/' + projectNames[id]);
        }
      }
    },
    onScroll({deltaY}){
      emitter.emit("on-right-column-scroll", { deltaY })
    },
    listTransitionOut(trigger){

      console.log("trigger 1:", trigger);

      this.isAnimated = true
      const tl = gsap.timeline({onComplete: () => {
        this.$store.dispatch('expandPortfolio')
        this.isAnimated = false
        const params = {
          id: this.selectedProject, 
          rect: document.getElementById('project_img' + this.selectedProject).getBoundingClientRect(),
          triggeredBy: trigger
        }

        console.log("params: ", params);

        emitter.emit("on-portfolio-expand-project", params)
      }});
      
      this.items.forEach((el, index) => {
        gsap.killTweensOf(el)
        if (index != this.selectedProject)
          tl.to (el, {
            duration : .25, 
            //y: window.innerHeight * ((index < this.selectedProject) ? -1 : 1), 
            autoAlpha: 0,
            ease: 'power3.in'
            }, 'sync')
        else {
          tl.to (el, {
            duration : .25, 
            ease: 'power3.in', 
            autoAlpha: 0,
          }, 'sync')
        }
      })
    },
    onPortfolioWillExpand () {
      this.scroller.toggleScrolling(false);
    },
    onPortfolioExpanded(){
      this.centerScroll()
      this.$store.dispatch('setIsProjectHovered', false)
    },
    onPortfolioWillShrink () {
      
    },
    onPortfolioShrinked () {
      
      this.isAnimated = true

      var tl = gsap.timeline({ onComplete: () => {

        this.isAnimated = false
        this.$store.dispatch('setIsProjectMouseOvered', false)
        this.scroller.toggleScrolling(true)

        this.onScrollInterval(true)
        this.videoRefs.forEach(video => video.pause())
      } })

      if(this.isMobile){
        this.videoRefs[this.selectedProject].pause()
      }  
      this.items.forEach((el, index) => {
        if(index != this.selectedProject){
          tl.to (el, { ...PortfolioTransitionsParamsFastOut, autoAlpha: 1 }, 'sync')
        }else{
          tl.to (el, { ...PortfolioTransitionsParamsFastOut, autoAlpha: 1 }, 'sync')
        }
      })
    },
    onScrollInterval(force = false){
      
      if (this.scrollDidChange || force) {
        
        this.scrollDidChange = false;

        if (this.isPortfolioShrinked && this.isMouseOver || force && this.isMouseOver){

          const target = document.elementFromPoint(this.mouseX, this.mouseY)
          const closest = target && target.closest('.portfolio-item--short')
          const closestId = closest && closest.getAttribute('data-id')
          
          if (closestId != this.selectedProject){
            this.onMouseOut(this.selectedProject)
            this.onMouseOver(closestId)
          }

        }
      }
    },
    isMouseOverVideo(){
      const target = document.elementFromPoint(this.mouseX, this.mouseY)
      const closest = target && target.closest('.portfolio-item--short')
      const closestId = closest && closest.getAttribute('data-id')
      const result = closestId == this.selectedProject ? true : false;
      
      return result
    },
  },
  computed:{
    videoHeight() { return  this.videoRatio * 0.6 * 900 },
    videoWidth() { return  this.videoRatio * 0.6 * 1600 },
    videoRatio() { return this.$store.getters.windowHeight / 900},
    isProjectDisabled () { return this.isProjectMouseOvered }
  },
  watch: {
    isProjectDisabled(){
      
      const params = { duration: 0.35, ease: 'power2.out' }

      this.items.forEach((item, index) => {
        
        const elVideo = item.querySelector('.portfolio-item-image-wrapper')
        const elTitle = item.querySelector('.thumb-title')

        gsap.killTweensOf(elVideo)
        gsap.killTweensOf(elTitle)
        
        if(index != this.selectedProject){
          gsap.to([elVideo, elTitle], { ...params, opacity: this.isProjectDisabled ? 0.4 : 1})
        }else{
          gsap.to([elVideo, elTitle], { ...params, opacity: 1})
        }
      })
      
    }
  }
}
</script>