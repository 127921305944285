



export default class InertialDragger2 {


    constructor (el = null, options) {

        this.reset();
        this.plugins = options.plugins || [];
        this.postDragInertiaType = ['lastSpeed', 'rubber'].includes(options.inertia) ? options.inertia : 'rubber';
        
        el && this.setActiveElement(el);
        
    }

    reset () {
        this.startDrag = {x: 0 , y: 0 }; // start mouse pos 
        this.lastDrag = {x: 0 , y: 0 }; // last frame mouse pos
        this.currentDrag = {x: 0 , y: 0 }; // current mouse pos
        
        this.currentSpeed = {x: 0 , y: 0 }; // diff currentDrag - lastDrag
        this.currentDragOffset = {x: 0 , y: 0 }; // diff currentDrag - startDrag
        this.currentPositionOffset = {x: 0 , y: 0 }; // pointer following drag offset 
        this.speedDamping = 0.25; // speed damping during drag (rubber)
        this.speedMultiplier = 1.2; // speed multiplier on end of drag when postDragType == 'lastSpeed'
        this.inertiaDamping = {x: 0.9, y: 0.9 }; // speed damping after drag (inertia)
        this.sleeps = true; // no running update loop
        this.isDragging = false; // mouse is dragging
        
    }   

    setActiveElement (el) {
        console.log(el);
        this.el = el;
        this.enable();
    }

    enable () {
        this.el.addEventListener('mousedown', this.onMouseDown);
    }

    disable () {
        this.el.removeEventListener('mousedown', this.onMouseDown);
    }

    onMouseDown = (e) => {
        this.startTracking(e, e.clientX, e.clientY);
    }

    onMouseMove = (e) => {
        this.updateTracking(e, e.clientX, e.clientY);
    }

    onMouseUp = (_) => {
        this.stopTracking();
    }
 
    startTracking (_, ex, ey) {
        
        this.isDragging = true;
        this.startDrag.x = this.currentDrag.x = this.lastDrag.x = ex;
        this.startDrag.y = this.currentDrag.y = this.lastDrag.y = ey;
        this.currentDragOffset.x = 0; this.currentDragOffset.y = 0;
        this.currentPositionOffset.x = 0; this.currentPositionOffset.y = 0;

        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('mouseup', this.onMouseUp);
        if (this.sleeps) {
            this.sleeps = false;
            window.requestAnimationFrame(this.updateLoop);
        }
        this.plugins.forEach(plugin => {
            plugin.onDragStart && plugin.onDragStart(this);
        });
    }

    updateTracking (_, ex, ey) {
        this.currentDrag.x = ex;
        this.currentDrag.y = ey;
    }

    stopTracking () {
        // this.sleeps = true; /// TODO: Change to another.
        this.isDragging = false;
        if (this.postDragInertiaType == 'rubber') {
            this.currentSpeed.x = this.currentDragOffset.x - this.currentPositionOffset.x;
            this.currentSpeed.y = this.currentDragOffset.y - this.currentPositionOffset.y;
        } else if (this.postDragInertiaType == 'lastSpeed') {
            this.currentSpeed.x *= this.speedMultiplier;
            this.currentSpeed.y *= this.speedMultiplier;
        }
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);
        console.log("STOP DRAG", this.sleeps, this.currentSpeed.y, this.lastDrag.y, this.currentDrag.y);
        this.plugins.forEach(plugin => {
            plugin.onDragEnd && plugin.onDragEnd(this);
        });
    }

    updateLoop = () => {
        
        if (this.isDragging) {
            this.currentSpeed.x = (this.currentDrag.x - this.lastDrag.x);
            this.currentSpeed.y = (this.currentDrag.y - this.lastDrag.y);
            this.lastDrag.x = this.currentDrag.x;
            this.lastDrag.y = this.currentDrag.y;
            this.currentDragOffset.x = this.currentDrag.x - this.startDrag.x; 
            this.currentDragOffset.y = this.currentDrag.y - this.startDrag.y;

            this.currentPositionOffset.x += (this.currentDragOffset.x - this.currentPositionOffset.x) * this.speedDamping;
            this.currentPositionOffset.y += (this.currentDragOffset.y - this.currentPositionOffset.y) * this.speedDamping;
            // console.log('DRAGS', this.currentSpeed.y);
        } else {
            if (!this.sleeps) {
                this.currentSpeed.x *= this.inertiaDamping.x;
                this.currentSpeed.y *= this.inertiaDamping.y;
                this.currentPositionOffset.x += this.currentSpeed.x;
                this.currentPositionOffset.y += this.currentSpeed.y;
            }
            if (Math.abs(this.currentSpeed.x) < 0.1 &&  Math.abs(this.currentSpeed.y) < 0.1) {
                this.sleeps = true;
                console.log("SLEEEEEPS");
                this.currentSpeed.x = 0;
                this.currentSpeed.y = 0;
            }
        }
        
        this.plugins.forEach(plugin => {
            plugin.update && plugin.update(this);
        });
        
        // console.log(this.currentDrag.x, this.currentDrag.y, this.currentSpeed.x, this.currentSpeed.y);

        if (!this.sleeps) {
            window.requestAnimationFrame(this.updateLoop);
        }
    }



}