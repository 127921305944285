const ShiftShader = {
    uniforms: {
        "tDiffuse": { type: "t", value: null },
        "time": { type: "f", value: 0.0 },
        "displacement": { type: "f", value: 0.0 },
        "rgbshift": { type: "f", value: 0.0 },
    },

    vertexShader: [
        "varying vec2 vUv;",
        "void main() {",
        "vUv = uv;",
        "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
        "}"

    ].join("\n"),

    fragmentShader: `
        precision mediump float;

        uniform sampler2D tDiffuse;
        uniform float time;
        
        varying vec2 vUv;
        
        uniform float displacement;
        uniform float rgbshift;
        
        float random(vec2 st){
            return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
        }
        
        float noise(vec2 st){
            vec2 i = floor(st);
            vec2 f = fract(st);
            
            float a = random(i);
            float b = random(i + vec2(1.0, 0.0));
            float c = random(i + vec2(0.0, 1.0));
            float d = random(i + vec2(1.0, 1.0));
            
            vec2 u = f * f * (3.0 - 2.0 * f);
            
            return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
        }

        void main() {
            vec2 uv = vUv;
            float scale = 10.;
            float displacement2 = (noise(uv * scale + time) * 2. - 1.) * displacement;
            vec2 displacedUV = uv + vec2(displacement2, displacement2);
            float g = texture2D(tDiffuse, displacedUV + vec2(noise(displacedUV * scale + time) * rgbshift, 0.0)).g;
            float b = texture2D(tDiffuse, displacedUV).b;
            float r = texture2D(tDiffuse, displacedUV - vec2(noise(displacedUV * scale + time) * rgbshift, 0.0)).r;
            gl_FragColor = vec4(r, g, b, 1.0);
        }
    `

};

export { ShiftShader }