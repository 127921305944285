/* eslint-disable */
import * as THREE from 'three';

class Particle {
  constructor(x, y, z, mass, clothInitialPosition) {
    this.position = clothInitialPosition( x, y ); // position
    this.previous = clothInitialPosition( x, y ); // previous
    this.original = clothInitialPosition( x, y ); // original

    this.a = new THREE.Vector3(0, 0, 0); // acceleration
    this.mass = mass;
    this.invMass = 1 / mass;
    this.tmp = new THREE.Vector3();
    this.tmp2 = new THREE.Vector3();

    this.DAMPING = 0.1;
    this.DRAG = 1 - this.DAMPING;
  }

  lockToOriginal() {
    this.position.copy(this.original);
    this.previous.copy(this.original);
  }

  lock() {
    this.position.copy(this.previous);
    this.previous.copy(this.previous);
  }

  addForce(force) {
    this.a.add(this.tmp2.copy(force).multiplyScalar(this.invMass) );
  }

  integrate(timesq) {
    const newPos = this.tmp.subVectors(this.position, this.previous);
    newPos.multiplyScalar(this.DRAG).add(this.position);
    newPos.add(this.a.multiplyScalar(timesq));

    this.tmp = this.previous;
    this.previous = this.position;
    this.position = newPos;

    this.a.set(0, 0, 0);
  }
}

export default Particle;
