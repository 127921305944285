
<template>
  <component
    ref="itemWrapper" 
    v-if="component"
    :topPosition="topPosition"
    :is="component" 
    :isInViewPort="isInViewPort"/>
</template>

<script>

import Fashion from '@/templates/Fashion'
import Design from '@/templates/Design'
import Hotel from '@/templates/Hotel'
import Pictet from '@/templates/Pictet'
import Multimedia from '@/templates/Multimedia'
import Dream from '@/templates/Dream'
import Warsaw from '@/templates/Warsaw'
import Soir from '@/templates/Soir'
import Prix from '@/templates/Prix'
import portfolioMixin from '@/mixins/portfolio-mixin';

export default {
  mixins: [portfolioMixin],
  components:{
    Fashion,
    Design,
    Hotel,
    Multimedia,
    Dream,
    Warsaw,
    Pictet,
    Soir,
    Prix,
  },
  data(){
    return {
      mouseDown: false,
      boundingRect: { y: 0, height: 0 },
      topPosition: 0,
      resizeTimeOut: null
    }
  },
  emits: ['onSizeChanged'],
  props:{
    projectId: Number,
    isInViewPort: Boolean
  },
  mounted(){
    this.$nextTick(() => this.meassureComponent())
  },
  methods:{
    meassureComponent(){
      this.boundingRect = this.$refs.itemWrapper.$el.getBoundingClientRect();
      this.$emit('onSizeChanged', this.boundingRect, this.projectId);
    },
    onResize (){
      this.meassureComponent()
      // clearTimeout(this.resizeTimeOut)
      // this.resizeTimeOut = setTimeout(() => this.meassureComponent(), 300)
    }
  },
  computed: {
    component (){
      return this.$store.getters.projects[this.projectId].vue_component    
    }
  },
  watch: {
    windowWidth(){
      this.onResize()
    },
    windowHeight(){
      this.onResize()
    }
  }
}
</script>