
import EventEmitter from 'events';
import DataConstants from './constants/DataConstants';
import ProjectConstants from './constants/ProjectConstants';

let instance = null;

class ProjectsStore extends EventEmitter {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }

    this.projects = [];
    this.selectedProject = -1;
    this.clothPoints = [];
    this.project_opened = false;

    this.cameraRotation = 0;

    this.clearDirection = 0;

    this.videoRefs = null;
    this.imageRefs = null;
    this.camera = null;
    this.scene = null;
    this.renderToCanvas = false;
    this.const = {
      CLOSE_PROJECT_WIDTH: 100,
    };
    window.bm_enterProject = (id) => {
      this.emit(ProjectConstants.ENTER_PROJECTS, id);
    } 

    return instance;
  }

  setProjects(projects_) {
    this.projects = projects_;
    this.emit(DataConstants.PROJECTS_LOADED, this);
  }

  setClothPoints(clothPoints_) {
    this.clothPoints = clothPoints_;
    this.emit(DataConstants.CLOTH_LOADED, this);
  }

  setCameraRotation(rotation_) {
    this.cameraRotation = rotation_;

    this.emit(ProjectConstants.CAMERA_ROTATE, this);
  }
  
  closeProject() {
    this.project_opened = false;
    this.emit(ProjectConstants.PROJECT_CLOSE, this);
  }

  startCameraRotation() {
    this.emit(ProjectConstants.CAMERA_ROTATE_START, this);
  }
  
  showNextProject(){
    console.log("%c 🔰: ProjectsStore -> showNextProject -> showNextProject ", "font-size:16px;background-color:#537e16;color:white;")
    this.emit('show-next-project', this);
  }
  showInfo(){
    console.log("%c 🔰: ProjectsStore -> showNextProject -> showNextProject ", "font-size:16px;background-color:#537e16;color:white;")
    this.emit('show-info', this);
  }
  /**
   * Lets enter selected projects programmatically:
   * @param {*} id - id of the project. If no id given the ProjectsList should select the project closest to mid-Y.
   */
  openProjects(id = null) {

    // 'drag' removes zoom out on background
    this.emit(ProjectConstants.ENTER_PROJECTS, id, 'drag');
  }
  
  closeProjects() {
    this.emit(ProjectConstants.CLOSE_PROJECTS);
  }

  selectProjectDirect(projectID, updateCloth, updatePortfolioView = false, triggeredBy) {
    
    console.log("%c 🤷‍♂️: ProjectsStore -> selectProjectDirect -> projectID ", "font-size:16px;background-color:#f1167e;color:white;", projectID, triggeredBy)
    
    if(this.selectedProject == projectID && triggeredBy != "click")
      return
    
    // console.log("%c 🇸🇿: ProjectsStore -> selectProjectDirect -> projectID ", "font-size:16px;background-color:#ca34bc;color:white;", projectID)
    this.selectedProject = projectID;
    this.emit(ProjectConstants.UPDATE_SCROLLER_PROJECT, projectID, triggeredBy);
    
    if(updatePortfolioView == false)
      this.updatePortfolioView()
    
    if (updateCloth) this.emit(ProjectConstants.SELECT_PROJECT, this);
  }

  selectProject(direction, projectID) {
    this.clearDirection = direction;
    this.selectedProject = projectID;
    this.emit(ProjectConstants.SELECT_PROJECT, this);
    this.emit(ProjectConstants.CARUSEL_PORTFOLIO_CHANGED, this);
  }

  selectProjectNoCloth(direction, projectID) {
    this.clearDirection = direction;
    this.selectedProject = projectID;
    //this.emit(ProjectConstants.SELECT_PROJECT_NOCLOTH, this);
  }

  updatePortfolioView(){
    this.emit(ProjectConstants.CARUSEL_PORTFOLIO_CHANGED, this);
  }

  getCameraRotationn() {
    return this.cameraRotation;
  }

  getClearDirection() {
    return this.clearDirection;
  }

  checkOpenedProject() {
    return this.project_opened;
  }

  getSelectedProject() {
    return this.selectedProject;
  }

  getProject() {
    return this.openedProject;
  }

  getProjects() {
    return this.projects;
  }

  getCloth() {
    return this.clothPoints;
  }
}

const projectsStore = new ProjectsStore();
export default projectsStore;
