/* eslint-disable */
// console.log('The page.js module has loaded! See the network tab in dev tools...');


import _ from 'lodash';
// import Stats from './utils/Stats';

import MainPage from './pages/MainPage';
import StageController from './StageController';
import PageBackground from './canvas/PageBackground';
import * as BrightActions from './actions/BrightActions';
import DataConstants from './constants/DataConstants';

import projectsStore from './ProjectsStore';
import stageStore from './StageStore';
// import MainNavigation from './navi/MainNavigation';
import ProjectsPage from './pages/ProjectsPage';
// import gsap from 'gsap';

require('./assets.js');

if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!');
}

class AppBootstrap {
  constructor(vue) {

    console.log("CONSTRUCTOR");
  
    // STAGE CONTROLLER //
    this.stage_controller = new StageController();
    this.vue = vue

    console.log("CONSTRUCTOR");

    // LISTENERS //
    projectsStore.on(DataConstants.PROJECTS_LOADED, this.projectsLoaded.bind(this));
    projectsStore.on(DataConstants.CLOTH_LOADED, this.clothLoaded.bind(this));

    stageStore.on(DataConstants.MOBILE_LOADED, this.mobileLoaded.bind(this));

    // gsap.ticker.add(this.update.bind(this));

    BrightActions.getMobile();
  }

  // update(){

  //   console.log("UPDATE IT");

  //   this.stats.update();

  // }

  mobileLoaded() {

    // PAGES //
    this.main_page = new MainPage();

    // ACTION //
    BrightActions.getCloth();
  }

  clothLoaded() {
    // ACTION //
    BrightActions.getProjects();
  }

  projectsLoaded() {
    console.log("%c 📶: AppBootstrap -> projectsLoaded -> projectsLoaded ", "font-size:16px;background-color:#b0201f;color:white;")
    
    //this.stage_controller.init();
    // BACKGROUND //
    this.background = new PageBackground(this.vue);
    this.background.init();

    // MAIN NAVI //
    // this.navi = new MainNavigation();

    // PAGES //
    this.projects = new ProjectsPage();

    // INIT WEBSITE //
    this.stage_controller.init();
    this.projects.init();
  }
}

export default AppBootstrap;
