import StageStore from './StageStore';

let instance = null;

class StageController {
  constructor() {
    if (!instance) {
      instance = this;
    }

    this.browser_width = 0;
    this.browser_height = 0;

    return instance;
  }

  init() {
    window.addEventListener('resize', this.resizeStage.bind(this), false);
    window.addEventListener('orientationchange', this.resizeStage.bind(this), false);
    window.addEventListener('mousemove', this.mouseMove.bind(this), false);

    this.resizeStage();
  }

  mouseMove(e) {
    let pageX = e.pageX;
    let pageY = e.pageY;
    if (pageX === undefined) {
      pageX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
      pageY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }

    StageStore.setMouse(pageX, pageY);
  }

  resizeStage() {
    this.browser_width = window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

    this.browser_height = window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

    StageStore.setBrowserSize(this.browser_width, this.browser_height);
  }
}

export default StageController;
