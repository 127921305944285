/* eslint-disable */
// var ModernizrWebpackPlugin = require('modernizr-webpack-plugin');
import gsap from 'gsap';

import StageStore from './../StageStore';
import ProjectsStore from '../ProjectsStore';
import ProjectConstants from './../constants/ProjectConstants';
// import Stats from './../utils/Stats';
import emitter from '@/js/emitter';

let scrollers = [];
window.bm_dumpScrollers = () => {
      
  return {
    msg: "DUMP SCROLLERS", 
    scrollers: scrollers,
  }
};
class Scroller {
  constructor() {
    this.scroller = null;
    this.offsetX;
    this.offsetY;
    this.targetX = 0;
    this.targetY = 0;
    this.targetYTmp = 0;

    this.onClickGoTo = false;
    this.mouseDown = false;
    this.prevMouseX = 0;
    this.prevMouseY = 0;
    this.lastX;
    this.lastY;
    this._prevViewportX;
    this._prevViewportY;
    this._roundedViewportX = 0;
    this._roundedViewportY = 0;
    this._roundedViewportYMotion = 0;
    this._moveThreshold = 10;
    this.targetYMotion = 0;
    this._viewPortX = 0;
    this._viewPortY = 0.00000000000003;
    this._viewPortYMotion = 0;
    this.curVelX = 0;
    this.curVelY = 0;
    this.curInertiaX = 0;
    this.curInertiaY = 0;
    this._prevMotion = 0;
    this._direction = 0;
    this._directionY;
    this.processInertiaX = false;
    this.processInertiaY = false;
    this.viewPortMaxX = 0;
    this.viewPortMaxY = 0;
    this.viewPortMinX = 0;
    this.lastMotion;
    this.lastClick;
    this.didDrag = false;
    this.scrollDirection = 'x';
    this.eventType = 'mouse';
    this.selectorType = 'LI';
    this.clickElement;
    // this._supportsTransforms = ModernizrWebpackPlugin.csstransforms3d;
    this.items = [];
    this.viewPortMin2Y;
    this.scrollClicked;
    this.naviMainItems;
    this.closerID_old = 0;
    this.browser_center = StageStore.getViewPortWidth() / 2;
    this.projectID = 0;
    this.stopDrawing = false;
    this.that;
    this.trackingEnabled = false;
    this.mySplitChapterTitle;
    this.tlClose;
    this.immediateUpdate = false;

    this.lastItem;
    this.firstItem;

    this.countIt = 2;

    this.navi_rotation = 0;
    this.navi_rotationTmp = 0;

    this.rotation_speed = 20;

    this.itemsDistance = 50;
    this.lastSpeed = 0

    this.carouselShrinked = true;
    this.portfolioOpened = false;
    // this.stats = new Stats();


    scrollers.push(this);


  }

  static addEvent(el, type, handler) {
    el.addEventListener(type, handler);
  }

  static removeEvent(el, type, handler) {
    el.removeEventListener(type, handler);
  }

  init(ele, useTouch) {
    this.that = this;

    this.browser_width = StageStore.getViewPortWidth();
    this.browser_height = StageStore.getViewPortHeight();
    this.browser_center = StageStore.getViewPortWidth() / 2;

    this.prevMouseX = this.browser_width / 2;
    this.prevMouseY = this.browser_height / 2;

    this.that = this;

    this.scroller = ele;

    // window.bm_dumpScroll = () => {
      
    //   return {
    //     msg: "DUMP SCROLL", 
    //     mouseDown: this.mouseDown,

    //   }
    // };

    this.scrollDirection = 'x';
    this.eventType = useTouch ? 'touch' : 'mouse';

    this.viewPortMinX = 0;
    this.viewPortMaxY = this.scroller.clientHeight;
    this.viewPortMin2Y = 0;
    this.viewPortMaxX = this.scroller.clientWidth;

    this.naviMainItems = document.querySelectorAll('.projects-item-navi');
    let speedT = 0.1;
    let positionX = 0;

    this.firstItem = this.naviMainItems[0];
    this.lastItem = this.naviMainItems[this.naviMainItems.lenght - 1];

    this.naviMainItems.forEach((value, index) => {
      // console.log(value);

      const rect = value.getBoundingClientRect();

      // console.log(rect.width);

      value.style.left = `${positionX}px`;

      value.setAttribute('data-id', index);
      // console.log(value.style);

      this.items.push({
        ref: value,
        refObj: value.getElementsByClassName('text-navi')[0],
        refObjS: value.getElementsByClassName('text-navi-s')[0],
        width: rect.width,
        height: rect.height,
        speed: speedT,
        viewPortX: 0,
        rotationTmp: 0,
        rotation: 0,
        direction: index % 2 ? -1 : 1,
        position: positionX,
        projectId: index,
      });

      positionX += rect.width + this.itemsDistance;

      speedT += 0.01;
    });

    this.addEventHandlers(this.eventType);
    ProjectsStore.on(ProjectConstants.UPDATE_SCROLLER_PROJECT, this.showProjectWithId.bind(this));
    
    emitter.on("on-portfolio-scroll", this.onPortfolioScrollChange.bind(this))

    emitter.on("on-portfolio-expand-project", this.onProjectWillExpand.bind(this))
    emitter.on("on-portfolio-shrink-project", this.onPortfolioShrink.bind(this))
    
    // ProjectsStore.selectProjectDirect(0, true, true);
    // ProjectsStore.on('show-next-project', this.showNextProject.bind(this));
    // ProjectsStore.on('show-info', this.showInfo.bind(this));
    // this.setElementTransform(this.scroller, '1px', '0px');
  }

  onProjectWillExpand(){
    this.portfolioOpened = true;
    
    this.carouselShrinked = false;

    this.shrinkCarouselItems();
  }

  onPortfolioShrink(){
    this.portfolioOpened = false;
    //this.carouselShrinked = true;

    this.shrinkCarouselItems();
  }

  onPortfolioScrollChange({ deltaY, direction, caruselaPositionY }){
    //return;
    
    // console.log("%c 💐: PageBackground -> onPortfolioScrollChange -> deltaY, direction, caruselaPositionY ", "font-size:16px;background-color:#8f92d0;color:white;", deltaY, direction, caruselaPositionY)
    // if (this.projectInTransition) return;

    //if (direction <= 0){

    //return;

    //console.log('caruselaPositionY: ', caruselaPositionY);

    if (this.didDrag || this.portfolioOpened == false) return;

      if (caruselaPositionY > StageStore.browser_height/4){

        // if (this.savedTarget == null){

        //   console.log("!! this.savedTarget", this.targetX)
        //   this.savedTarget = this.targetX;
          
        // }

        //if (this.savedTarget) {
        
          //console.log("this.targetX: ", this.targetX, caruselaPositionY);
  
          //console.log("caruselaPositionY", caruselaPositionY);

          // this.targetX = this.savedTarget - (caruselaPositionY - this.browser_height/4);
        
          // if (direction > 0){

            this.shrinkCarouselItems();
          // }
        //}

        //this.targetX = this.savedTarget - caruselaPositionY;

        // if (this.openedBox){
        //   this.rotateCameraStart(false);
        // }

        // ProjectsStore.setCameraRotation(-deltaY / 300);

      }else{

        // console.log(" OTWIERAMY !!", caruselaPositionY, this.carouselShrinked);

        if (caruselaPositionY <= StageStore.browser_height/4 && caruselaPositionY >= 0 && this.carouselShrinked) {
          // this.clickElement = null;
          // this.mouseDown = false;
          // this.didDrag = false;

          var closerId = this.projectID;

          var item = this.items.find((el, index) => {
            if(el.projectId == this.projectID){
              closerId = index
              return true
            }else 
              return false
          });

          this.centerCarouselItem(closerId, item);
          //this.OnMouseUp(null);

          // console.log("SHOW PROJECT FULL")
          // this.clickElement = null;
          // this.mouseDown = false;
          
          // this.didDrag = false;

          //this.OnMouseUp();
        }
        //this.savedTarget = null;

        // if (direction <= 0){
        //   //if (!this.openedBox) this.selectProject();

        //   // this.mouseDown = false;
        // }
      }

      // if (caruselaPositionY > 150){

      // }

      // if (this.savedTarget) {
        
      //   console.log("this.targetX: ", this.targetX, caruselaPositionY);

      //   this.targetX = this.savedTarget - (caruselaPositionY - 150);
      
      // }
    //}
  }

  openNavi(delay_) {
    // this.didDrag = true;
    
    this.scroller.focus();
    this.setSelectedProject(0)
    this.update()
    
    Scroller.addEvent(this.scroller, this.dragOverEvt,    this.dragOverCB);
    Scroller.addEvent(this.scroller, this.dragStopEvt,    this.dragStopCB);
    Scroller.addEvent(this.scroller, this.dragStartEvt,   this.dragStartCB);
    Scroller.addEvent(this.scroller, this.dragStopEvt2,   this.dragStopCB2);
    Scroller.addEvent(this.scroller, this.dragStartEvt2,  this.dragStartCB2);

    // this.immediateUpdate = true;
    gsap.ticker.add(this.update.bind(this));


    // ProjectsStore.selectProject(1, this.projectID);
    // ProjectsStore.updatePortfolioView(this.projectID);
    
  }

  /* changeOrientation(isPortrait) {
    // if(isPortrait){
    // scrollDirection = 'y';
    // this.scroller.style.width = '640px';
    // this.scroller.style.height = '8200px';
    // this.scroller.className = this.scroller.className.replace(new RegExp('(^|\\s+)' + 'vCenter' + '(\\s+|$)'), 'hCenter');
    // this.scroller.style.marginLeft = '-320px';
    // viewPortMaxY = this.scroller.clientHeight;
    // }
    // else {
    // scrollDirection = 'x';
    // this.scroller.style.width = '12900px';
    // this.scroller.style.height = '410px';
    // this.scroller.className = this.scroller.className.replace(new RegExp('(^|\\s+)' + 'hCenter' + '(\\s+|$)'), 'vCenter');
    // this.scroller.style.marginTop = '-205px';
    // viewPortMaxX = this.scroller.clientWidth;
    // }
  } */


  // /////////////////////////////////SCROLLER EVENTS///////////////////////////////////////////
  PreventSelection(mouseEvent) {
    mouseEvent.preventDefault();
  }

  OnMouseLost(mouseEvent) {
    this.OnMouseUp(mouseEvent);
  }

  OnMouseDown2(ex= 0, ey= 0) {
    console.log('SCROLLER - mouse down 2');
    this.prevMouseX = ex;
    this.prevMouseY = ey;

    this.prevMouseSaveX = ex;
    this.prevMouseSaveY = ey;

    this.rotation_speed = 20;

    this.curInertiaX = 0;
    this.curInertiaY = 0;
    this.curVelX = 0;
    this.curVelY = 0;
    this.lastMotion = new Date().getTime();
    this.lastX = this.targetX;
    this.lastY = this.targetY;
    this.mouseDown = true;
    this.didDrag = false;
    this.delayedCall = null;

    this.enableTracking();

    this.targetX = 0
    this.activateDrag(this);

  }
  OnMouseDown(e, ex, ey) {
    if(this.mouseDown) return
    console.log('SCROLLER - mouse down');
    window.caruselMouseDown = true
    // if (didDrag) return;

    this.stopDrawing = false;

    gsap.killTweensOf(Scroller);

    this.clickElement = null;
    
    if(e){

      e.preventDefault?.();

      // emitter.emit('on-carusel-startdrag')

      if(e.target?.nodeName === this.selectorType) {
        this.clickElement = e.target;
        this.lastClick = new Date().getTime();
    

      /*if (new Date().getTime() - this.lastMotion < 200){

        console.log("CLICKED !!");

        return;
      }*/
			/*_$lineIns.css("width", _$clickElement.width() + 4);
			_$lineIns.css("top", _$clickElement.offset().top + 25);
			_$lineIns.css("left", "29px");

			TweenLite.to("#lineIns", 0, {scaleX:0, autoAlpha:1, transformOrigin:"0%", overwrite:"all"})
			TweenLite.to("#lineIns", 0.2, {scaleX:1, transformOrigin:"0%", ease:Power4.easeOut})*/

			//return;
    }

    e.preventDefault?.();
    }

    this.prevMouseX = ex;
    this.prevMouseY = ey;

    this.prevMouseSaveX = ex;
    this.prevMouseSaveY = ey;

    this.rotation_speed = 20;

    this.curInertiaX = 0;
    this.curInertiaY = 0;
    this.curVelX = 0;
    this.curVelY = 0;
    this.lastMotion = new Date().getTime();
    this.lastX = this.targetX;
    this.lastY = this.targetY;
    // this.mouseDown = true;
    this.didDrag = false;
    this.delayedCall = null;

    this.enableTracking();

    /*const that = this;

    this.items.forEach((value, index) => {
      TweenLite.to(value.refObj, 0.5, {
        scale: 1, autoAlpha: 0, x: 0, transformOrigin: '50% 50%', ease: Power4.easeOut, overwrite: 'all',
      });

      if (that.projectID !== index) {
        TweenLite.to(value.refObjS, 0.5, { autoAlpha: 0.3, transformOrigin: '50% 50%', ease: 'power3.out' });
      }

      TweenLite.to(value.refObjS, 0.5, { scale: 1, x: 0, transformOrigin: '50% 50%', ease: 'power3.out' });
      value.speed = 0.2 + (index % 2) * 0.05;
    });

    StageStore.startCameraRotation();*/

    // this.delayedCall && this.delayedCall.kill()
    // this.delayedCall = gsap.delayedCall(0, this.activateDrag, [this])
    // return 
    // this.activateDrag(this);
  }

  activateDrag (ref){
    console.log("%c 🍌: activateDrag -> ref ", "font-size:16px;background-color:#38b66c;color:white;", ref)
    
    if (ref.didDrag) return;

    ref.didDrag = true;

    ref.scroller.classList.add('grabbing');

    this.shrinkCarouselItems();

    ProjectsStore.startCameraRotation();
  }

  OnMouseOver(e) {
    if(e.target.nodeName === this.selectorType) {

      const position = e.target.getBoundingClientRect();
      
    }
  }

  OnMouseMove(e, ex, ey) {
    
    if (window.caruselMouseDown) {
      var diffX = Math.abs(this.prevMouseX - ex)
      var diffY = Math.abs(this.prevMouseY - ey)
      
      if (diffX > 5) {
        window.caruselMouseDown = false
        this.mouseDown = true
        this.activateDrag (this)
        emitter.emit('on-carusel-startdrag')
      } else if (diffY > 5) {
        window.caruselMouseDown = false
        emitter.emit('on-carusel-drag-canceled', e)
      }

    }
    this.stopDrawing = false;

    const that = this;

    //console.log("MOUSE MOVE ");

    // if(e.target.nodeName === this.selectorType) {
      // console.log("OVER!!");
    // }

    if (this.mouseDown) {
      const timeNow = new Date().getTime();
      const newX = ex;
      const newY = ey;
      const deltaX = newX - this.prevMouseX;
      const deltaY = newY - this.prevMouseY;

      this.prevMouseX = newX;
      this.prevMouseY = newY;
      this.lastMotion = timeNow;

      this.scrollDirection = 'x';

      this.targetX -= deltaX * 2;

      //console.log("deltaX: ", deltaX);

      //console.log("this.didDrag: ", this.didDrag);

      const deltaMoveX = newX - this.prevMouseSaveX;

      //console.log("deltaMoveX", deltaMoveX);

      if (Math.abs(deltaMoveX) > 5 && this.didDrag === false){
        this.activateDrag(this);
      }
      /* if ((this.targetX) > this.viewPortMinX && (this.targetX < this.viewPortMaxX - this.browser_width)) this.targetX -= deltaX;
      else this.targetX -= deltaX / 3; */

      const dX = this.targetX - this.lastX;
      this.lastX = this.targetX;

      const velocity = Math.abs(dX);
      this.curVelX += (velocity - this.curVelX) * 0.3;
      this._direction = dX < 0 ? -1 : 1;
    }

    //this.didDrag = true;
  }

  showProjectWithId(id, triggeredBy){
    console.log("%c ⚠️: showProjectWithId -> id ", "font-size:16px;background-color:#355a36;color:white;", id);
    
    if (this.projectID == id && triggeredBy != 'click') return;

    console.log("showProjectWithId: ", id);

    this.projectID = id;
    
    this.immediateUpdate = true;
    this.mouseDown = true;
    this.didDrag = false;
    
    // this.setSelectedProject(this.projectID);
    
    this.update()
    
    var closerId = this.projectID
    var item = this.items.find((el, index) => {
      if(el.projectId == this.projectID){
        closerId = index
        return true
      }else 
        return false
    });

    this.repositionClosestItems(closerId, item);

    
    const rect = item.refObjS.offsetParent.getBoundingClientRect();
    var gdzieX = item.position - this.browser_center + (rect.width / 2);

    if (triggeredBy == "click" || triggeredBy == "drag"){

      //this.didDrag = true;

      this.savedTarget = gdzieX;
      this.targetX = this.savedTarget - this.browser_width;

      var objTmp = {x: this.targetX};

      gsap.to(objTmp, {delay:0.0, duration:1, x:this.savedTarget, ease:"power1.out", onUpdate: () => { this.targetX = objTmp.x}, onComplete: () => { 
        this.setSelectedProject(this.projectID)
      } });

      gsap.delayedCall(0.5, () => { this.mouseDown = false;});

    }else{
      this.savedTarget = gdzieX;
      this.targetX = gdzieX;

      this.setSelectedProject(this.projectID);

      this.mouseDown = false;
    }

    ProjectsStore.selectProjectNoCloth(1, this.projectID);
    //ProjectsStore.selectProject(1, this.projectID);

    this.mouseDown = false;

    // this.carouselShrinked = true;

    if (triggeredBy == "click" || triggeredBy == "drag"){
      // this.carouselShrinked = true;
    }
  }

  setSelectedProject(selectedProjectId){
    
    this.projectID = selectedProjectId;
    
    var closerId = this.projectID
    var item = this.items.find((el, index) => {
      if(el.projectId == this.projectID){
        closerId = index
        return true
      }else 
        return false
    });
    if (item) {
      
      this.centerCarouselItem(closerId, item);
      
      const rect = item.refObjS.offsetParent.getBoundingClientRect();
      var gdzieX = item.position - this.browser_center + (rect.width / 2);
  
      this.savedTarget = gdzieX;
  
      this.targetX = gdzieX;
    }
  }

  OnMouseUp(e) {
    
    if (!this.didDrag){
      window.caruselMouseDown = false;
      this.mouseDown = false;

      if(!this.onClickGoTo && !e.touches) {
        this.onClickGoTo = true;
        const currentNaviItemWidth = this.naviMainItems[this.projectID].clientWidth
        const goingLeft = e.clientX < window.innerWidth / 2

        const goTo = { x: window.innerWidth * 0.5, y: this.prevMouseY };
        const targetX = goTo.x + (goingLeft ? 0.9 : -1) * currentNaviItemWidth * 0.45
        if(e.clientX > window.innerWidth / 4 && e.clientX < window.innerWidth * 3 / 4) {
          this.onClickGoTo = false;
          return;
        }

        const speed = (goingLeft ? -1 : 1) * 100 / window.innerWidth;
        gsap.to(goTo, { x: targetX, duration: 0.5, ease: 'power1.inOut', onStart: () => {
          this.OnMouseDown(e, goTo.x, goTo.y);
        }, onUpdate: () => {
          ProjectsStore.setCameraRotation(speed);
          this.OnMouseMove({}, goTo.x, goTo.y);
        }, onComplete: () => {
          this.onClickGoTo = false;
          this.OnMouseUp({});
        }})
      }

      return;
    }
    // this.clickElement ,new Date().getTime()
    window.caruselMouseDown = false
    let closerId = null;
    let item = null;
    
    // console.log('SCROLLER: - onMouseuP', window.bm_dumpScroll());
    
    
    if (e != null && this.mouseDown){
      if(this.clickElement == e.target, new Date().getTime() - this.lastClick <200){
        

        var id = null
        if (e.target && e.target && e.target.getAttribute('data-id') != null) {
          id = parseInt(e.target.getAttribute('data-id'))
        }
        
        var nextId = this.projectID + 1
        if(nextId > this.items.length - 1){
          nextId = 0
        }

        if(id == nextId){
          console.log("%c 👃: Scroller -> OnMouseUp -> next ", "font-size:16px;background-color:#64da2c;color:black;")
        }
        
        this.items.forEach((el, index) => {
          if(el.ref.getAttribute('data-id') == nextId){
            item      = el
            closerId  = index
            console.log("%c ⏏️: Scroller -> OnMouseUp -> item ", "font-size:16px;background-color:#6edf77;color:black;", closerId, item)
            
          }
        });
      }
    } else {
      console.log('SCROLLER - ignore mouse up');
      
    }

    // e.preventDefault();
    if (e) if (e.touches) if (e.touches.length > 0) return;

    this.delayedCall && this.delayedCall.kill()


    if (this.mouseDown) {

      emitter.emit('on-carusel-stopdrag')
      this.mouseDown = false;
      this.curInertiaX = Math.abs(this.curVelX);
      this.curInertiaY = Math.abs(this.curVelT);
      this.processInertiaX = true;
      this.processInertiaY = true;
      const timeNow = new Date().getTime();
      let deltaTime = timeNow - this.lastMotion;
      deltaTime = Math.max(10, deltaTime);
      this.lastMotion = 0;
      this.curVelX *= 1 - Math.min(1, Math.max(0, deltaTime / 100));
      this.curVelY *= 1 - Math.min(1, Math.max(0, deltaTime / 100));
    }

    let closer = 60000;

    let direction = 1;

    let gdzieX = this.targetX;


    // if (!this.didDrag) return;

    const that = this;

    //return;
    if(closerId == null)
      this.items.forEach((value, index) => {
        const rect = value.refObjS.offsetParent.getBoundingClientRect();

        if (Math.abs(that.browser_center - rect.left) < closer || Math.abs(that.browser_center - rect.left - rect.width) < closer) {
          if (Math.abs(this.browser_center - rect.left) < Math.abs(this.browser_center - rect.left - rect.width)) {
            closer = -(this.browser_center - rect.left);
            direction = -1;
          } else {
            closer = this.browser_center - rect.left - rect.width;
            direction = 1;
          }

          // console.log("value.position", value.position);

          closerId = index;
          gdzieX = value.position - that.browser_center + (rect.width / 2);

          item = value;
        }
      });
    else{
      const rect = item.refObjS.offsetParent.getBoundingClientRect();
      gdzieX = item.position - that.browser_center + (rect.width / 2);
    }

    this.items[closerId].speed = 0.1;
    
    this.projectID = item.projectId;
    
    this.scroller.classList.remove('grabbing');

    // if (new Date().getTime() - this.lastClick < 150){

    this.clickElement = null;
    this.mouseDown = false;
    
    this.didDrag = false;

    //this.disableTracking();
//}

    // TweenLite.to(item.refNr, 0.1, { autoAlpha: 0, x:-50, ease: 'power3.out', delay:0 });
    this.centerCarouselItem(closerId, item);

    this.scrollDirection = 'x';

    this.prevMouseX = this.browser_center;
    this.prevMouseY = this.browser_height / 2;
    this.rotation_speed = 20;

    this.disableTracking();

    this.savedTarget = gdzieX;

    this.targetX = gdzieX;

    //return;

    ProjectsStore.selectProject(direction, this.projectID);
    
    // return;

    if (ProjectsStore.selectedProject != this.projectID){
      ProjectsStore.updatePortfolioView(this.projectID);
    }
    
    return this.didDrag ? false : undefined;
  }

  shrinkCarouselItems(){
    
    if (this.carouselShrinked) return;

    this.carouselShrinked = true;

    this.items.forEach((value, index) => {
      // gsap.to(value.refObj, {
      //   duration: 0.5, x: 0, transformOrigin: '50% 50%', ease: 'power4.out', overwrite: true,
      // });

      gsap.to(value.refObjS, { duration: 0.5, scale: 1, x: 0, autoAlpha: 0.3, transformOrigin: '50% 50%', ease: 'power3.out', overwrite: true });

      if (this.projectID !== value.projectId) {
        gsap.to(value.refObjS, { duration: 0.5, scale: 1, autoAlpha: 0.3, transformOrigin: '50% 50%', ease: 'power3.out' });
      }

      //gsap.to(value.refObjS, { duration: 0.5, scale: 1, x: 0, transformOrigin: '50% 50%', ease: 'power3.out', overwrite: true });
      value.speed = 0.2 + (index % 2) * 0.05;
    });
  }

  centerCarouselItem(closerId, item){
    if (!this.carouselShrinked || !item) return;

    this.carouselShrinked = false;
    // this.mouseDown = false;

    console.log("CENTER CAROUSEL!! ");
    
    this.repositionClosestItems(closerId, item);

    gsap.to(item.refObjS, {
      duration: 0.6,
      autoAlpha: 1, scale: 1.25, x: 0, transformOrigin: '50% 55%', ease: 'power3.out', overwrite: true,
    });

    // gsap.to(item.refObj, {
    //   duration: 0.6,
    //   scale: 1, x: 0, transformOrigin: '50% 50%', ease: 'power3.out', overwrite: true,
    // });

    gsap.to(item.refObjS, {
      duration: 0.6,
      css: { color: ProjectsStore.getProjects()[this.projectID].fontcolor }, ease: 'power3.out',
    });

    // this.repositionClosestItems(closerId, item);
  }

  repositionClosestItems(closerId, item){
    
    if (!item) return;

    console.log("repositionClosestItems item", item.height);

    const itemBefore = closerId - 1 === -1 ? this.items.length - 1 : closerId - 1;
    const itemAfter = closerId + 1 === this.items.length ? 0 : closerId + 1;

    const itemBefore2 = closerId - 2 <= -1 ? this.items.length + closerId - 2 : closerId - 2;
    const itemAfter2 = closerId + 2 >= this.items.length ? (closerId + 2) % this.items.length : closerId + 2;


    // TweenLite.to(this.items[itemBefore].refNr, 0.5, { x: -(((that.browser_width - item.width) / 2) - 150), autoAlpha: 0, ease: 'power3.out' });
    gsap.to(this.items[itemBefore].refObjS, { duration:0.5,
      autoAlpha: 0.3, scale: 1, x: -(((this.browser_width - item.width) / 2) - this.itemsDistance - (item.height * 0.55)), transformOrigin: '50% 50%', ease: 'power3.out' 
    });

        // TweenLite.to(this.items[itemAfter].refNr, 0.5, { x: (((that.browser_width - item.width) / 2) - 150), autoAlpha: 0, ease: 'power3.out' });
    gsap.to(this.items[itemAfter].refObjS, { duration:0.5,
      autoAlpha: 0.3, scale: 1, x: (((this.browser_width - item.width) / 2) - this.itemsDistance - (item.height * 0.55)), transformOrigin: '50% 50%', ease: 'power3.out' 
    });

    gsap.to(this.items[itemBefore2].refObjS, { duration:0.5,
      autoAlpha: 0.3, scale: 1, x: -(((this.browser_width - item.width) / 2) - this.itemsDistance - (item.height * 0.55)), transformOrigin: '50% 50%', ease: 'power3.out' 
    });

        // TweenLite.to(this.items[itemAfter].refNr, 0.5, { x: (((that.browser_width - item.width) / 2) - 150), autoAlpha: 0, ease: 'power3.out' });
    gsap.to(this.items[itemAfter2].refObjS, { duration:0.5,
      autoAlpha: 0.3, scale: 1, x: (((this.browser_width - item.width) / 2) - this.itemsDistance - (item.height * 0.55)), transformOrigin: '50% 50%', ease: 'power3.out' 
    });
  }

  getX() {
    return 	_roundedViewportX;
  }


  getY() {
    return 	_roundedViewportY;
  }

  update() {
    
    // this.stats.update();

    if (this.stopDrawing) return;
    
    
    if (this.didDrag === true || this.immediateUpdate || this.lastSpeed != 0) {
      const firstItem = this.items[0].ref//this.scroller.children[0];
      const lastItem = this.items[this.items.length - 1].ref//this.scroller.children[this.scroller.children.length - 1];

      const rect = firstItem.getBoundingClientRect();

      if (rect.left + (this.itemsDistance * 2) > 0 && this.countIt != 0) {
        const position = this.items[0].position -
        _.last(this.items).width - this.itemsDistance;

        lastItem.style.left = `${position}px`;

        this.items.splice(0, 0, this.items.splice(this.items.length - 1, 1)[0]);

        this.items[0].position = position;

        this.scroller.insertBefore(lastItem, firstItem);
      } else {
        const rectLast = lastItem.getBoundingClientRect();

        if (rectLast.left - (this.itemsDistance * 2) + rectLast.width < this.browser_width) {
          const position = _.last(this.items).position +
          _.last(this.items).width + this.itemsDistance;

          firstItem.style.left = `${position}px`;

          this.items.splice(this.items.length - 1, 0, this.items.splice(0, 1)[0]);

          _.last(this.items).position = position;

          this.scroller.appendChild(firstItem);
        }
      }
    }


    if (this.scrollDirection == 'x') {
      // console.log('this.targetX!!', this.targetX);

      let closer = 6000000;
      let closerId = 0;
      let speed = 0;
      
      this.items.forEach((value, h) => {
        speed = (this.targetX - value.viewPortX) * (this.immediateUpdate ? 1 : value.speed);
        if(Math.abs(speed)  < 0.01)
          speed = 0
        
        value.viewPortX += speed;
        const roundedX = -Number(value.viewPortX).toFixed(1);

        const rect = value.refObjS.getBoundingClientRect();

        if (Math.abs(this.browser_center - rect.left) < closer || Math.abs(this.browser_center - rect.left - rect.width) < closer) {
          if (Math.abs(this.browser_center - rect.left) < Math.abs(this.browser_center - rect.left - rect.width)) {
            closer = -(this.browser_center - rect.left);
          } else {
            closer = this.browser_center - rect.left - rect.width;
          }

          closerId = h;
        }

        if (this.roundedX === -Number(this.targetX).toFixed(1)) {
          this.stopDrawing = true;
        } else {
          this.stopDrawing = false;
        }

        // console.log('this.roundedX', this.roundedX);

        if (this.didDrag === true) {
          value.rotation = value.direction * speed / 10;// (this.browser_height/2 - this.prevMouseY)/40;

          value.rotationTmp -= (value.rotationTmp - value.rotation) / 5;
        } else {
          value.rotation = 0;

          value.rotationTmp -= (value.rotationTmp - value.rotation) / 10;
        }

        if (Math.abs(value.rotationTmp).toFixed(1) < 0.1){
          value.rotationTmp = 0;
        }

        if(this.immediateUpdate)
          value.rotationTmp = 0;

        // value.rotationTmp = 0;
        this.setElementTransform(value.ref, `${roundedX}px`, `${0}px`, `${-value.rotationTmp / 4}deg`);
        // this.setElementTransformR(this.items[h].ref, `${this.navi_rotationTmp}deg`);
      })

      //console.log("this.targetX ", this.targetX);


      this.lastSpeed = speed

      if (this.didDrag === true) {
        if (this.browser_height / 2 > this.prevMouseY) {
          this.navi_rotation = speed / 10;// (this.browser_height/2 - this.prevMouseY)/40;
        } else {
          this.navi_rotation = -speed / 10;
        }
      } else {
        this.navi_rotation = 0;
      }

      this.navi_rotationTmp -= (this.navi_rotationTmp - this.navi_rotation) / this.rotation_speed;


      if (this.didDrag === true) {
        ProjectsStore.setCameraRotation(speed / 100);
      }

      if (Math.abs(this.navi_rotationTmp) < 0.1){
        this.navi_rotationTmp = 0;
      }

      // console.log(this.scroller, this.navi_rotationTmp);

      // this.setElementTransform(this.scroller, `${0}px`,`${this.navi_rotationTmp}px`);
      
      if(this.immediateUpdate)
        this.navi_rotationTmp = 0;

      // this.setElementTransformR(this.scroller, `${this.navi_rotationTmp}deg`);

      if (this.mouseDown) {
        if (this.closerID_old !== closerId) {
          this.closerID_old = closerId;

          this.items.forEach((value, h) => {
            if (h !== this.closerID_old) {
              gsap.to(value.refObjS, {duration: this.immediateUpdate ? 0 : .1, scale:1, autoAlpha: 0.3, x:0, overwrite:true });
              // gsap.to(value.refObj, {duration: this.immediateUpdate ? 0 : .1, scale:1, autoAlpha: 0, x:0, overwrite:true });
              // TweenLite.to(value.refNr, 0.1, { autoAlpha: 0.2 });
            } else {

              gsap.to(value.refObjS, {duration: this.immediateUpdate ? 0 : .3, scale:1, autoAlpha: 1, x:0, overwrite:true });
              // gsap.to(value.refObj, {duration: this.immediateUpdate ? 0 : .1, scale:1, autoAlpha: 0, overwrite:true });
              // TweenLite.to(value.refNr, 0.7, { autoAlpha: 1 });
            }
          });
        }
      }
    }

    this.immediateUpdate = false
    this.scrollClicked = false;

    this.offsetX = this._roundedViewportX;
    this.offsetY = this._roundedViewportY;
  }


  set2dTransform(ele, x, y, r, s) {
    this.v = `translate(${x},${y}) rotate(${r})`;
    ele.style.transform = this.v;
    ele.style.WebkitTransform = this.v;
    ele.style.msTransform = this.v;
    ele.style.MozTransform = this.v;
  }

  setElementTransformR(ele, r) {
    this.v = `translate(0, 0) rotate(${r})`;
    ele.style.transform = this.v;
    ele.style.WebkitTransform = this.v;
  }

  setElementTransform(ele, x, y, r, s) {
    this.v = `translate3d(${x},${y}, 0px) translate(0, 0) rotate(${r})`;
    ele.style.transform = this.v;
    ele.style.WebkitTransform = this.v;
  }

  // starts tracking mouse after mouseDown
  enableTracking() {
    if (this.trackingEnabled == false) {
      this.trackingEnabled = true;
      Scroller.addEvent(this.scroller, this.dragMoveEvt, this.dragMoveCB );
      Scroller.addEvent(window, this.dragStopEvt, this.dragStopCB );
      //Scroller.addEvent(this.scroller, this.dragOverEvt,   this.dragOverCB );
      //Scroller.addEvent(this.scroller, this.dragOutEvt,   his.dragOutCB );
      Scroller.addEvent(this.scroller, this.dragCancelEvt, this.dragStopCB );

      Scroller.addEvent(this.scroller, this.dragMoveEvt2, this.dragMoveCB2 );
      Scroller.addEvent(window, this.dragStopEvt2, this.dragStopCB2 );
      //Scroller.addEvent(this.scroller, this.dragOverEvt,   this.dragOverCB );
      //Scroller.addEvent(this.scroller, this.dragOutEvt,   his.dragOutCB );
      Scroller.addEvent(this.scroller, this.dragCancelEvt2, this.dragStopCB2 );
    }
  }
  // ends tracking mouse after mouseDown
  disableTracking() {
    if (this.trackingEnabled == true) {
      this.trackingEnabled = false;
      Scroller.removeEvent(this.scroller, this.dragMoveEvt, this.dragMoveCB);
      Scroller.removeEvent(window, this.dragStopEvt, this.dragStopCB);
      //Scroller.removeEvent(this.scroller, this.dragOverEvt, this.dragOverCB);
      //Scroller.removeEvent(this.scroller, this.dragOutEvt, this.dragOutCB);
      Scroller.removeEvent(this.scroller, this.dragCancelEvt, this.dragStopCB);

      Scroller.removeEvent(this.scroller, this.dragMoveEvt2, this.dragMoveCB2);
      Scroller.removeEvent(window, this.dragStopEvt2, this.dragStopCB2);
      Scroller.removeEvent(this.scroller, this.dragCancelEvt2, this.dragStopCB2);
    }
  }

  addEventHandlers(eventType) {
    const main = this;

    // if (eventType === 'mouse') {
      this.dragStartEvt = 'mousedown';

      this.dragStartCB = function (e) {
        if(e.button !== 0) return; // only lmb click
        console.log('SCROLLER: dragStartCB');
        
        return main.OnMouseDown(e, e.clientX, e.clientY);
      };
      
      this.dragMoveEvt = 'mousemove';
      this.dragMoveCB = function (e) {
        // console.log('SCROLLER: dragMoveCB');
        return main.OnMouseMove(e, e.clientX, e.clientY);
      };

      this.dragOverEvt = 'mouseover';
      this.dragOverCB = function (e) {
        console.log('SCROLLER: dragOverCB');
        return main.OnMouseOver(e);
      };

      this.dragOutEvt = 'mouseout';

      this.dragStopEvt = 'mouseup';
      this.dragStopCB = function (e) {
        console.log('SCROLLER: dragStopCB');
        return main.OnMouseUp(e);
      };

      this._dragOutEvt = 'mouseout';
      this._dragOutCB = function (e) {
        console.log('SCROLLER: _dragOutCB');
        return main.OnMouseUp(e);
      };
    // } else {
      this.dragStartEvt2 = 'touchstart';
      this.dragStartCB2 = function (e) {
        console.log("SCROLLER: dragStartCB2");

        const t = e.touches[0];
        return main.OnMouseDown(e, t.pageX, t.pageY);
      };

      this.dragMoveEvt2 = 'touchmove';
      this.dragMoveCB2 = function (e) {
        console.log("SCROLLER: dragMoveCB2");

        const t = e.touches[0];
        return main.OnMouseMove(e, t.pageX, t.pageY);
      };

      this._dragCancelEvt2 = 'touchcancel';
      this._dragLeaveEvt2 = 'touchleave';

      this.dragStopEvt2 = 'touchend';
      this.dragStopCB2 = function (e) {
        console.log('SCROLLER: dragStopCB2', );
        return main.OnMouseUp(e);
      };
    // }
  }

  resize() {
    /* if (this.scroller) {
      viewPortMaxY = this.scroller.clientHeight * BMSite.siteScale + (BMSite.siteYAdd * 2);
      viewPortMaxX = this.scroller.clientWidth * BMSite.siteScale;
    } */

    this.browser_width = StageStore.getViewPortWidth();
    this.browser_height = StageStore.getViewPortHeight();
    this.browser_center = this.browser_width / 2;

    this.itemsDistance = Math.floor(30 * (this.browser_width / 1680));

    let positionX = 0;

    this.items.forEach((el, index) => {
      const rect = el.ref.getBoundingClientRect()

      el.ref.style.left = `${positionX}px`;
      el.width = rect.width
      el.height = rect.height
      el.position = positionX
      positionX += rect.width + this.itemsDistance;
    })

    if(this.items.length) {
      var closerId = this.projectID
      var item = this.items.find((el, index) => {
        if(el.projectId == this.projectID){
          closerId = index
          return true
        }else 
          return false
      });

      this.repositionClosestItems(closerId, item);

      const rect = item.refObjS.offsetParent.getBoundingClientRect();
      var gdzieX = item.position - this.browser_center + (rect.width / 2);
  
      this.savedTarget = gdzieX;
      this.targetX = gdzieX;

      this.immediateUpdate = true
      this.update()

      this.centerCarouselItem(closerId, item);

      //this.repositionClosestItems(closerId, item);

    }
    
  }

  targetYUpdate() {
    this.targetY = this.targetYTmp;
  }

  targetYUpdateTmp() {
    this.targetYTmp = this.targetY;
  }
}

export default Scroller;
