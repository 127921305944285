<template>
  <div class="home" ref="home">
    <div class="intro" ref="introCover">
      <!-- <div id="intro-logo" ref="introLogo"></div> -->
      <video autoplay muted loop playsinline id="intro-logo" ref="introLogo" alt="Spinning Bright Logo">
        <source src="/assets/Star.webm"
          type="video/webm">
        <source src="/assets/Star.mp4"
          type="video/mp4">
      </video>
    </div>


    <div class="home-wrapper">

      <PortfolioExpanded ref="portfolioExpanded" @on-close-right-column="onShrinkRightColumn"></PortfolioExpanded>

      <div ref="colsWrapper" class="cols-wrapper" :class="{ 'pointer-events-none': isPortfolioExpanded }">
        <div ref="blackCover" class="black-cover"></div>
        <div class="right-column-fixed" :style="{ width: portfolioColumnWidth + 'px' }">
          <div>
            <div id="right-column-content" :style="{ 'min-width': `${rightColumnMinWidth}px` }">
              <ColumnRight :lastColRightWidth="lastColRightWidth" :colRightWidth="colRightWidth"
                @on-size-changed="onColumnSizeChanged" @on-project-click="onProjectclick"
                @on-all-video-loaded="onAllVideoLoaded" />
            </div>


            <!-- <div class="right-column-hover-apla" :style="{ opacity: colRightAplaOpacity }"></div> -->
            <div class="right-click-to-open-area" @click="onExpandColumn"
              v-if="isMobile && !isPortfolioExpanded && !isRightColumnExpanded"></div>
          </div>
          <div v-show="!isMobile" class="column-borders"></div>
          <!-- <div class="drag-bar" @mousedown="onDragStart">
            <div class="bars"></div>
          </div> -->
          <!-- <div style="position: fixed; top: 0; left: 0;">
            <div>
              isPortfolioExpanded: {{isPortfolioExpanded}}
            </div>
            <div>
              isRightColumnExpanded: {{isRightColumnExpanded}}
            </div>
            <div>
              viewWidth: {{viewWidth}}
            </div>
          </div> -->
        </div>

        <div ref="cookies" class="cookies-container bm-xs bm-sm bm-md bm-lg bm-xl" :style="{ width: contentColumnWidth + 'px'}">
          <div class="
            cookies-wrapper
            bm-col-18 bright-nav-size">
            <div @mouseenter="redrawBorder" class="cookies-link">
              <div class="cookies-info" @click="() => performRouteChange('cookies', openForm(this.$refs.cookiePolicyContainer))">
                This site uses cookies
                <!-- <div class="border-redraw-transition left"></div> -->
              <!-- <div class="border-redraw-transition right"></div> -->
              </div>
              <div class="cookies-confirm" @click="acceptCookies">OK</div>

            </div>
          </div>
        </div>

        <div class="chessboard-container" :style="{ width: contentColumnWidth + 'px' }">
          <div id="chessboard"></div>
        </div>

        <div class="column-left-main" :style="{ width: contentColumnWidth + 'px' }">
          <div ref="colLeft" class="left-column-fixed" :class="{ 'portfolio-expanded': isPortfolioShrinked == false }">
            <div ref="colLeftWrapper" class="column-left-wrapper">
              <ColumnLeft
                @on-start-project="() => performRouteChange('contact', openForm(this.$refs.startProjectFormContainer) )"
                @on-careers-click="() => performRouteChange('careers', openForm(this.$refs.careersFormContainer) )" 
                @on-cookies-click="() => performRouteChange('cookies', openForm(this.$refs.cookiePolicyContainer) )" 
                @on-see-work="() => openProjects()"
                :colLeftWidth="colLeftWidth"
                :lastColLeftWidth="lastColLeftWidth" />
            </div>
          </div>

          <div class="drag-bar intro-animation-3" @click="closeClick" @mousedown="onDragStart" @touchstart="onDragStart" v-show="!isMobile">
            <div id="drag-bar-ins" class="drag-bar-ins">
              <div id="bars" class="bars">
                <div class="bar-cover">
                  <div id="bar1" class="bar1"></div>
                  <div id="bar2" class="bar2"></div>
                </div>
                <div class="bar3" v-if="isPortfolioExpanded">
                  GO HOME
                </div>
                <div class="bar3" v-if="!isPortfolioExpanded">
                  SEE WORK
                </div>
              </div>
            </div>
          </div>

          <div class="drag-bar drag-bar--mobile intro-animation-3" @mousedown="onDragStart" @touchstart="onDragStart" v-show="isMobile">
            <div class="drag-bar-ins drag-bar-ins--mobile">
              <div class="bars">
                <div class="bar-cover">
                  <div id="bar1" class="bar1"></div>
                  <div id="bar2" class="bar2"></div>
                </div>
                <div class="bar3" v-if="isPortfolioExpanded">
                  GO HOME
                </div>
                <div class="bar3" v-if="!isPortfolioExpanded">
                  SEE WORK
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="activeForms > 0" class="form-close-area"
        @click="() => getFormContainers().forEach(container => performRouteChange('/', onCloseContactForm(container)))">
      </div>

      <div class="form-container" ref="startProjectFormContainer">
        <div class="close-portfolio-x form" @click="() => performRouteChange('/', onCloseContactForm($refs.startProjectFormContainer))">
          ✕
        </div>

        <div class="form-wrapper">
          <StartProjectForm ref="startProjectForm" :onCloseClick="() =>{
            performRouteChange('/', onCloseContactForm($refs.startProjectFormContainer))
          }"></StartProjectForm>
        </div>
      </div>

      <div class="form-container careers-form" ref="careersFormContainer">
        <div class="close-portfolio-x form" @click="() => performRouteChange('/', onCloseContactForm($refs.careersFormContainer))">
          ✕
        </div>

        <div class="form-wrapper">
          <CareersForm ref="form"></CareersForm>
        </div>
      </div>

      <div class="form-container" ref="cookiePolicyContainer">
        <div class="close-portfolio-x form" @click="() => performRouteChange('/', onCloseContactForm($refs.cookiePolicyContainer))">
          ✕
        </div>

        <div class="form-wrapper">
          <CookiePolicy ref="form"></CookiePolicy>
        </div>
      </div>


    </div>
  </div>
</template>

<script >
/* eslint-disable */
// import lottie from 'lottie-web';

// import * as animationData from '../json/logo.json';
/* eslint-disable */
import ColumnLeft from '@/components/ColumnLeft.vue';
import ColumnRight from '@/components/ColumnRight.vue';
// import DraggerDatGui from '@/components/debug/DraggerDatGui.vue'; 
import StartProjectForm from '@/components/contactform/StartProjectForm.vue';
import CareersForm from '@/components/contactform/CareersForm.vue';
import CookiePolicy from '@/components/contactform/CookiePolicy.vue';
import gsap from 'gsap'
import CustomEase from 'gsap/CustomEase'
import emitter from '@/js/emitter'
import Scrollbar from 'smooth-scrollbar';
import PortfolioExpanded from '@/views/PortfolioExpanded.vue';
import portfolioMixin from '@/mixins/portfolio-mixin';
import redrawBorder from '@/mixins/redraw-border';
import ModalPlugin from '@/plugins/ModalPlugin';
import ProjectsStore from '@/js/ProjectsStore';
import { PortfolioTransitionsParams } from '@/js/constants/ProjectConstants';

import Cookies from 'js-cookie'

import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
// import Scroller from '@/js/navi/Scroller';
import InertialDragger from '@/js/navi/InertialDragger2';
import router from '../router';
// import * as dat from 'dat.gui';

gsap.registerPlugin(CustomEase)

export default {
  mixins: [portfolioMixin, redrawBorder],
  components: {
    ColumnLeft,
    ColumnRight,
    StartProjectForm,
    CareersForm,
    PortfolioExpanded,
    CookiePolicy,
  },
  data() {
    return {
      viewWidth: window.innerWidth,
      colLeftWidth: window.innerWidth * 75 / 100,
      lastColRightWidth: window.innerWidth * 25 / 100,
      lastColLeftWidth: window.innerWidth * 75 / 100,
      activeForms: 0,
      leftScroller: null,
      inertialDragger: null,
      scrollY: 0,
      fixedRightColumWidth: 18,
      isAllVideoListLoaded: false,
      bgColor: "#f7f7f2",
      bgColorAlpha: "#f7f7f200",
    }
  },
  mounted() {

    Scrollbar.use(OverscrollPlugin);
    Scrollbar.use(ModalPlugin);

    this.draggingColumn = false;

    const SCROLLERS = [1, 3];

    if (SCROLLERS.includes(1)) {

      console.log("%c ✴️: mounted -> this.leftScroller ", "font-size:16px;background-color:#1e41aa;color:white;");
      this.leftScroller = Scrollbar.init(this.$refs.colLeft, {
        onScroll(position) {
          //console.log('position', position);
          emitter.emit("on-scroll", position);
        },
        damping: 0.2,
        renderByPixels: true,
        plugins: {
          overscroll: {
            effect: 'bounce',
            damping: 0.5,
            maxOverscroll: 50,
            onScroll(position) {
              //console.log('position', position);
              emitter.emit("on-overscroll", position);
            }
          },
        }
      });

      this.leftScroller.addListener((status) => {

        if(!this.$refs.cookies.classList.contains('cookies-hidden')) {
          gsap.to(this.$refs.cookies, { opacity: 1 })
        }
        // console.log({});

        emitter.emit("on-left-column-scroll", { deltaY: status.offset.y - this.scrollY, offsetY: this.$refs.colLeftWrapper.offsetHeight - this.$refs.colLeft.offsetHeight - status.offset.y });
        this.scrollY = status.offset.y;
      })





      // window.bm_setMomentumY = (num) => {
      //   this.leftScroller.setMomentum(0, num);
      // }

    }

    const DraggerAndScrollerConnectorPlugin = function (scroller, activeArea) {

      let startOffset = {};
      let wheelActivated = false; // Was wheel used since drag started. 
      // Used to kill dragger inertia when mouse wheel is used.

      const onWheelActivated = () => {
        wheelActivated = true;
        activeArea.removeEventListener('wheel', onWheelActivated);
      }

      return {
        onDragStart: (_) => {
          startOffset.x = scroller.offset.x;
          startOffset.y = scroller.offset.y;
          wheelActivated = false;
          activeArea.addEventListener('wheel', onWheelActivated, {passive: true});
        },
        onDragEnd: (dragger) => {
          console.log("DRAGGER PLUGIN END", dragger.currentSpeed.y);
        },
        update: (dragger) => {
          // console.log("DRAGGER PLUGIN", dragger.currentSpeed.y);
          // Updates Scrollbar if wheel wasn't used since drag started.
          if (!wheelActivated) {
            scroller.setPosition(
              startOffset.x - dragger.currentPositionOffset.x,
              startOffset.y - dragger.currentPositionOffset.y,
            );
          }
        }
      }
    };

    if (!this.isMobile) {
      this.inertialDragger = new InertialDragger(this.$refs.colLeft, {
        plugins: [
          DraggerAndScrollerConnectorPlugin(this.leftScroller, this.$refs.colLeft)
        ]
      });

      // var gui = new dat.GUI();
      // gui.width = 400;
      // const draggerFolder = gui.addFolder('Dragger');
      // draggerFolder.add(this.inertialDragger, 'speedDamping').name('Drag damping (rubber)').min(0.0).max(1).step(0.025)
      // draggerFolder.add(this.inertialDragger.inertiaDamping, 'y').name('Inertia damping ').min(0.0).max(1).step(0.025)
      // draggerFolder.add(this.inertialDragger, 'speedMultiplier').name('Speed multiplier').min(0).max(5).step(0.05);
      // draggerFolder.add(this.inertialDragger, 'postDragInertiaType').name('Post drag behavior').options(['lastSpeed', 'rubber']).listen();
      // draggerFolder.add(this.inertialDragger.currentSpeed, 'y', -100, 100, 1).name('Speed Y').listen();
      // draggerFolder.open();
    }



    console.log("%c ♉: mounted -> inertialDragger ", "font-size:16px;background-color:#056b13;color:white;");

    gsap.utils.toArray(this.$refs.home.querySelectorAll('.form-wrapper')).forEach(wrapper => {
      const formScroller = Scrollbar.init(wrapper);
      if (!this.isMobile) {
        this.inertialDragger = new InertialDragger(wrapper, {
          plugins: [
            DraggerAndScrollerConnectorPlugin(formScroller, wrapper)
          ]
        });
      }
    })


    window.addEventListener('resize', this.onWindowResize);
    this.onWindowResize();

    window.addEventListener('mousemove', this.onMouseMoveDragger);

    // emitter.on('onCloseContactForm', this.onCloseContactForm)
    // emitter.on("on-right-column-scroll", ({ deltaY }) => this.leftScroller.scrollTop += deltaY / 10 )

    // this.onMouseUp({clienX: this.colLeftWidth, noAnimation: true})

    // fake routing

    this.handleRouteChange();

    window.addEventListener('popstate', this.handleRouteChange)
    window.addEventListener('pushstate', this.handleRouteChange)

    // this.$refs.introCover.remove()
    // return;

    // intro animation

    // var logoAnimation = lottie.loadAnimation({
    //   container: document.getElementById('intro-logo'), // required
    //   animationData: animationData.default,
    //   renderer: 'svg', // required
    //   autoplay: false, // optional
    //   loop: false,
    //   name: "Bright signature", // optional,
    // });

    gsap.set('.intro-animation-1', { 
      opacity: 0,
      x: -10
    })

    gsap.set('.intro-animation-2', { 
      opacity: (idx) => idx <= 4 ? 0 : 1,
    })

    gsap.set('.intro-animation-3', { opacity: 0 })

    const homeLogoText = document.querySelector('#home-logo-text')
    gsap.set(homeLogoText, { x: -50 })

    const ease = CustomEase.create("custom", "M0,0 C0.084,0.61 0.175,0.833 0.236,0.894 0.3,0.958 0.374,1 1,1 ");
    const config = { duration: 1.6, ease };

    const isHomePage = router.currentRoute.value.path === '/';

    gsap.timeline()
      .add('logo-animation')
      // wait after everything is loaded and start logo animation
      .delay(0.3)
      // .add(() => logoAnimation.play())
      // wait for animation to finish
      .to(this.$refs.introLogo, { duration: 3 })

      // shift black plane to the right
      .add('uncover')
      .to(this.$refs.introCover, { width: isHomePage ? this.colRightWidth : 0, ...config, }, 'uncover')
      .to(this.$refs.introLogo, { x: isHomePage ? -this.colLeftWidth : -window.innerWidth, opacity: 0, ...config }, 'uncover')
      .to(homeLogoText, { x: 0, ...config, duration: 1.2 }, 'uncover')
      
      // fade in page elements left to right
      .add('fadein')
      .set(this.$refs.introCover, { zIndex: 1 }, 'fadein-=0.6')
      .to('.intro-animation-1', { opacity: 1, x: 0, ease: 'power2.out', duration: 1.4, delay: (idx) => idx * 0.1}, 'fadein-=1.4')
      // projects
      .to(this.$refs.introCover, { opacity: 0, pointerEvents: 'none' }, 'fadein')
      .to('.intro-animation-2', { opacity: 1, x: 0, ease: 'power2.out', duration: 1.2, delay: (idx) => idx * 0.2}, 'fadein-=0.6')
      // drag bars
      .to('.intro-animation-3', { opacity: 1, ...config }, 'fadein-=0.6')
      .add(() => this.$refs.introCover.remove())


      if (Cookies.get("cookiePolicy")){
        gsap.to(this.$refs.cookies, {
          opacity: 0,
          pointerEvents: 'none'
        })
        this.$refs.cookies.classList.add('cookies-hidden')
        
      };
  },
  methods: {
    performRouteChange(url, action = null) {
      router.push(url)
      action?.();
    }, 
    onShrinkRightColumn() {
      this.onPortfolioWillShrink()
    },
    onExpandColumn() {

      this.lastColLeftWidth = this.colLeftWidth;
      this.lastColRightWidth = window.innerWidth - this.colLeftWidth;

      gsap.timeline({ onComplete: () => this.onPortfolioExpanded() })
        .to('.column-borders', {
          x: 15,
          ...PortfolioTransitionsParams,
          duration: PortfolioTransitionsParams.duration / 2,
          overwrite: true
        }, 'sync')
        .to(this, {
          colLeftWidth: 15,
          ...PortfolioTransitionsParams,
          duration: PortfolioTransitionsParams.duration / 2
        }, 'sync')
      // .to ('.left-column-wrapper', { delay:0, duration: PortfolioTransitionsParams.duration / 2, ease:"power1.in"}, 'sync')

    },
    onAllVideoLoaded() {
      console.log("%c 🔃: onAllVideoLoaded -> onAllVideoLoaded ", "font-size:16px;background-color:#0885fe;color:white;")
      this.isAllVideoListLoaded = true;
      const currentRoute = router.currentRoute.value.path;
      if(currentRoute.startsWith('/projects')) {
        this.handleRouteChange();
      }
    },
    onDragStart(e) {
      e.preventDefault();
      console.log('DRAG START');

      if (!e.target.classList.contains("drag-bar")) return;

      const touch = e.touches ? e.touches[0] : e;

      this.draggingColumn = true;
      this.dragXShift = this.draggerOffset - (touch.clientX - touch.target.offsetLeft);

      e.target.classList.add("grabbing");

      gsap.to('.left-column-wrapper', { backgroundColor: this.bgColor, duration: 0 })

      window.addEventListener('mouseup', this.onMouseUp);
      window.addEventListener('mousemove', this.onMouseMove);

      window.addEventListener('touchcancel', this.onMouseUp, {passive: true})
      window.addEventListener('touchleave', this.onMouseUp, {passive: true})
      window.addEventListener('touchend', this.onMouseUp, {passive: true})
      window.addEventListener('touchmove', this.onTouchMove, {passive: true})

      this.onColumnSizeChanged({ mouseX: touch.clientX + this.dragXShift })

      this.hideCookies();
    },
    onMouseMove(e) {
      this.draggingColumn = true;
      this.leftScroller.update();

      this.onColumnSizeChanged({ mouseX: e.clientX + this.dragXShift })

    },
    onTouchMove(e) {
      const touch = e.touches[0];
      this.onMouseMove(touch)
    },
    onMouseMoveDragger(e) {
      if (!this.isPortfolioShrinked) return

      if (this.draggingColumn) return;

      const params2 = { duration: 0.35, ease: 'power2.out' }

      // if(this.colLeftWidth < e.clientX + 15){
      //   gsap.to ('#bars', { x:0, ...params2})
      //   gsap.to ('#bar1', { backgroundColor: '#FFFFFF', ...params2})
      //   gsap.to ('#bar2', { backgroundColor: '#FFFFFF', ...params2});
      // }else{
      //   gsap.to ('#bars', { x:-16, ...params2})
      //   gsap.to ('#bar1', { backgroundColor: '#000000', ...params2})
      //   gsap.to ('#bar2', { backgroundColor: '#000000', ...params2})
      // }
    },
    onMouseUp(e) {
      console.log("%c 1️⃣: onMouseUp -> e ", "font-size:16px;background-color:#f8b467;color:black;", e)

      const touch = e.changedTouches ? e.changedTouches[0] : e;

      this.draggingColumn = false;

      window.removeEventListener('mouseup', this.onMouseUp)
      window.removeEventListener('touchcancel', this.onMouseUp)
      window.removeEventListener('touchleave', this.onMouseUp)
      window.removeEventListener('touchend', this.onMouseUp)
      window.removeEventListener('mousemove', this.onMouseMove)
      window.removeEventListener('touchmove', this.onTouchMove)

      var dragger = document.getElementsByClassName("drag-bar");

      if (dragger) dragger[0].classList.remove("grabbing");

      var whereSnap75 = this.$store.getters.windowWidth * 75 / 100;
      var whereSnap50 = this.$store.getters.windowWidth * 50 / 100;
      var whereSnap25 = this.$store.getters.windowWidth * 25 / 100;
      var whereSnap0 = this.$store.getters.windowWidth * 0 / 100;

      var whereX = touch.clientX + this.dragXShift;

      var whereSnap = { now: whereX, x: whereSnap75 };

      if (Math.abs(whereSnap50 - whereX) > Math.abs(whereSnap0 - whereX)) {
        whereSnap = { now: whereX, x: whereSnap0 };
        // ProjectsStore.selectProjectDirect(this.currentProjectInViewportId, false, true, 'click');
        // ProjectsStore.selectProjectDirect(1, false, true, 'click');
        setTimeout(() => { ProjectsStore.openProjects() });
        console.log('Where snap1', whereSnap);


      } else if (Math.abs(whereSnap75 - whereX) > Math.abs(whereSnap50 - whereX)) {
        whereSnap = { now: whereX, x: whereSnap50 };
        console.log('Where snap2', whereSnap);

      }
      if (whereSnap.x > this.$store.getters.windowWidth * 75 / 100) {
        whereSnap.x = this.$store.getters.windowWidth * 75 / 100;
        console.log('Where snap3', whereSnap);
      }

      if (this.isMobile) {
        if (this.isRightColumnExpanded) {
          if (touch.clientX > this.windowWidth / 2) {
            whereSnap.x = window.innerWidth - this.fixedRightColumWidth
            this.$store.dispatch('isRightColumnExpanded', false)

            if (this.isPortfolioExpanded) {
              this.$store.dispatch('toggleHome', false);

              ProjectsStore.updatePortfolioView();
              this.$store.dispatch('shrinkPortfolio');

              return
            }

          } else {
            whereSnap.x = 0
            this.$store.dispatch('isRightColumnExpanded', true)
          }
        } else {
          if (touch.clientX > this.windowWidth / 2) {
            whereSnap.x = window.innerWidth - this.fixedRightColumWidth
            this.$store.dispatch('isRightColumnExpanded', false)
          } else {
            whereSnap.x = 0
            this.$store.dispatch('isRightColumnExpanded', true)
          }
        }
      } else {
        this.$store.dispatch('isRightColumnExpanded', false)
      }

      if (whereSnap.x <= 0 && this.isPortfolioShrinked) {
        console.log('DRAG - EXPAND PORTFOLIO');
      } else if (whereSnap.x > 0 && this.isPortfolioExpanded) {
        console.log('DRAG - SHRINK PORTFOLIO');
        setTimeout(ProjectsStore.closeProjects(), 10);
        // ProjectsStore.enterProjects();
      }

      this.leftScroller.update();

      gsap.to(whereSnap, { duration: e.noAnimation ? 0 : 0.3, now: whereSnap.x, ease: "power1.out", onUpdate: () => { this.onColumnSizeChanged({ mouseX: whereSnap.now }) } });

      this.showCookies();
    },
    closeClick() {
      if(this.isPortfolioExpanded) {
        ProjectsStore.closeProjects()
      }
    },
    expandRightColumn() {
      const whereSnap = { now: window.innerWidth, x: 0 };
      this.$store.dispatch('isRightColumnExpanded', true)
      this.leftScroller.update();
      gsap.to(whereSnap, { duration: 0.8, now: whereSnap.x, ease: 'power4.out', onUpdate: () => { this.onColumnSizeChanged({ mouseX: whereSnap.now }) } });
      this.hideCookies();
    },
    showCookies() {
      if(!this.$refs.cookies.classList.contains('cookies-hidden')) {
        gsap.to(this.$refs.cookies, {
          opacity: 1,
          pointerEvents: 'auto'
        })
      }
    },
    hideCookies() {
      gsap.to(this.$refs.cookies, {
          opacity: 0,
          pointerEvents: 'none'
        })
    },
    openForm(formContainer) {
      if (!formContainer.classList.contains('.active')) {

        var homeOffset = this.isMobile ? '25%' : this.$refs.form.formWidth / 2;
        formContainer.classList.add('.active');
        this.activeForms++;
        // this.$refs.formContainer.classList.add('active');
        gsap.timeline()
          .to(
            [this.$refs.colsWrapper, formContainer],
            {
              duration: 0.75,
              x: gsap.utils.wrap([homeOffset, '0%']),
              ease: 'power3.out',
            }, 'start')
          .to(this.$refs.blackCover, {
            duration: 0.75,
            opacity: 0.75,
            ease: 'power3.out'
          }, 'start')
          .fromTo(formContainer.querySelector('.form-wrapper'), {
            x: '75%'
          }, {
            x: '0%',
            duration: 0.75,
            ease: 'power3.out'
          }, 'start')

        //opacity musimy zastąpić czymś lzejszym, bo teraz za bardzo to tnie animację
      }
    },
    onCloseContactForm(formContainer) {
      if (formContainer.classList.contains('.active')) {
        formContainer.classList.remove('.active');
        this.activeForms--;
        // this.$refs.formContainer.classList.remove('active');
        gsap.timeline().to(
          [this.$refs.colsWrapper, formContainer],
          {
            duration: 0.75,
            x: gsap.utils.wrap([0, '-100%']),
            ease: 'power3.out'
          }, 'start')
          .to(this.$refs.blackCover, {
            duration: 0.75,
            opacity: 0,
            ease: 'power3.out'
          }, 'start')
          .to(formContainer.querySelector('.form-wrapper'), {
            duration: 0.75,
            x: '75%',
            ease: 'power3.out'
          }, 'start')
          .add(() => {
            this.$refs.startProjectForm.submitted = false;
          })
      }

    },
    /// UNUSED: 
    onProjectclick({ id, rect }) {
      if (this.isAllVideoListLoaded)
        this.showProject(id, rect);
      else {
        console.error("%c 👨‍⚕️: onProjectclick -> Video not loaded yet ", "font-size:16px;background-color:#16172d;color:white;",)
      }
    },
    onPortfolioWillExpand() {

      if (!this.isRightColumnExpanded) {
        this.lastColLeftWidth = this.colLeftWidth;
        if (this.lastColLeftWidth < 30) {
          this.lastColLeftWidth = window.innerWidth * 75 / 100;
        }
        this.lastColRightWidth = window.innerWidth - this.lastColLeftWidth;
      }

      gsap.to('.column-borders', {
        x: 0,
        ...PortfolioTransitionsParams,
        duration: PortfolioTransitionsParams.duration / 2,
        overwrite: true
      })
      gsap
        .timeline({
          onComplete: () => this.$store.dispatch('onExpandPortfolioComplete')
        })
        .to(this, {
          colLeftWidth: 0,
          ...PortfolioTransitionsParams,
          duration: PortfolioTransitionsParams.duration / 1.5
        })

      // gsap.to ('.left-column-wrapper', { delay:0, duration:0.5, ease:"power1.in", x: -100 })
      this.hideCookies();
    },

    onPortfolioExpanded() {

      this.$store.dispatch('isRightColumnExpanded', true);

      const params2 = { duration: 0.5, ease: 'power2.in' }

      gsap.to("#drag-bar-ins", { duration: 0.35, ...params2 });
      gsap.to('#bar1', {...params2 })
      gsap.to('#bar2', {...params2 });
    },
    onColumnSizeChanged(e) {

      if (e.mouseX >= 15 && e.mouseX <= window.innerWidth) {
        this.colLeftWidth = e.mouseX;

        // if(e.mouseX <= window.innerWidth / 2) {
        // this.$store.dispatch('isPortfolioShrinked', false);
        // }
      } else {

        if (e.mouseX < 15) {
          if(window.innerWidth <= this.tabletThreshold) {
            this.colLeftWidth = e.mouseX;
          } else {
            this.colLeftWidth = 0;
          }
        } else {
          this.colLeftWidth = window.innerWidth
        }

      }

    },
    onWindowResize() {
      this.recalculateMobileRightColumnSpacing()
      this.fixedRightColumWidth = window.innerWidth <= this.tabletThreshold ? 0 : 18
      var colRightWidth = this.colRightWidth

      if (this.isPortfolioShrinked)
        this.colLeftWidth = window.innerWidth - colRightWidth
      else
        this.lastColLeftWidth = window.innerWidth - this.lastColRightWidth

      this.viewWidth = window.innerWidth;

      if (this.isPortfolioShrinked)
        this.onMouseUp({ clientX: this.colLeftWidth, noAnimation: true })
    },
    onPortfolioWillShrink() {

      if (this.isMobile) {
        this.lastColLeftWidth = this.windowWidth - this.mobileRightColumnSpacing
      } else if (this.windowWidth - this.lastColLeftWidth < this.rightColumnMinWidth) {
        this.lastColLeftWidth = this.windowWidth - this.rightColumnMinWidth
      }

      this.$store.dispatch('isRightColumnExpanded', false)

      gsap.to('.column-borders', {
        x: 0,
        ...PortfolioTransitionsParams,
        duration: PortfolioTransitionsParams.duration / 2,
        overwrite: true
      })

      gsap.to(this, {
        duration: 0.8,
        ease: 'power4.out',
        colLeftWidth: this.lastColLeftWidth,
        onComplete: () => {
          this.$store.dispatch('onShrinkPortfolioComplete')
        }
      })

      // gsap.delayedCall(1, () => {this.$store.dispatch('onShrinkPortfolioComplete')});

      // gsap.to ('.left-column-wrapper', { delay:0, duration:0.5, ease:"power1.out", overwrite: true })
      gsap.to("#drag-bar-ins", { duration: 1 });

      const params2 = { duration: 0.35, ease: 'power2.out' }

      gsap.to('#bar1', { backgroundColor: '#ffffff', ...params2 })
      gsap.to('#bar2', { backgroundColor: '#ffffff', ...params2 });

      this.showCookies();
    },
    getFormContainers() {
      return gsap.utils.toArray(this.$refs.home.querySelectorAll('.form-container'))
    },
    acceptCookies() {
      this.hideCookies();
      this.$refs.cookies.classList.add('cookies-hidden')

      Cookies.set("cookiePolicy", true);
    },
    handleRouteChange() {
      const closeForms = () => {
        this.onCloseContactForm(this.$refs.startProjectFormContainer)
        this.onCloseContactForm(this.$refs.careersFormContainer)
        this.onCloseContactForm(this.$refs.cookiePolicyContainer)
      }

      switch (router.currentRoute.value.path) {
        case '/contact':
          this.openForm(this.$refs.startProjectFormContainer)
          break;
        case '/careers':
          this.openForm(this.$refs.careersFormContainer)
          break;
        case '/cookies':
          this.openForm(this.$refs.cookiePolicyContainer)
          break;
        case '/':
          this.$refs.portfolioExpanded?.shrinkProject();
          closeForms();
          break;
        default:
          if(router.currentRoute.value.path.startsWith('/projects')) {
            closeForms();

            const projectUrl = router.currentRoute.value.path.replace('/projects/', '')

            let id = this.projectNames.findIndex(project => project == projectUrl);
            if(id === -1) id = 0;
            if(this.isAllVideoListLoaded) {
              ProjectsStore.openProjects(id);
            } 
          }

          break;
      }
    },
    openProjects() {
      ProjectsStore.openProjects();
    }
  },
  computed: {
    projectNames() {
      return this.$store.getters.projects.map(project => project.title.toLowerCase().replaceAll(' ', '-'));
    },
    draggerOffset() {
      return this.isMobile ? 90 : 60;
    },
    portfolioColumnWidth() {
      return `${this.viewWidth - this.colLeftWidth}`
    },
    contentColumnWidth() {
      return `${this.colLeftWidth}`
    },
    cookiePolicy() {
      return Cookies.get("cookiePolicy");
    },
    colRightWidth() {
      return window.innerWidth - this.colLeftWidth
    },
    colRightAplaOpacity() {
      let opacity = (this.rightColumnMinWidth / this.colRightWidth) - 1;
      return Math.max(Math.min(opacity, 1), 0);
    },
  },
  watch: {
    isRightColumnExpanded() {

      const params2 = { duration: 0.35, ease: 'power2.out' }

      if (this.isRightColumnExpanded)
        gsap.to('.drag-bar-ins--mobile', { ...params2 })
      else
        gsap.to('.drag-bar-ins--mobile', { ...params2 })

    },
    isMobile() {
      if (this.isPortfolioShrinked)
        this.onMouseUp({ clientX: this.colLeftWidth, noAnimation: true })
      else
        this.lastColRightWidth = this.fixedRightColumWidth
    },
    windowWidth() {
      this.onWindowResize()
    },
    colLeftWidth() {
      gsap.set('.left-column-wrapper', { x: this.colLeftWidth < 300 ? this.colLeftWidth / 2 - 150 : 0 })
    },
    homeVisibility() {

      if (!this.isPortfolioExpanded) return

      gsap.killTweensOf(this)
      gsap.killTweensOf('.left-column-wrapper')
      gsap.killTweensOf('.left-column-wrapper .body-text')

      gsap.to(this, {
        duration: this.homeVisibility ? 0.3 : 0.1,
        ease: 'power1.out',
        colLeftWidth: this.homeVisibility ? ProjectsStore.const.CLOSE_PROJECT_WIDTH : 0,
        // overwrite: true
      })

      // gsap.to ('.left-column-fixed', { delay:0, duration:this.homeVisibility ? 0.3 : 0.1, ease:"power1.out", x: this.homeVisibility ? 0 : -100 })

      gsap.to('.left-column-wrapper', { backgroundColor: this.bgColor, duration: 0 })

      gsap.to(['.left-column-wrapper', '.left-column-wrapper .body-text'], { color: '#000000', duration: 0 })

      // gsap.to ('.left-column-wrapper', { delay:0, duration:0.75, ease:"power4.out", x: this.homeVisibility ? 0 : -100 })

      gsap.set(['.border-bottom', '.border-out-transition'], { 'borderBottomColor': '#000000' })
      // gsap.set('.team-row', { opacity: 1 })



      const params2 = { duration: 0.35, ease: 'power2.out' }

      // if (!this.homeVisibility){
      gsap.to('#bars', { x: 0, ...params2 })
      gsap.to("#drag-bar-ins", { ...params2, duration: 0.1 });
      gsap.to(".drag-bar-ins--mobile", { ...params2, duration: 0.1 });
      console.log("%c ❇️: homeVisibility -> .drag-bar-ins--mobile ", "font-size:16px;background-color:#4f7f78;color:white;", this.homeVisibility ? 'hidden' : 'visible')
      gsap.to('#bar1', { backgroundColor: '#000000', ...params2 })
      gsap.to('#bar2', { backgroundColor: '#000000', ...params2 });
      // }else{
      //   gsap.to ('#bars', { x:0, ...params2})
      //   gsap.to("#drag-bar-ins", {duration:1, backgroundColor: '#000000', ...params2});
      //   gsap.to('#bar1', { backgroundColor: '#FFFFFF', ...params2})
      //   gsap.to('#bar2', { backgroundColor: '#FFFFFF', ...params2});
      // }
    }
  }
}
</script>
