import Vuex from 'vuex'
import { PORTFOLIO_STATE } from "../js/constants/PortfolioState.js"
import emitter from  '@/js/emitter'
// import isMobile from 'ismobilejs';
import json from "@/assets/data.json"


const THREE_STORE_MODULE = {
  state: () => ({
    three: {
      isThreeInitilized: false,
      planeWidth: 0,
      planeHeight: 0,
    },
    projectViewState: PORTFOLIO_STATE.SHRINKED,
    isProjectMouseOvered: false,
    isProjectHovered: false,
    isRightColumnExpanded: false,
    homeVisibility:false
  }),
  mutations: { 
    setThreeInitialized:  (state, payload) => state.three.isThreeInitilized = payload,
    setThreePlaneSize:    (state, payload) => {state.three.planeWidth = payload.width; state.three.planeHeight = payload.height; },
    setProjectViewState:  (state, payload) => state.projectViewState = payload,
    setIsProjectMouseOvered:  (state, payload) => state.isProjectMouseOvered = payload,
    setIsProjectHovered:  (state, payload) => state.isProjectHovered = payload,
    isRightColumnExpanded:  (state, payload) => state.isRightColumnExpanded = payload,

    toggleHome:  (state, payload) => state.homeVisibility = payload,
  },
  actions: { 
    setThreeInitialized:  (context, payload) => context.commit('setThreeInitialized', payload),
    setThreePlaneSize:    (context, payload) => context.commit('setThreePlaneSize', payload),
    expandPortfolio:      (context) => context.commit('setProjectViewState', PORTFOLIO_STATE.WILL_EXPANDED),
    shrinkPortfolio:      (context) => {
      context.commit('setProjectViewState', PORTFOLIO_STATE.WILL_SHRINK);
      emitter.emit("on-portfolio-shrink-project");
    },
    toggleHome:      (context, payload) => {
      context.commit('toggleHome', payload);
      //emitter.emit("on-portfolio-shrink-project");
    },
    onExpandPortfolioComplete:      (context) => context.commit('setProjectViewState', PORTFOLIO_STATE.EXPANDED),
    onShrinkPortfolioComplete:      (context) => {
      context.commit('setProjectViewState', PORTFOLIO_STATE.SHRINKED);
    },
    setIsProjectMouseOvered:        (context, payload) => context.commit('setIsProjectMouseOvered', payload),
    setIsProjectHovered:        (context, payload) => context.commit('setIsProjectHovered', payload),
    isRightColumnExpanded:        (context, payload) => context.commit('isRightColumnExpanded', payload),
  },
  getters:{
    projectViewState:   state => state.projectViewState,
    isThreeInitilized:  state => state.three.isThreeInitilized,
    threePlaneSize:     state => ({width: state.three.planeWidth, height: state.three.planeHeight}),
    isProjectMouseOvered: state => state.isProjectMouseOvered,
    isProjectHovered: state => state.isProjectHovered,
    homeVisibility: state => state.homeVisibility,
    isPortfolioShrinked: state => state.projectViewState == PORTFOLIO_STATE.SHRINKED,
    isPortfolioVisible: state => state.isProjectMouseOvered || state.projectViewState != PORTFOLIO_STATE.SHRINKED,
    isRightColumnExpanded: state => state.isRightColumnExpanded
  }
}



export default Vuex.createStore({
  state: {
    projects: json.projects,
    isMobile: window.innerWidth <= 1024,
    app: {
      windowWidth: 0,
      windowHeight: 0,
      homeColumnWidth: 0,
    },
    portfolio: {
      scrollOffset: 0,
    },
  },
  mutations: {
    onResize (state, payload) {

      console.log("RESIZE");
      
      state.app.windowWidth   = payload.width;
      state.app.windowHeight  = payload.height;
      state.isMobile = payload.width <= 1024;
    },
    updatePortfolioScrollOffset (state, payload) {
      state.portfolio.scrollOffset = payload;
    },
    setHomeColumnWidth (state, payload) {
      state.app.homeColumnWidth = payload;
    }
  },
  actions: {
    onResize(context, payload){
      context.commit('onResize', payload)
    },
    updatePortfolioScrollOffset (context, payload) {
      context.commit('updatePortfolioScrollOffset', payload)
    },
    setHomeColumnWidth (context, payload) {
      context.commit('setHomeColumnWidth', payload)
    }
  },
  modules: {
    THREE_STORE_MODULE
  },
  getters:{
    projects: state => state.projects,
    portfolioScrollOffset: state => state.portfolio.scrollOffset,
    windowWidth: state => state.app.windowWidth,
    windowHeight: state => state.app.windowHeight,
    homeColumnWidth: state => state.app.homeColumnWidth,
    isMobile: state => state.isMobile,
  }
});
