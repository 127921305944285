
<template>
  <div class="contact-form">

    <section>
      <div class="container-fluid" ref="formHeader" :style="{ 'padding': `0 ${leftPadding}px` }">
        <div class="row">
          <h1 class="form-title">
            COOKIE POLICY
          </h1>
        </div>
        <div class="row">
          <div class="body-text input-wrapper lead" style="margin-bottom: 0;">
            <p class="input-wrapper bright-body-size">In the spirit of transparency, this Cookie Policy (“Policy”) provides detailed
              information about why, how, and when we use cookies on our Site, Software, and/or Services, as defined in
              our Privacy Policy.</p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid" :style="{ 'padding': `0 ${leftPadding}px` }">
        <div class="row">
          <div class="col-12 col-sm-12 col-lg-5">
            <h2 class="section-title">
              WHAT IS A COOKIE?
            </h2>
          </div>
          <div class="col-12 col-sm-12 col-lg-7 cookie-explanation bright-body-size">
            <p>1. A cookie is a small text file that is placed on your hard drive by a web page server. Cookies contain
              information that can later be read by a web server in the domain that issued the cookie to you.</p>

            <p>Some of the cookies will only be used if you use certain features or select certain preferences, and some
              cookies are essential to the Site, Software, and/or Services, and will always be used. Web beacons, tags,
              and
              scripts may be used in the Site or in emails to help us deliver cookies and count visits, understand usage
              and
              campaign effectiveness and determine whether an email has been opened and acted upon.</p>

            <p>We may receive reports based on the use of these technologies by our service/analytics providers (for
              example,
              Google Analytics) on an individual and aggregated basis.</p>

            <p>We place great importance on protecting the privacy of users of the Bright website (the "Website").
              Therefore,
              we explain below the rules and manner of collecting and using information about the users of the Website.
            </p>

            <p>2. The Controller of personal data of natural persons using the Website is Łukasz Górka, conducting a
              business
              activity under the business name BrightMedia Łukasz Górka Ewa Przybylak sp. k., 18 Brzeska Street, premises 52, 03-737 Warsaw,
              NIP: PL1133049879 e-mail: admin@wearebright.studio. All correspondence and requests concerning personal data should be
              addressed
              to the above e-mail address (or postal address).</p>

            <p>3. The Administrator shall make every effort to protect user data against unauthorized access, unauthorized
              modification, disclosure or destruction.</p>

            <p>4. The User's personal data shall be protected in accordance with generally applicable laws, including
              Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of
              individuals with regard to the processing of personal data and on the free movement of such data and
              repealing
              Directive 95/46/EC, hereinafter referred to as "GDPR".</p>

            <p>5. The Website uses "cookie" files, i.e. small text files saved by the server on the User's device, used
              for
              the following purposes:
              a. maintaining the user’s session (after logging in),
              b. adjusting the services to user's preferences,
              c. remembering individual preferences and settings of the user,
              d. audience statistics.
              6.Cookies contain the following data:
              a. unique identifier,
              b. information about the operating system,
              c. browser identification,
              d. location – if the user agrees in the browser to share his location.
            </p>

            <p>7. There are two main types of cookies used within the Website: "session" and "permanent". “Session"
              cookies
              are temporary files that are stored on the User's end device until logging out, leaving the website or
              switching off the software (web browser). "Permanent" cookies are stored on the User's end device for the
              time
              specified in the cookie file parameters or until they are deleted by the User.</p>

            <p>8. The Website uses external cookies from the following entities: a. Google Inc. with its registered office
              in
              the USA - for analytical and statistical purposes as well as for personalized advertisement display, b.
              Hotjar
              Ltd. with its registered office in Malta - for analytical and statistical purposes.</p>

            <p>9. The User may, by changing the settings of a given web browser, disable the use of cookies. Below you
              will
              find links to the description of the rules for the use of cookies (including how to disable them) in the
              most
              popular web browsers:
              a. Chrome support
              b. Firefox support
              c. Safari support
              d. Internet explorer support
              a. Opera support
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  mounted() {
    this.onResize()
  },
  methods: {
    onResize() {
      this.$nextTick(() => this.meassureForm())
    },
    meassureForm() {
      this.formWidth = Math.round(this.$refs.formHeader.getBoundingClientRect().width);
    }
  },
  computed: {
    windowWidth() {
      return this.$store.getters.windowWidth
    },
    leftPadding() {
      return this.$store.getters.homeColumnWidth
    }

  },
  watch: {
    windowWidth() {
      this.onResize()
    }
  },
}
</script>